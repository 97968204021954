// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    GetUser,
} from "../../redux/actions";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";
import moment from "moment";
import { GET_LIMITS } from "../../constants/constsTypes";
import ModalSendNotification from "../../components/uiElements/ModalSendNotification";
import { LinkButton } from "../../components/buttons/buttons";
import InfiniteScroll from "react-infinite-scroller";
import { defaultEmptyView, defaultUserLogo } from "../../assets/images";


class User extends Component {
    constructor (props) {
        super(props);
        this.state = {
            selectingActive: false,
            selectedUsers: [],
            sendModalOpen: false,
            allSelected: false,
            searchValue: "",
        }
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.toggleSelecting = this.toggleSelecting.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
    }

    componentDidMount () {
        this.props.GetUser({
            offset: 0,
            limit: GET_LIMITS,
        });
    }

    loadMoreItems () {
        const { hasMore, userList } = this.props.userData;
        hasMore && this.props.GetUser({
            reset: false,
            offset: userList.length,
            limit: GET_LIMITS,
            text: this.state.searchValue,
        });
    }

    getSearchValue (text) {
        this.setState({
            searchValue: text
        })
       this.props.GetUser({
            reset: true,
           text: text,
            offset: 0,
            limit: GET_LIMITS,
        });
    }

    toggleNotificationModal (notificationSuccessfulSend = false) {
        const newState = {
            sendModalOpen: !this.state.sendModalOpen
        }
        this.state.allSelected && (newState.allSelected = false)
        if (notificationSuccessfulSend) {
            newState.selectingActive = false;
            newState.selectedUsers = [];
            newState.sendModalOpen = false;
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    selectUser (userId) {
        let { selectingActive, selectedUsers } = this.state;
        if (selectingActive) {
            const filteredUsers = selectedUsers.filter(id => id !== userId);
            this.setState({
                selectedUsers: filteredUsers.length === selectedUsers.length ?
                    [userId, ...selectedUsers] : filteredUsers
            })
        }
    }

    selectAll () {
        this.setState({
            allSelected: true,
            selectedUsers: [],
            sendModalOpen: true
        })
    }


    toggleSelecting () {
        const newState = { selectingActive: !this.state.selectingActive };
        if (this.state.selectingActive) {
            newState.selectedUsers = [];
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    render () {
        let { userData, requestLoading } = this.props;
        const { selectedUsers, sendModalOpen, allSelected, selectingActive, searchValue } = this.state;

        let itemListHeaderInfo = {
            generalInfo: ["Նկար","Անվանում", "Հեռախոս", "Էլ․ հասցե", "Ամսաթիվ"],
            largeItems:[1],
            actions: selectingActive && ["send"],

        };
        let itemListInfo = userData?.userList?.map((user) => {
            const { id, email, first_name, last_name, created_at, username, image_url } = user;
            let fullName = `${first_name} ${last_name || ""}`
            return {
                id,
                values: [
                    {
                        title:<img src={image_url ? image_url : defaultUserLogo}
                                   alt="user"/>
                    },
                    first_name ? fullName : ' - ',
                    username || ' - ',
                    email,
                    moment(created_at).format('DD/MM/YYYY HH:mm')
                ],
                openModalCb: this.selectUser.bind(this, id),
                selected: selectingActive && (selectedUsers.find(userId => userId === user.id) || allSelected),
            }
        });
        return <PageWrapper pageTitle={'Օգտատերեր'}>
            {
                this.props.userData.total_count ? <div className={'user-total-count'}>
                    Ընդհանուր օգտատերերի քանակը:  <span>{this.props.userData.total_count}</span>
                </div> : ""
            }
            <PageHeader search={true}
                        searchValue={searchValue}
                        getSearchValue={this.getSearchValue}
                        rightPartProps={<div className={'buttons-block'}>
                            {selectingActive && <LinkButton title={'Առաջ'}
                                                            className={`bg-white size-sm`}
                                                            disabled={!selectedUsers?.length}
                                                            cb={this.toggleNotificationModal}/>}
                            {selectingActive && <LinkButton title={'Բոլորին'}
                                                            className={`bg-white size-sm`}
                                                            cb={this.selectAll}/>}
                            <LinkButton title={selectingActive ? 'Չեղարկել' : 'Ուղարկել ծանուցում'}
                                        className={`send-notification-btn size-sm ${selectingActive ? "bg-default-important" : ''}`}
                                        cb={this.toggleSelecting}/>
                        </div>}
            />

            <InfiniteScroll
                hasMore={userData?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'groupWorkoutList'}
                useWindow={true}
                initialLoad={false}>
                {!!userData?.userList?.length ?
                    <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                               itemListInfo={itemListInfo}
                               detailsLink={"user/details"}
                               loading={requestLoading}/>
                    : <div className={'empty-view'}>
                        <img src={defaultEmptyView} alt={''}/>
                    </div>}
            </InfiniteScroll>
            <ModalSendNotification visible={sendModalOpen}
                                   sendToAllUsers={allSelected}
                                   userIds={selectedUsers}
                                   closeModal={this.toggleNotificationModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
