// Import packages
import React from "react";
import {NavLink} from "react-router-dom";

// Import styles
import "../../assets/styles/navbars/leftSideMenu.scss";

// Import components
import {MaterialIcon} from "../utils/Icon";

//import utils
import {ACCESS_PAGES} from "../../constants/accessPages";
import {Footer} from "../../containers/Footer";

export function LeftSideMenu(props) {
    const {
        toggleMenu, leftSideMenuOpen, accessPages, isAdmin,
    } = props;

    return <aside className={`left-side-menu ${leftSideMenuOpen ? "open" : "close"}`}>

        <ul className="aside-menu-list custom-scrollbar-y ">
            {
                Object.keys(ACCESS_PAGES).map(path => {
                    const page = ACCESS_PAGES?.[path];


                    let haveAccess = false;
                    if (path === 'service' || path === 'event') {
                        let subPages = page?.key.split('_')
                        haveAccess = isAdmin || subPages.some(k => accessPages.includes(k))
                    } else {
                        haveAccess = isAdmin || accessPages.includes(page?.key)
                    }

                    return haveAccess && !page?.isHidden ? <li key={path}>

                        {
                            window.innerWidth < 1000 ?  <NavLink to={`/${path}`} activeClassName='active' onClick={toggleMenu}>
                                <MaterialIcon icon={page?.icon}/>
                                <span>{page?.name}</span>
                            </NavLink> :  <NavLink to={`/${path}`} activeClassName='active' >
                                <MaterialIcon icon={page?.icon}/>
                                <span>{page?.name}</span>
                            </NavLink>
                        }
                    </li> : null
                })
            }

            <div className={'mobile_footer'}>
                <Footer leftSideMenuOpen={leftSideMenuOpen}/>
            </div>
        </ul>
        <div className={`menu-toggle-btn-wrapper ${leftSideMenuOpen ? "open" : "close"}`}>
            {<MaterialIcon icon={`${leftSideMenuOpen ? "keyboard_arrow_left" : "keyboard_arrow_right"}`}
                           onClick={toggleMenu}
                           className="menu-toggle-btn"/>}
        </div>


    </aside>
}
