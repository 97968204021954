import React from 'react';
import { connect } from 'react-redux';

//import components
import MediaUploader from './MediaUploader';
import { Modal } from "antd";

//import utils
import { mapStateToProps } from "../../../redux/mapStateToProps";
import {
    closeAddMedia,
    getMedias,
    onDropFile,
    onUploadClick,
    onUploadProgress,
    triggerFileUpload,
    uploadMedias,
    minimizeUpload,
} from "./functions/action-functions";
import { GetMedia, UploadFile } from "../../../redux/actions";
import { iconNewFile } from "../../../assets/images";

class MediaUploaderWrapper extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            currentUploadingId: null,
            uploading: false,

            addingFiles: [], // uploading files list
            filesIsChoose: false, // if true upload modal is open.
            errors: {},
        };
        this.inputElement = React.createRef();

        this.closeAddMedia = closeAddMedia.bind(this);
        this.onUploadClick = onUploadClick.bind(this);
        this.triggerFileUpload = triggerFileUpload.bind(this);
        this.getMedias = getMedias.bind(this);
        this.onDropFile = onDropFile.bind(this);
        this.uploadMedias = uploadMedias.bind(this);
        this.minimizeUpload = minimizeUpload.bind(this);
        this.onUploadProgress = onUploadProgress.bind(this);
        // new
        this.closeModal = this.closeModal.bind(this);
    }

    prevent (e) {
        e.stopPropagation();
        e.preventDefault();
    }

    closeModal () {
        this.closeAddMedia();
        this.state.uploading && this.minimizeUpload();
    }

    render () {
        const { uploadProgresses } = this.props;
        const {
            filesIsChoose, addingFiles, uploading, errors, currentUploadingId
        } = this.state;
        const { progress = 0, status = null } = uploadProgresses[currentUploadingId] || {}
        return (
            <div className={"file-uploader"}
                 onDragEnter={this.prevent}
                 onDragLeave={this.prevent}
                 onDragOver={this.prevent}
                 onDrop={this.onDropFile}>
                <div className={'add-file-btn'} onClick={this.triggerFileUpload}>
                    <img src={iconNewFile} alt=""/>
                    Ավելացնել Ֆայլ
                </div>

                <input
                    style={{ display: 'none' }}
                    type="file"
                    ref={this.inputElement}
                    multiple={true}
                    onChange={this.onDropFile}
                    onClick={this.onUploadClick}
                />
                <Modal
                    visible={filesIsChoose}
                    wrapClassName={'custom-modal'}
                    onCancel={this.closeModal}>
                    <div className={'media-uploader-wrapper-modal'}>

                        <MediaUploader addingFiles={addingFiles}
                                       uploading={uploading}
                                       status={status}
                                       progress={progress}
                                       errors={errors}
                                       getMedias={this.getMedias}
                                       uploadMedias={this.uploadMedias}
                                       minimizeUpload={this.minimizeUpload}
                                       cacnel={this.closeAddMedia}/>
                    </div>
                </Modal>

            </div>
        )
    }
}

const mapDispatchToProps = {
    UploadFile,
    GetMedia
};
export default connect(mapStateToProps, mapDispatchToProps)(MediaUploaderWrapper);
