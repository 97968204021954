import {request, _urlWorkoutSection} from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {WORKOUT_SECTION_CONSTS} from "../constants";

export const GetWorkoutSections = () => {
    const requestData = {
        url: _urlWorkoutSection,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: WORKOUT_SECTION_CONSTS.GET_WORKOUT_SECTIONS,
                    payload: data
                });
            })
    }
};


export const GetWorkoutSectionById = id => {
    const requestData = {
        url: `${_urlWorkoutSection}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: WORKOUT_SECTION_CONSTS.GET_WORKOUT_SECTION_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};
export const DeleteWorkoutSectionById = id => {
    const requestData = {
        url: `${_urlWorkoutSection}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: WORKOUT_SECTION_CONSTS.DELETE_WORKOUT_SECTION_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateWorkoutSection = data => {
    const requestData = {
        url:_urlWorkoutSection,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:WORKOUT_SECTION_CONSTS.CREATE_WORKOUT_SECTION,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Մարզումների բաժինը հաջողությամբ ստեղծվեց!"
                })
                history.push('/workoutSection')
            })
            .catch(() => {
            })
    }
};

export const UpdateWorkoutSectionById = (id, data) => {
    const requestData = {
        url: `${_urlWorkoutSection}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:WORKOUT_SECTION_CONSTS.UPDATE_WORKOUT_SECTION_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Մարզումների բաժինը հաջողությամբ փոփոխվեց!"
                })
                history.push('/workoutSection')
            })
            .catch(() => {
            })
    }
};