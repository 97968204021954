import { ABOUT_CONSTS, AUTH_CONSTS } from "../constants";

export const initialState = {
    about: {},
    aboutBlocks: [],
    aboutBlockById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case ABOUT_CONSTS.GET_ABOUT:
            const { blocks, ...about } = action.payload
            return {
                ...state,
                about,
                aboutBlocks: blocks || [],
            };
        case ABOUT_CONSTS.UPDATE_ABOUT:
            return {
                ...state,
                about: action.payload,
            };
        case ABOUT_CONSTS.CREATE_ABOUT_BLOCK:
            return {
                ...state,
                aboutBlocks: [action.payload].concat(state.aboutBlocks)
            };
        case ABOUT_CONSTS.GET_ABOUT_BLOCK_BY_ID:
            return {
                ...state,
                aboutBlockById: action.payload
            };
        case ABOUT_CONSTS.UPDATE_ABOUT_BLOCK_BY_ID:
            return {
                ...state,
                aboutBlocks: state.aboutBlocks.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case ABOUT_CONSTS.DELETE_ABOUT_BLOCK_BY_ID:
            return {
                ...state,
                aboutBlocks: state.aboutBlocks.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
