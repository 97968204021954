// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationsInitialValues,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateSlider, UpdateSliderById,GetSliderById,GetArticles,GetEvents} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import {GET_LIMITS, MAIN_LANGUAGE_TYPES} from "../../constants/constsTypes";
import {transformImage} from "../../utils/transformImage";

class AddEditSlider extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                image:null,
                article_id: undefined,
                event_id: undefined,
                service_id: undefined,
                position: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,

        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.addEditSlider = this.addEditSlider.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;
        if (isEditing) {
            await this.props.GetSliderById(this.props.id);
            const editingData = this.props.sliderById;
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations, ['title','description']),
                    image: editingData?.image_object_name ?
                        transformImage(editingData.image_object_name, editingData.image_url )
                        : null,
                    position:editingData?.position,
                    article_id:editingData?.article?.id || this.state.fieldsData.article_id,
                    event_id:editingData?.event?.id || this.state.fieldsData.event_id,
                    service_id:editingData?.service?.id || this.state.fieldsData.service_id,
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                image: mediaArray[0],
            },
            errors: {
                ...errors,
                image: false
            }
        })
    }


    async addEditSlider () {
        await this.setState({
            requestLoading: true
        });
        const {translations, image} = this.state.fieldsData;
        // const translationRequiredFields = ['title', 'description']
        // const validationTr = validateTranslations(translations, translationRequiredFields)
        // let result = validationTr.result;
        let result = true;
        const errors = {};

        if (!image) {
            errors.image = true;
            result = false;
        }

        if (result) {
            if (!this.state.isEditing) {
                let {sliderList} = this.props;

                let position = undefined
                let num = []
                sliderList?.forEach(item => {
                    num.push(item?.position)
                    return position = Math.max(...num)
                })

                let reqData = this.getPostReqData(this.state.fieldsData);
                reqData.position = position + 1
                this.props.CreateSlider(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                let reqData = this.getPostReqData(this.state.fieldsData);
                this.props.UpdateSliderById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            requestLoading,
            isEditing,
            errorsTabs,
        } = this.state;

        const {eventData,articleData, serviceList} = this.props

        const articleOptions = articleData?.articleList?.map(article => {
            return {
                id: article?.id,
                title: article?.title || ' '
            }
        });

        const eventOptions = eventData?.eventList?.map(event => {
            return {
                id: event?.id,
                title: event?.title || ' '
            }
        });
        const serviceOptions = serviceList?.map(event => {
            return {
                id: event?.id,
                title: event?.title || ' '
            }
        });

        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Սլայդեր' : 'Փոփոխել Սլայդերը'}>
            <section className="slider-add-edit">
                <div className={"images-wrapper"}>
                    <div>
                        <div className="top-side">
                            <label> նկար <span className={'required-badge'}>*</span></label>
                            <LinkButton title="Ընտրել մեդիադարանից"
                                        className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                        cb={this.toggleMediaModal}
                            />
                        </div>
                        {fieldsData?.image && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.image}
                            />
                        </div>}
                    </div>
                </div>

                <InputGroup inputType="selectCustom"
                            label={"Հոդված"}
                            placeholder="Հոդված"
                            name="article_id"
                            showSearch={false}
                            value={fieldsData?.article_id}
                            error={errors?.article_id}
                            initValue={this.currentData?.article_id}
                            required={false}
                            withClear={true}
                            onChange={this.getInputValues}
                            options={articleOptions}/>

                <InputGroup inputType="selectCustom"
                            label={"Իրադարձություն"}
                            placeholder="Իրադարձություն"
                            name="event_id"
                            showSearch={false}
                            value={fieldsData?.event_id}
                            error={errors?.event_id}
                            initValue={this.currentData?.event_id}
                            required={false}
                            withClear={true}
                            onChange={this.getInputValues}
                            options={eventOptions}/>
                <InputGroup inputType="selectCustom"
                            label={"Փաթեթներ"}
                            placeholder="Փաթեթներ"
                            name="service_id"
                            showSearch={false}
                            value={fieldsData?.service_id}
                            error={errors?.service_id}
                            initValue={this.currentData?.service_id}
                            required={false}
                            withClear={true}
                            onChange={this.getInputValues}
                            options={serviceOptions}/>

                {/*<LanguageTabs changeLanguageTab={this.changeLanguageTab}*/}
                {/*              errorsTabs={errorsTabs}*/}
                {/*              activeTab={languageTab}/>*/}
                {/*<div className="slider-wrapper">*/}
                {/*    <InputGroup inputType="input"*/}
                {/*                type="text"*/}
                {/*                label="Անվանում"*/}
                {/*                placeholder="Անվանում"*/}
                {/*                name="title"*/}
                {/*                value={currentData.title}*/}
                {/*                initValue={initData.title}*/}
                {/*                error={errors['title' + languageTab]}*/}
                {/*                maxLength={100}*/}
                {/*                required={true}*/}
                {/*                onChange={this.getTranslationValues}/>*/}
                {/*    <InputGroup inputType="input"*/}
                {/*                type="text"*/}
                {/*                label="Ենթավերնագիր"*/}
                {/*                placeholder="Ենթավերնագիր"*/}
                {/*                name="description"*/}
                {/*                value={currentData.description}*/}
                {/*                initValue={initData.description}*/}
                {/*                error={errors['description' + languageTab]}*/}
                {/*                maxLength={1000}*/}
                {/*                required={true}*/}
                {/*                onChange={this.getTranslationValues}/>*/}
                {/*</div>*/}
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditSlider}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateSlider,
    UpdateSliderById,
    GetSliderById,
    GetArticles,
    GetEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSlider)
