import React from "react";
import {Route, Redirect} from "react-router-dom";
import {ACCESS_PAGES} from "../constants/accessPages";

import {store} from "../redux/store";

export const ProtectedRoute = ({ component: Component, ...rest }) =>
    <Route {...rest}
           render={(props) => {
               const { accessPages, isAdmin } = store.getState().auth;
               const pagePath = rest.location.pathname.split('/')[1];

               const page = ACCESS_PAGES?.[pagePath];
               let haveAccess = false;
               if(pagePath === 'service' || pagePath === 'event'){
                   let subPages = page?.key.split('_')
                   haveAccess = subPages.some(k => accessPages.includes(k))
               }else {
                   haveAccess = accessPages.includes(page?.key)
               }
               return (isAdmin || pagePath === 'dashboard' || haveAccess ?
                   <Component id={props.match.params.id}
                              location={props.location}
                              {...rest}
                              {...props}
                   /> :
                   <Redirect to="/404"/>)
           }}/>;
