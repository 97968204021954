// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import swal from "sweetalert";

//import styles
import '../../assets/styles/containerStyles/event-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {MaterialIcon} from "../../components/utils/Icon";
import ItemsList from "../../components/ItemsList";
import InfiniteScroll from "react-infinite-scroller";
import {SkeletonSmallItem} from "../../components/uiElements/SkelatonISmalltem";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetEventById, DeleteJoinedMember, GetEventJoinedMembers} from "../../redux/actions";
import {GET_LIMITS, LANGUAGE_TYPES} from "../../constants/constsTypes";
import {LinkButton} from "../../components/buttons/buttons";
import ModalSendNotification from "../../components/uiElements/ModalSendNotification";

class EventDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectingActive: false,
            selectedUsers: [],
            sendModalOpen: false,
            allSelected: false,
            members: {
                list: [],
                hasMore: true,
                loading: true,
            },
            eventLoading: true,
        }
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.deleteMember = this.deleteMember.bind(this);
        this.toggleSelecting = this.toggleSelecting.bind(this);

    }

    async componentDidMount() {
        await this.props.GetEventById(this.props.id);
        const editingData = this.props.eventById;
        this.setState({
            eventLoading: false,
        })
        const joinedMembers = await this.props.GetEventJoinedMembers({
            id: this.props.id,
        });
        this.setState({
            members: {
                list: joinedMembers,
            }
        })
    }

    async loadMoreItems() {
        const {hasMore, list} = this.state.members;
        if(hasMore){
            const moreMembers = await this.props.GetEventJoinedMembers({
                id: this.props.id,
            });
            this.setState({
                members: {
                    list: [...list, ...moreMembers],
                    hasMore: GET_LIMITS === moreMembers?.length,
                    loading: false
                }
            })
        }

    }

    deleteMember(removingId) {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(async confirm => {
            if (confirm) {
                await this.props.DeleteJoinedMember(removingId);
                this.setState({
                    members: {
                        ...this.state.members,
                        list: this.state.members?.list?.filter(m => m.id !== removingId)
                    }
                })
            }
        })

    }

    toggleNotificationModal (notificationSuccessfulSend = false) {
        const newState = {
            sendModalOpen: !this.state.sendModalOpen
        }
        this.state.allSelected && (newState.allSelected = false)
        if (notificationSuccessfulSend) {
            newState.selectingActive = false;
            newState.selectedUsers = [];
            newState.sendModalOpen = false;
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    selectUser (userId) {
        let { selectingActive, selectedUsers } = this.state;
        if (selectingActive) {
            const filteredUsers = selectedUsers?.filter(id => id !== userId);
            this.setState({
                selectedUsers: filteredUsers?.length === selectedUsers?.length ?
                    [userId, ...selectedUsers] : filteredUsers
            })
        }
    }

    selectAll () {
        const filteredUsers = this.state.members.list?.map(item => {
            if(item?.user_id){
                return item?.user_id
            }
        }) ;
        this.setState({
            allSelected: true,
            selectedUsers: filteredUsers?.length && filteredUsers,
            sendModalOpen: true
        })
    }

    toggleSelecting () {
        const newState = { selectingActive: !this.state.selectingActive };
        if (this.state.selectingActive) {
            newState.selectedUsers = [];
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    render() {
        const {eventById,} = this.props;
        const {eventLoading,selectingActive,selectedUsers,allSelected,sendModalOpen} = this.state;
        const {hasMore, list, loading} = this.state.members;
        console.log(selectedUsers,'selectedUsers')

        let itemListHeaderInfo = {
            generalInfo: ["№", "Անուն", "Ազգանուն", "Հեռախոս", "Էլ․ հասցե", "Գրանց․ Օր/ժամ"],
            largeItems: [],
            actions:selectingActive ? ["send"] : ["delete"]
        };
        let itemListInfo = list?.map((user, index) => {
            const {id, user_id, user_data, created_at} = user;
            return {
                id: id,
                bordered: !!user_id,
                values: [
                    ++index,
                    user_data?.first_name || "",
                    user_data?.last_name || "",
                    user_data?.phone || '',
                    user_data?.email || '',
                    created_at ? moment(created_at).format("DD.MM.YYYY / HH:mm") : "",
                ],
                deleteCb: this.deleteMember.bind(this, user_id),
                openModalCb: this.selectUser.bind(this, user_id),
                selected: selectingActive && (selectedUsers?.find(userId => userId === user?.user_id) || allSelected),
            };
        });
        const trInfo = eventById?.translations?.find(tr => tr.lang === LANGUAGE_TYPES.hy)
        return <PageWrapper pageTitle={`Իրադարձություն`}>
            <section className="event-details">
                {eventLoading ? <SkeletonSmallItem/>
                    : <div className={'row colored'}>
                        <MaterialIcon icon={'event_upcoming'}/> <span>{trInfo?.title}</span>
                    </div>}
                {eventLoading ? <SkeletonSmallItem/>
                    : <div className={'row'}>
                        <MaterialIcon icon={'schedule'}/>
                        <span>{moment(eventById?.event_day_dates?.[0]).format("DD.MM.YY / HH:mm")}</span>
                    </div>}
                {(eventLoading && eventById?.event_day_dates?.[1]) ? <SkeletonSmallItem/>
                    : <div className={'row'}>
                        <MaterialIcon icon={'schedule'}/>
                        <span>{moment(eventById?.event_day_dates?.[1]).format("DD.MM.YY / HH:mm")}</span>
                    </div>}
                <div className={'buttons-block'}>
                    {selectingActive && <LinkButton title={'Առաջ'}
                                                    className={`bg-white size-sm`}
                                                    disabled={!selectedUsers?.length}
                                                    cb={this.toggleNotificationModal}/>}
                    {selectingActive && <LinkButton title={'Բոլորին'}
                                                    className={`bg-white size-sm`}
                                                    cb={this.selectAll}/>}
                    <LinkButton title={selectingActive ? 'Չեղարկել' : 'Ուղարկել ծանուցում'}
                                className={`send-notification-btn size-sm ${selectingActive ? "bg-default-important" : ''}`}
                                cb={this.toggleSelecting}/>
                </div>
                <InfiniteScroll
                    hasMore={hasMore}
                    loadMore={this.loadMoreItems}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                               loading={loading}
                               itemListInfo={itemListInfo}/>
                </InfiniteScroll>
            </section>

            <ModalSendNotification visible={sendModalOpen && selectedUsers?.length}
                                   sendToAllUsers={false}
                                   isEvents={true}
                                   eventId={this.props?.id}
                                   userIds={selectedUsers}
                                   closeModal={this.toggleNotificationModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetEventById,
    GetEventJoinedMembers,
    DeleteJoinedMember
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails)
