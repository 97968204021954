import {AUTH_CONSTS, GENERAL_CONSTS} from "../constants";

export const initialState = {
    routesList: [],
    userData: {
        userList: [],
        total_count: 0,
        hasMore: false,
    },
    deviceDate: {
        deviceList: [],
        hasMore: false,
    },
    invitations: {
        invitationsList: [],
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERAL_CONSTS.SET_ROUTES:
            return {
                ...state,
                routesList: action.payload,
            };
        case GENERAL_CONSTS.GET_USER:
            return {
                ...state,
                userData: {
                    userList: action.payload.reset ? action.payload.list : [...state.userData.userList, ...action.payload.list],
                    total_count: action.payload.total_count,
                    hasMore: action.payload.hasMore,
                }
            };
        case GENERAL_CONSTS.GET_DEVICE:
            return {
                ...state,
                deviceDate: {
                    deviceList: action.payload.reset ? action.payload.data : [...state.deviceDate.deviceList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                },
            };
        case GENERAL_CONSTS.GET_INVITATIONS:
            return {
                ...state,
                invitations: {
                    invitationsList: action.payload.reset ? action.payload.data : [...state.invitations.invitationsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
