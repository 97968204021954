// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import assets
import '../../assets/styles/dataDisplay/notification-modal.scss';

// Import components
import {Modal} from "antd";
import {LanguageTabs} from "./Tabs";
import {InputGroup} from "./inputGroup";
import {LinkButton} from "../buttons/buttons";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    changeStateField, getInputValues, getPostReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField
} from "../../utils/helperFunctions";

import {SendNotification} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {iconCloseDark} from "../../assets/images";
import {MAIN_LANGUAGE_TYPES, NotificationTypes} from "../../constants/constsTypes";
import {MediaFileCard} from "../media/MediaFileCard";

class ModalSendNotification extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['title', 'description'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                user_ids: [],
                device_ids: [],
                type:this.props.isEvents ? NotificationTypes.Event.key : NotificationTypes.System.key,
                spec: this.props.isEvents ? this.props.eventId: undefined
            },
            languageTab: MAIN_LANGUAGE_TYPES.en,
            notificationSend: false,
            loading: false,
            errorsTabs: [],
            errors: {},
        };
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.sendNotification = this.sendNotification.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.getTypeValue = this.getTypeValue.bind(this);
    }

    onMediaPositionChange(items) {
        const {fieldsData, isEditing} = this.state;
        const medias = items.map(item => item.media);
        if (isEditing) {
            this.checkMediaListDifferent(medias)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                medias: medias
            }
        })
    }

    mediaCardWrapper(data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia}/>
    }

    async sendNotification() {
        const {translations,spec} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validation = validateTranslations(translations, translationRequiredFields, false);
        const {userIds, sendToAllUsers, type} = this.props;
        let result = validation.result;

        if(this.state.fieldsData.type!==NotificationTypes.System.key && !spec){
            result=false;
            validation.errors.spec=true
        }
        if (result) {
            await this.setState({loading: true});

            let reqData = {...this.state.fieldsData};
            if (type === 'device') {
                !sendToAllUsers && (reqData.device_ids = userIds);
            } else {
                !sendToAllUsers && (reqData.user_ids = userIds);
            }
            if(spec){
                reqData.spec={
                    id:spec
                }
            }
            await this.props.SendNotification(reqData).then(() => {
                this.setState({
                    notificationSend: true
                });
            }).catch(() => {
            });
            this.setState({loading: false});
        } else {
            this.setState({
                errors: validation.errors,
                errorsTabs: validation.errorsTabs
            })
        }
    }

    closeModal = () => {
        this.props.closeModal(this.state.notificationSend);
        setTimeout(() => {
            this.setState({
                fieldsData: {
                    translations: initTranslations(this.translationsFields),
                    user_ids: [],
                    device_ids: [],
                    type:this.props.isEvents ? NotificationTypes.Event.key : NotificationTypes.System.key,
                    spec: this.props.isEvents ? this.props.eventId: undefined
                },
                notificationSend: false,
                errorsTabs: [],
                errors: {},
            });
        }, 300)
    }

    async getTypeValue(props) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                spec: undefined
            }
        }, ()=>{
            this.getInputValues(props)
        })

    }

    render() {
        const {visible, eventData, articleData, groupWorkout} = this.props;
        const {languageTab, fieldsData, notificationSend, loading, errorsTabs, errors} = this.state;
        const trData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};

        const articleOptions = articleData?.articleList?.map(article => {
            return {
                id: article?.id,
                title: article?.title || ' '
            }
        });

        const eventOptions = eventData?.eventList?.map(event => {
            return {
                id: event?.id,
                title: event?.title || ' '
            }
        });
        const workoutOptions = groupWorkout?.groupWorkoutList?.map(workout => {
            return {
                id: workout?.id,
                title: workout?.title || ' '
            }
        });

        const typeOptions = Object.values(NotificationTypes)?.map(item => {
            return {
                id: item.key,
                title: item.title || ' '
            }
        });

        let specOptions = articleOptions;
        if (fieldsData.type === NotificationTypes.Event.key) {
            specOptions = eventOptions;
        }
        if (fieldsData.type === NotificationTypes.Workout.key) {
            specOptions = workoutOptions;
        }
        return <Modal
            visible={visible}
            wrapClassName={'custom-modal'}
            onCancel={this.closeModal}
        >
            <div className={'send-notification-modal'}>
                <span className={'close-icon'} onClick={this.closeModal}>
                    <img src={iconCloseDark}
                         className="close_icon" alt=""/>
                </span>
                <h2 className="title">Ծանուցում</h2>
                {notificationSend && <div className={'message'}>Ծանուցումը հաջողությամբ ուղարկվեց</div>}
                {!notificationSend && <section className="send-notification">
                    <InputGroup inputType="selectCustom"
                                label={"Տեսակ"}
                                placeholder="Տեսակ"
                                name="type"
                                showSearch={false}
                                value={fieldsData?.type}
                                error={errors?.type}
                                disabled={this.props.isEvents ? true : false}
                                required={true}
                                onChange={this.getTypeValue}
                                options={typeOptions}/>
                    {!this.props.isEvents && fieldsData?.type !== NotificationTypes.System.key
                        && <InputGroup inputType="selectCustom"
                                       label={Object.values(NotificationTypes)?.find(t=>t.key===fieldsData?.type)?.title}
                                       placeholder={Object.values(NotificationTypes)?.find(t=>t.key===fieldsData?.type)?.title}
                                       name="spec"
                                       showSearch={false}
                                       value={fieldsData?.spec}
                                       error={errors?.spec}
                                       required={true}
                                       onChange={this.getInputValues}
                                       options={specOptions}/>}
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  activeTab={languageTab}
                                  errorsTabs={errorsTabs}
                    />
                    <div className="info-adding-fields">

                        <InputGroup inputType={"input"}
                                    type={"text"}
                                    label={"Վերնագիր"}
                                    placeholder={"Վերնագիր"}
                                    name={"title"}
                                    maxLength={200}
                                    required
                                    error={errors['title' + languageTab]}
                                    value={trData?.title}
                                    onChange={this.getTranslationValues}/>
                        <InputGroup inputType={"textarea"}
                                    label={"Նկարագրություն"}
                                    placeholder={"Նկարագրություն"}
                                    name={"description"}
                                    maxLength={1000}
                                    required
                                    error={errors['description' + languageTab]}
                                    value={trData?.description}
                                    onChange={this.getTranslationValues}/>
                    </div>

                    <div className="btn-wrapper">
                        <LinkButton title="Ուղարկել Ծանուցում"
                                    loading={loading}
                                    disabled={notificationSend}
                                    loadingWithTitle={true}
                                    cb={this.sendNotification}/>
                    </div>
                </section>}
            </div>
        </Modal>
    }
}


const mapDispatchToProps = {
    SendNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendNotification)
