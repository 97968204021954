import { MEDIA_TYPES } from "../constants/constsTypes";

export function transformImage(key, url) {
    const name= key?.split('/')?.at(-1)
    return {
        key,
        url,
        type: MEDIA_TYPES.FILE,
        name,
        extension: name?.split('.')?.at(-1)
    }
}
