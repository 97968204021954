import { AUTH_CONSTS, CAFE_CONSTS } from "../constants";

export const initialState = {
    cafeDate: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case CAFE_CONSTS.GET_CAFES:
            return {
                ...state,
                cafeDate: action.payload ,
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
