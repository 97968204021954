// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    DeleteServiceById,
    UpdateServiceById,
    GetServices, UpdateServicePositions,
} from "../../redux/actions";
import { iconPaid, iconPackage } from "../../assets/images";

// Import components
import { NestableItem } from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import { deleteItem } from "../../utils/helperFunctions";
import { TYPE_CONST } from "../../constants/constsTypes";


class Package extends Component {
    constructor (props) {
        super(props);

        this.deleteCategoryItem = this.deleteCategoryItem.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount () {
        this.props.GetServices();
    }

    deleteCategoryItem (id) {
        this.props.DeleteServiceById(id).then(() => {
            this.props.GetServices();
        })
    }


    async onPositionChange (items, item) {
        const positionedItems = items.map((item, index) => {
            return { id: item.id, position: index + 1 }
        });
        await this.props.UpdateServicePositions(positionedItems);
        this.props.GetServices();
    }

    getNestableItems (list, parent = null) {
        const items = [];

        list && !!list.length && list.forEach((item, index) => {
            const title = item?.title || '';
            items.push({
                id: item.id,
                index: index,
                title: title,
                customIcon: item.type === TYPE_CONST.PAID ? iconPaid : iconPackage,
                isHidden: item.is_hidden,
                link: {
                    pathname: `package/edit/${item.id}`,
                },
                deleteCb: deleteItem.bind(this, this.deleteCategoryItem, 'Փաթեթը', item.id),
                children:  []
            })
        });
        return items;
    }


    render () {
        let { serviceList } = this.props;
        let items = this.getNestableItems(serviceList);
        return <PageWrapper pageTitle={'Փաթեթները'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/package/add"}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetServices,
    DeleteServiceById,
    UpdateServiceById,
    UpdateServicePositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Package);
