export const COMPOSITE_CONSTS = {
    GET_CONTACT: "GET_CONTACT",
    UPDATE_CONTACT: "UPDATE_CONTACT",
    GET_RULE: "GET_RULE",
    UPDATE_RULE: "UPDATE_RULE",

    GET_REGIONS: "GET_REGIONS",
    GET_REGION_RESIDENCES: "GET_REGION_RESIDENCES",
    CLEAR_REGION_RESIDENCES: "CLEAR_REGION_RESIDENCES",

    GET_RESOURCE: "GET_RESOURCE",
    UPDATE_RESOURCE: "UPDATE_RESOURCE",
    GET_APP_VERSION: "GET_APP_VERSION",
    UPDATE_APP_VERSION: "UPDATE_APP_VERSION",
};
