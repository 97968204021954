import { GENERAL_CONSTS, UTIL_CONSTS } from "../constants";
import {
    _urlDevice,
    _urlInvitations,
    _urlNotif,
    _urlUser,
    request
} from "../api";

export const SetRoutes = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.SET_ROUTES,
            payload: data
        });

    }
};

export const GetUser = ({
                            reset = true,
                            offset = 0,
                            limit = 10,
                            text = '',
                            getResponse = false,
                        }={}) => {
    const requestData = {
        url: `${_urlUser}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };
    if (text) {
        requestData.url += `&text=${text}`;
    }

    return dispatch => {
        reset && !getResponse && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                !getResponse &&  dispatch({
                    type: GENERAL_CONSTS.GET_USER,
                    payload: {
                        list: data?.records,
                        total_count: data?.total_count,
                        reset,
                        hasMore: data?.records?.length === limit,
                    }
                });
                if (getResponse) {
                    console.log('sdadasd', data)
                    return data?.records
                }
            })
    }
};

export const GetUserById = (id) => {
    const requestData = {
        url: `${_urlUser}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                return data
            })
    }
};


export const SendNotification = (data) => {
    const requestData = {
        url: `${_urlNotif}`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};

export const GetDevices = ({ reset = true, offset = 0, limit = 10 }) => {
    const requestData = {
        url: `${_urlDevice}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: GENERAL_CONSTS.GET_DEVICE,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            })
    }
};

export const GetInvitations = ({ reset = true, offset = 0, limit = 10, text = '' }) => {
    const requestData = {
        url: `${_urlInvitations}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    if (text) {
        requestData.url += `&text=${text}`;
    }

    return dispatch => {
        reset && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: GENERAL_CONSTS.GET_INVITATIONS,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            })
    }
};
