// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import styles
import "../assets/styles/containerStyles/contact-rule-resource.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {ContactAddingList} from "../constants/contactAddingList";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, toggleStateField, getTranslationsInitialValues, getPostReqData
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import {Editor} from "../components/uiElements/Editor";
import MediaSelectorModal from "../components/media/MediaSelectorModal";
import {ACCEPT_IMAGE_TYPES} from "../constants/acceptedTypes";
import {MAIN_LANGUAGE_TYPES} from "../constants/constsTypes";

const format = 'HH:mm';

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['address', 'phones','working_times','privacy_policy'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                email: "",
                url: '',
                fb_link: '',
                yt_link: '',
                in_link: '',
                ln_link: '',
                tk_link:'',
                tg_link:'',
            },
            mediaModalOpen: false,
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: MAIN_LANGUAGE_TYPES.en,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
    }

    async componentDidMount() {
        !this.props?.contactData?.id && await this.props.GetContact();
        const {contactData} = this.props;

        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                url: contactData.url || "",
                fb_link: contactData.fb_link || "",
                yt_link: contactData.yt_link || "",
                in_link: contactData.in_link || "",
                ln_link: contactData.ln_link || "",
                tk_link: contactData.tk_link || "",
                tg_link: contactData.tg_link || "",
                translations: getTranslationsInitialValues(contactData.translations,
                    ['address', 'phones','working_times', 'privacy_policy']),
            },
        });
    }

    updateContact() {
        this.setState({
            requestLoading: true
        });
        const {translations, url} = this.state.fieldsData;
        const translationRequiredFields = ['address', 'phones','working_times']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        if (result) {
            let reqData = this.getPostReqData(this.state.fieldsData);
            reqData.url = url || ""
            this.props.UpdateContact(reqData).then(() => {
                this.setState({
                    requestLoading: false,
                    errors: errors,
                    errorsTabs: validationTr.errorsTabs
                })
            })
        }
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs, mediaModalOpen} = this.state;
        const {translations} = fieldsData;
        const initTranslations = fieldsData.translations?.find(item => item?.lang === languageTab) || {};;
        const trData = translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={'Կապ'}>
            <section className="contact-rule-resource">
                <div className="info-adding-fields">
                    {ContactAddingList.map((item, index) => {
                        return <InputGroup key={index}
                                           {...item}
                                           error={errors[item.name]}
                                           value={fieldsData?.[item.name]}
                                           initValue={contact?.[item.name]}
                                           onChange={this.getInputValues}/>
                    })}
                    <div className="row">
                    </div>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address"
                            maxLength={100}
                            value={trData?.address}
                            required={true}
                            initValue={initTranslations?.address}
                            error={errors['address' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <div className={'editors-block'}>
                    <div className={'editors'}>
                        <div className={'editor-wrapper'}>
                            <label>հեռախոսահամարները <span className={'required-badge'}>*</span></label>
                            <Editor
                                value={trData?.phones || ""}
                                name={'phones'}
                                initValue={initTranslations?.phones}
                                error={errors['phones' + languageTab]}
                                onChange={this.getTranslatableInputValues}/>
                        </div>
                    </div>
                    <div className={'editors'}>
                        <div className={'editor-wrapper'}>
                            <label>Աշխատանքային ժամեր <span className={'required-badge'}>*</span></label>
                            <Editor
                                value={trData?.working_times || ""}
                                name={'working_times'}
                                initValue={fieldsData?.working_times}
                                error={errors['working_times' + languageTab]}
                                onChange={this.getTranslatableInputValues}/>
                        </div>
                    </div>
                </div>
                <div className={'editor-wrapper'}>
                    <label>Գաղտնիության քաղաքականություն</label>
                    <Editor
                        value={trData?.privacy_policy || ""}
                        name={'privacy_policy'}
                        initValue={fieldsData?.privacy_policy}
                        error={errors['privacy_policy' + languageTab]}
                        onChange={this.getTranslatableInputValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
