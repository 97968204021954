export const SPA_CONSTS = {
    GET_SPA: "GET_SPA",
    UPDATE_SPA: "UPDATE_SPA",

    CREATE_SPA_SERVICE: "CREATE_SPA_SERVICE",
    GET_SPA_SERVICE_BY_ID: "GET_SPA_SERVICE_BY_ID",
    UPDATE_SPA_SERVICE_BY_ID: "UPDATE_SPA_SERVICE_BY_ID",
    DELETE_SPA_SERVICE_BY_ID: "DELETE_SPA_SERVICE_BY_ID",

    CREATE_SPA_WORKER: "CREATE_SPA_WORKER",
    GET_SPA_WORKER_BY_ID: "GET_SPA_WORKER_BY_ID",
    UPDATE_SPA_WORKER_BY_ID: "UPDATE_SPA_WORKER_BY_ID",
    DELETE_SPA_WORKER_BY_ID: "DELETE_SPA_WORKER_BY_ID",
};
