export const ACCESS_PAGES = {
    user: {
        key: 'user',
        name: 'Օգտատերեր',
        icon: 'supervisor_account',
        crud: true,
        isHidden: false,
    },
    moderators: {
        key: 'MODERATORS',
        name: 'Մոդերատորներ',
        icon: 'supervised_user_circle',
        crud: false,
        isHidden: false,
    },
    media: {
        key: 'file',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    slider: {
        key: 'slider',
        name: 'Սլայդեր',
        icon: 'amp_stories',
        crud: true,
        isHidden: false,
    },
    event: {
        key: 'event_event-join',
        name: 'Իրադարձություն',
        icon: 'events',
        crud: true,
        isHidden: false,
    },
    article: {
        key: 'article',
        name: 'Բլոգ',
        icon: 'description',
        crud: true,
        isHidden: false,
    },
    trainer: {
        key: 'trainer',
        name: 'Մարզիչներ',
        icon: 'sports_martial_arts',
        crud: true,
        isHidden: false,
    },
    service: {
        key: 'fitness_spa_cafe_pool',
        name: 'Ծառայություններ',
        icon: 'manage_accounts',
        crud: true,
        isHidden: false,
    },
    package: {
        key: 'service',
        name: 'Փաթեթներ',
        icon: 'wysiwyg',
        crud: true,
        isHidden: false,
    },
    "package-request": {
        key: 'service-request',
        name: 'Հայտեր',
        icon: 'dynamic_form',
        crud: false,
        isHidden: false,
    },
    groupWorkout: {
        key: 'workout',
        name: 'Խմբային մարզումներ',
        icon: 'groups_3',
        crud: false,
        isHidden: false,
    },
    workoutSection: {
        key: 'workout',
        name: 'Մարզումների տեսակներ',
        icon: 'fitness_center',
        crud: true,
        isHidden: false,
    },
    categories: {
        key: 'category',
        name: 'Կատեգորիաներ',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'contact',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    rule: {
        key: 'rule',
        name: 'Կանոնակարգ',
        icon: 'security',
        crud: true,
        isHidden: false,
    },
    invitations: {
        key: 'voucher-code',
        name: 'Հրավերներ',
        icon: 'mark_unread_chat_alt',
        crud: true,
        isHidden: false,
    },
    devices: {
        key: 'device',
        name: 'Սարքեր',
        icon: 'devices',
        crud: true,
        isHidden: false,
    },
    faq: {
        key: 'faq',
        name: 'Հարց-Պատասխան',
        icon: 'psychology_alt',
        crud: true,
        isHidden: false,
    },
    resource: {
        key: 'resource',
        name: 'Տվյալներ',
        icon: 'view_timeline',
        crud: true,
        isHidden: false,
    },
    about: {
        key: 'about',
        name: 'Մեր Մասին',
        icon: 'account_tree',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'static-texts',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
    activity_notification: {
        key: 'activity_notification',
        name: 'Notification Logs',
        icon: 'query_stats',
        crud: false,
        isHidden: false,
    },
};
