// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationsInitialValues,
    initTranslations,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateService, UpdateServiceById, GetServiceById } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { MAIN_LANGUAGE_TYPES, TYPE_CONST } from "../../constants/constsTypes";
import { Editor } from "../../components/uiElements/Editor";
import { MaterialIcon } from "../../components/utils/Icon";
import { ColorPicker } from "../../components/uiElements/ColorPicker";

class AddEditPackage extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'name',
            'information',
            'details',
            'duration_options',

            "web_details",
            "web_description",
            "web_information",
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                type: undefined,
                position: 0,
                is_popular: false,
                name_color: '#000000'
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,

        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.addEditPackage = this.addEditPackage.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.addOptions = this.addOptions.bind(this);
        this.removeOptions = this.removeOptions.bind(this);
        this.getOptionsValues = this.getOptionsValues.bind(this);

        this.addDetailsOptions = this.addDetailsOptions.bind(this);
        this.removeDetailsOptions = this.removeDetailsOptions.bind(this);
        this.getDetailsOptionsValues = this.getDetailsOptionsValues.bind(this);
    }

    async componentDidMount () {
        const { isEditing, fieldsData } = this.state;
        if (isEditing) {
            await this.props.GetServiceById(this.props.id);
            const editingData = this.props.serviceById;
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations,
                        ['title', 'description', 'name', 'information', 'details', 'duration_options',
                            "web_details", "web_description", "web_information",]),
                    position: editingData?.position,
                    is_popular: editingData?.is_popular,
                    name_color: editingData?.name_color || fieldsData.name_color,
                    type: editingData?.type || fieldsData.type,
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }

    async addEditPackage () {
        await this.setState({
            requestLoading: true
        });
        const {
            translations,
            type,
        } = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!type) {
            errors.type = true;
            result = false;
        }
        if (result) {
            if (!this.state.isEditing) {
                let { serviceList } = this.props;

                let position = undefined
                let num = []
                if (serviceList?.length) {
                    serviceList?.forEach(item => {
                        num.push(item?.position)
                        return position = Math.max(...num)
                    })
                } else {
                    position = 0
                }

                let reqData = this.state.fieldsData;
                reqData.position = position + 1
                this.props.CreateService(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                let reqData = this.state.fieldsData;
                this.props.UpdateServiceById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    addOptions () {
        const { fieldsData, languageTab } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    duration_options: [...item?.duration_options, '']
                } : item)
            },
        })
    }

    removeOptions (position) {
        const { fieldsData, languageTab } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    duration_options: item?.duration_options.filter((_, index) => position !== index)
                } : item)
            },
        })
    }

    getOptionsValues ({ name, value, haveChanges }) {
        const { fieldsData, languageTab } = this.state;
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        haveChanges
            ? this.updatedDataMap.set("duration_options" + name, value)
            : this.updatedDataMap.delete("duration_options" + name);
        let newAttributeList = [...currentData?.duration_options];
        newAttributeList[name] = value;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    duration_options: newAttributeList
                } : item)
            },
            errors: {
                ...this.state.errors,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    [name]: false
                } : item)
            },
        });
    }


    addDetailsOptions () {
        const { fieldsData, languageTab } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    web_details: [...item?.web_details, '']
                } : item)
            },
        })
    }

    removeDetailsOptions (position) {
        const { fieldsData, languageTab } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    web_details: item?.web_details.filter((_, index) => position !== index)
                } : item)
            },
        })
    }

    getDetailsOptionsValues ({ name, value, haveChanges }) {
        const { fieldsData, languageTab } = this.state;
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        haveChanges
            ? this.updatedDataMap.set("web_details" + name, value)
            : this.updatedDataMap.delete("web_details" + name);
        let newAttributeList = [...currentData?.web_details];
        newAttributeList[name] = value;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    web_details: newAttributeList
                } : item)
            },
            errors: {
                ...this.state.errors,
                translations: fieldsData?.translations.map(item => item.lang === languageTab ? {
                    ...item,
                    [name]: false
                } : item)
            },
        });
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            requestLoading,
            isEditing,
            errorsTabs,
        } = this.state;


        const typeOptions = Object.values(TYPE_CONST).map(type => {
            return {
                id: type || '',
                title: type || ' '
            }
        });


        const { translations } = fieldsData;
        const trData = translations?.find(item => item?.lang === languageTab) || {};
        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData?.translations?.find(item => item?.lang === languageTab) || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Փաթեթը' : 'Փոփոխել Փաթեթը'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{fieldsData.is_popular ? "Ճանաչված" : "Ոչ Ճանաչված"}</label>
                    <Switch checked={fieldsData.is_popular}
                            onChange={(checked) => this.getInputValues({
                                name: "is_popular",
                                value: checked,
                                haveChanges: this.currentData?.is_popular !== !checked
                            })}/>
                </div>

                <InputGroup inputType="selectCustom"
                            label={"Տիպ"}
                            placeholder="Տիպ"
                            name="type"
                            showSearch={false}
                            value={fieldsData?.type}
                            error={errors?.type}
                            initValue={this.currentData?.type}
                            required={true}
                            onChange={this.getInputValues}
                            options={typeOptions}/>

                <ColorPicker color={fieldsData.name_color}
                             name={"name_color"}
                             initColor={this.currentData?.name_color}
                             onChange={this.getInputValues}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="slider-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Տեղեկություն"
                                placeholder="Տեղեկություն"
                                name="information"
                                value={currentData.information}
                                initValue={initData.information}
                                error={errors['information' + languageTab]}
                                maxLength={1000}
                                required={false}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Համար"
                                placeholder="Համար"
                                name="name"
                                value={currentData.name}
                                initValue={initData.name}
                                error={errors['name' + languageTab]}
                                maxLength={256}
                                required={false}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Առավելություն"
                                placeholder="Առավելություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={false}
                                onChange={this.getTranslationValues}/>

                </div>
                <div className={'editors'}>
                    <div className={'editor-wrapper'}>
                        <label>Նկարագրություն </label>
                        <Editor
                            value={trData?.details || ""}
                            name={'details'}
                            initValue={fieldsData?.details}
                            error={errors['details' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                    </div>
                </div>
                <div className={'options'}>
                    <label className={'label'}>Տևողությունները</label>
                    {currentData?.duration_options?.map((options, index) => {
                        return <div className="item-row" key={index}>
                            <InputGroup
                                value={options}
                                initValue={options}
                                error={errors['duration_options' + index + languageTab]}
                                name={index}
                                inputType={"input"}
                                placeholder={"Տեւողությունը"}
                                maxLength={100}
                                onChange={this.getOptionsValues}
                            />
                            {currentData?.duration_options?.length
                            && <LinkButton className="bg-red delete-item-btn"
                                           title={<MaterialIcon icon="delete"/>}
                                           cb={() => this.removeOptions(index)}/>}

                        </div>
                    })}
                    <LinkButton title={<MaterialIcon icon={'add'}/>}
                                className={errors['duration_options' + languageTab] ? "bg-red" : ''}
                                cb={this.addOptions}/>
                </div>


                <InputGroup inputType="input"
                            type="text"
                            label="Կայք Տեղեկություն"
                            placeholder="Կայք Տեղեկություն"
                            name="web_information"
                            value={currentData.web_information}
                            initValue={initData.web_information}
                            error={errors['web_information' + languageTab]}
                            maxLength={1000}
                            required={false}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="textarea"
                            type="text"
                            label="Կայք Նկարագրություն"
                            placeholder="Կայք Նկարագրություն"
                            name="web_description"
                            value={currentData.web_description}
                            initValue={initData.web_description}
                            error={errors['web_description' + languageTab]}
                            maxLength={1000}
                            required={false}
                            onChange={this.getTranslationValues}/>
                <div className={'options'}>
                    <label className={'label'}>Կայք Հատկություններ </label>
                    {!!Array.isArray(currentData?.web_details) && currentData?.web_details?.map((opt, index) => {
                        return <div className="item-row" key={index}>
                            <InputGroup
                                value={opt}
                                initValue={opt}
                                error={errors['web_details' + index + languageTab]}
                                name={index}
                                inputType={"input"}
                                placeholder={"Հատկություններ"}
                                maxLength={100}
                                onChange={this.getDetailsOptionsValues}
                            />
                            {currentData?.web_details?.length
                            && <LinkButton className="bg-red delete-item-btn"
                                           title={<MaterialIcon icon="delete"/>}
                                           cb={() => this.removeDetailsOptions(index)}/>}

                        </div>
                    })}
                    <LinkButton title={<MaterialIcon icon={'add'}/>}
                                cb={this.addDetailsOptions}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditPackage}/>
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateService,
    UpdateServiceById,
    GetServiceById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPackage)
