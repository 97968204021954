export const ContactAddingList = [
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        placeholder: "Էլ․ հասցե",
        name: "email",
        maxLength: 500
    },
    {
        label: "Կապ բաժնի կոճակի հղում",
        inputType: "input",
        type: "text",
        placeholder: "Հղում",
        name: "url",
        maxLength: 1000
    },
    {
        label: "Instagram",
        inputType: "input",
        type: "text",
        placeholder: "Instagram",
        name: "in_link",
        maxLength: 1000
    },
    {
        label: "Facebook",
        inputType: "input",
        type: "text",
        placeholder: "Facebook",
        name: "fb_link",
        maxLength: 1000
    },
    {
        label: "Linkedin",
        inputType: "input",
        type: "text",
        placeholder: "Linkedin",
        name: "ln_link",
        maxLength: 1000
    },
    {
        label: "Youtube",
        inputType: "input",
        type: "text",
        placeholder: "Youtube",
        name: "yt_link",
        maxLength: 1000
    },
    {
        label: "TikTok",
        inputType: "input",
        type: "text",
        placeholder: "TikTok",
        name: "tk_link",
        maxLength: 1000
    },
    {
        label: "Telegram",
        inputType: "input",
        type: "text",
        placeholder: "Telegram",
        name: "tg_link",
        maxLength: 1000
    },
];
