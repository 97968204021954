import { ACTIVITY_CONSTS, UTIL_CONSTS } from "../constants";
import {
    _urlActivityNotification, _urlActivityUser,
    request
} from "../api";


export const GetUserActivities = ({
                                      reset = true,
                                      offset = 0,
                                      limit = 30,
                                      moderator = null,
                                      userId = null,
                                      getResponse = false
                                  } = {}) => {
    console.log('mod', moderator)
    const requestData = {
        url: `${_urlActivityUser}/?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    userId && (requestData.url += `&userId=${userId}`);
    moderator && (requestData.url += `&moderatorId=${moderator}`);

    return dispatch => {
        reset && !getResponse && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                if (getResponse) {
                    return {
                        data,
                        hasMore: data.length === limit
                    };
                } else {
                    dispatch({
                        type: ACTIVITY_CONSTS.GET_USER_ACTIVITY,
                        payload: {
                            data: data,
                            reset,
                            hasMore: data.length === limit
                        }
                    })
                }
            })
            .finally(() => {
                reset && !getResponse && dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const RemoveUserActivities = () => {
    return dispatch => {
        dispatch({
            type: ACTIVITY_CONSTS.REMOVE_USER_ACTIVITY,
        });
    }
};


export const GetNotificationActivities = ({
                                              reset = true,
                                              offset = 0,
                                              limit = 30,
                                              getResponse = false,
                                              userId = ''
                                          } = {}) => {

    const requestData = {
        url:  `${_urlActivityNotification}/?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };
    console.log('userId', userId);
    userId && (requestData.url += `&userId=${userId}`);
    return dispatch => {
        reset && !getResponse && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                if (getResponse) {
                    return {
                        data,
                        hasMore: data.length === limit
                    };
                } else {
                    dispatch({
                        type: ACTIVITY_CONSTS.GET_NOTIFICATION_ACTIVITY,
                        payload: {
                            data: data,
                            reset,
                            hasMore: data.length === limit
                        }
                    })
                }
            })
            .finally(() => {
                reset && !getResponse && dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};
