import { AUTH_CONSTS, SPA_CONSTS } from "../constants";

export const initialState = {
    spa:{},
    spaServices: [],
    spaWorkers: [],
    spaServiceById: null,
    spaWorkerById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case SPA_CONSTS.GET_SPA:
            const {services, workers,...spa} = action.payload
            return {
                ...state,
                spa,
                spaServices: services || [],
                spaWorkers: workers || [],
            };
        case SPA_CONSTS.UPDATE_SPA:
            return {
                ...state,
                spa: action.payload
            };


        case SPA_CONSTS.CREATE_SPA_SERVICE:
            return {
                ...state,
                spaServices: [action.payload].concat(state.spaServices)
            };
        case SPA_CONSTS.GET_SPA_SERVICE_BY_ID:
            return {
                ...state,
                spaServiceById: action.payload
            };
        case SPA_CONSTS.UPDATE_SPA_SERVICE_BY_ID:
            return {
                ...state,
                spaServices: state.spaServices.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case SPA_CONSTS.DELETE_SPA_SERVICE_BY_ID:
            return {
                ...state,
                spaServices: state.spaServices.filter(item => item.id !== action.payload)
            };
            //==========================worker=======================
        case SPA_CONSTS.CREATE_SPA_WORKER:
            return {
                ...state,
                spaWorkers: [action.payload].concat(state.spaWorkers)
            };
        case SPA_CONSTS.GET_SPA_WORKER_BY_ID:
            return {
                ...state,
                spaWorkerById: action.payload
            };
        case SPA_CONSTS.UPDATE_SPA_WORKER_BY_ID:
            return {
                ...state,
                spaWorkers: state.spaWorkers.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case SPA_CONSTS.DELETE_SPA_WORKER_BY_ID:
            return {
                ...state,
                spaWorkers: state.spaWorkers.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
