// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import "../../assets/styles/containerStyles/contact-rule-resource.scss";


// Import components.
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues,
    getTranslatableInputValues,
    getTranslationData,
    getTranslationsInitialValues,
    initTranslations,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    DeleteAboutBlockById,
    GetAbout,
    UpdateAbout,
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES, ACCEPT_VIDEO_TYPES } from "../../constants/acceptedTypes";
import { history } from "../../configs/history";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MAIN_LANGUAGE_TYPES, MEDIA_MODAL_TYPES } from "../../constants/constsTypes";
import { transformImage } from "../../utils/transformImage";
import PageHeader from "../../components/pageContentViews/pageHeader";
import Nestable from "react-nestable";
import { NestableItem } from "../../components/cards/nestableItem";


class About extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'video_title',
            'video_description',
            'block_title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                logo: null,
                thumbnail: null,
                video: null,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
            mediaModalOpen: false,
            modalAllowedTypes: '',
        };
        this.updatedDataMap = new Map();
        this.currentData = null;

        this.getNestableItems = this.getNestableItems.bind(this);
        this.toggleMediaModal = this.toggleMediaModal.bind(this);
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateAbout = this.updateAbout.bind(this);

        this.deleteItem = deleteItem.bind(this, props.DeleteAboutBlockById, 'Բաժինը');

    }

    async componentDidMount () {
        await this.props.GetAbout();
        const { about } = this.props;
        if (about) {
            const initStateData = {
                translations: getTranslationsInitialValues(about.translations, [
                    'title',
                    'description',
                    'video_title',
                    'video_description',
                    'block_title'
                ]),
                logo: about?.logo_file_object_name ?
                    transformImage(about.logo_file_object_name, about.logo_url)
                    : null,
                thumbnail: about?.thumbnail_file_object_name ?
                    transformImage(about.thumbnail_file_object_name, about.thumbnail_url)
                    : null,
                video: about?.video_file_object_name ?
                    transformImage(about.video_file_object_name, about.video_url)
                    : null,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, modalAllowedTypes } = this.state;
        let newState;
        if (modalAllowedTypes === 'image_logo') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    logo: mediaArray[0],
                },
                errors: {
                    ...errors,
                    logo: false
                }
            }
        } else if (modalAllowedTypes === 'image_thumbnail') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    thumbnail: mediaArray[0],
                },
                errors: {
                    ...errors,
                    thumbnail: false
                }
            }
        } else {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    video: mediaArray[0],
                },
                errors: {
                    ...errors,
                    video: false
                }
            }
        }
        this.setState(newState)
    }

    toggleMediaModal (type = '') {
        this.setState({
            mediaModalOpen: !this.state.mediaModalOpen,
            modalAllowedTypes: type
        })
    }

    async updateAbout () {
        await this.setState({
            requestLoading: true
        });
        const { about } = this.props;
        const { translations, logo, thumbnail, video, } = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };
        if (!logo) {
            errors.logo = true;
            result = false;
        }
        if (!thumbnail) {
            errors.thumbnail = true;
            result = false;
        }
        if (!video) {
            errors.video = true;
            result = false;
        }

        if (result && about.id) {
            let reqData = {
                logo_object_name: logo?.key,
                thumbnail_object_name: thumbnail?.key,
                video_object_name: video?.key,
                translations: getTranslationData(translations)
            }
            if (Object.keys(reqData).length) {
                this.props.UpdateAbout(about.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            } else {
                await this.setState({ requestLoading: false });
                history.push('/about')
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getNestableItems (list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                path: item?.image_url,
                link: {
                    pathname: `/about/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            errorsTabs,
            modalAllowedTypes,
        } = this.state;
        const { aboutBlocks } = this.props;
        let items = this.getNestableItems(aboutBlocks);
        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={'Մեր Մասին'}>
            <section className="general-add-edit">
                <div className={'tops-wrapper'}>
                    <div className="top-side">
                        <label>Նկար Կայք<span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.logo ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'image_logo')}
                        />
                        {fieldsData?.logo && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.logo}
                            />
                        </div>}
                    </div>
                    <div className="top-side">
                        <label>Վիդեո <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.video ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'video')}
                        />
                        {fieldsData?.video && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.video}/>
                        </div>}
                    </div>
                    <div className="top-side">
                        <label>Վիդեո-Նկար <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.thumbnail ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'image_thumbnail')}
                        />
                        {fieldsData?.thumbnail && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.thumbnail}/>
                        </div>}
                    </div>

                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            required={true}
                            value={currentData.description}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Բաժնի Անվանում"
                            placeholder="Բաժնի Անվանում"
                            name="block_title"
                            required={true}
                            value={currentData.block_title}
                            initValue={initData.block_title}
                            error={errors['block_title' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Վիդեո Անվանում"
                            placeholder="Վիդեո Անվանում"
                            name="video_title"
                            required={true}
                            value={currentData.video_title}
                            initValue={initData.video_title}
                            error={errors['video_title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Վիդեո Նկարագրություն"
                            placeholder="Վիդեո Նկարագրություն"
                            name="video_description"
                            required={true}
                            value={currentData.video_description}
                            initValue={initData.video_description}
                            error={errors['video_description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                cb={this.updateAbout}/>
                </div>


                <PageHeader linkTitle={"Ավելացնել Բաժին"}
                            addingLink={"about/add"}/>

                {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    confirmChange={() => false}
                    maxDepth={1}
                    handler={() => {
                    }}
                    renderItem={NestableItem}
                />}


            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={modalAllowedTypes === 'video' ? ACCEPT_VIDEO_TYPES : ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAbout,
    UpdateAbout,
    DeleteAboutBlockById
};

export default connect(mapStateToProps, mapDispatchToProps)(About)
