// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import components
import { NestableItem } from "../../../components/cards/nestableItem";
import PageHeader from "../../../components/pageContentViews/pageHeader";

// Import utils
import { mapStateToProps } from "../../../redux/mapStateToProps";
import { deleteItem } from "../../../utils/helperFunctions";
import {
    UpdateFitness,
    DeleteFitnessServiceById,
    GetFitness,
} from "../../../redux/actions";
import { transformImage } from "../../../utils/transformImage";
import { LinkButton } from "../../../components/buttons/buttons";
import { MediaFileCard } from "../../../components/media/MediaFileCard";
import MediaSelectorModal from "../../../components/media/MediaSelectorModal";
import { ACCEPT_IMAGE_TYPES } from "../../../constants/acceptedTypes";


class Fitness extends Component {
    constructor (props) {
        super(props);
        this.state = {
            fieldsData: {
                logo: null,
            },
            errors: {},
            mediaModalOpen: false,
        };
        this.toggleMediaModal = this.toggleMediaModal.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteItem = deleteItem.bind(this, props.DeleteFitnessServiceById, 'Ֆիթնեսը');
        this.onPositionChange = this.onPositionChange.bind(this);
        this.updateFitness = this.updateFitness.bind(this);
    }

    async componentDidMount () {
        await this.props.GetFitness();
        let { fieldsData } = this.state;
        let { fitness } = this.props;
        this.setState({
            fieldsData: {
                ...fieldsData,
                logo: fitness?.logo_file_object_name ? transformImage(fitness?.logo_file_object_name, fitness?.logo_url) : null
            }
        })
    }

    getNestableItems (list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.image_url,
                link: {
                    pathname: `fitness/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange (items, item) {
        const movedSliderId = item.id;
        const { fitnessServices } = this.props;
        const positionedItems = items.map((item, index) => {
            return { id: item.id, position: index }
        });
        if (~fitnessServices.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            this.props.GetFitness();
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
                fieldsData: {
                    ...fieldsData,
                    logo: mediaArray[0],
                },
                errors: {
                    ...errors,
                    logo: false
                }
            }
        )
    }

    updateFitness () {
        const {  logo } = this.state.fieldsData
        if (!logo?.key) {
            this.setState({
                errors: {
                    logo: true,
                }
            })
            return;
        }
        this.props.UpdateFitness({
            logo_object_name: logo?.key,
        })
    }

    toggleMediaModal () {
        this.setState({
            mediaModalOpen: !this.state.mediaModalOpen,
        })
    }

    render () {
        let { fieldsData, mediaModalOpen,errors } = this.state;
        let { fitnessServices, fitness } = this.props;
        let items = this.getNestableItems(fitnessServices);
        return <div className={'general-add-edit'}>
            <div className="top-side">
                <label>Նկար Կայք<span className={'required-badge'}>*</span></label>
                <LinkButton title="Ընտրել մեդիադարանից"
                            className={`media-select-btn ${errors.logo ? 'media-select-error' : ''}`}
                            cb={this.toggleMediaModal}
                />
                {fieldsData?.logo && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.logo}
                    />
                </div>}
            </div>
            <div className="flex-wrapper-right">
                <LinkButton title="Փոփոխել"
                            disabled={!(fieldsData?.logo?.key !== fitness?.logo_file_object_name)}
                            cb={this.updateFitness}
                />
            </div>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"fitness/add"}/>

            {items && items.length !== 0 &&
            <Nestable
                items={items}
                confirmChange={() => false}
                maxDepth={1}
                handler={() => {
                }}
                renderItem={NestableItem}
            />}
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </div>

    }
}

const mapDispatchToProps = {
    UpdateFitness,
    GetFitness,
    DeleteFitnessServiceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fitness);
