import { AUTH_CONSTS, EVENT_CONSTS } from "../constants";

export const initialState = {
    eventData:{
        eventList: [],
        hasMore: false,
    },
    eventById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case EVENT_CONSTS.GET_EVENTS:
            return {
                ...state,
                eventData: {
                    eventList:action.payload.reset ? action.payload.data : [...state.eventData.eventList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };
        case EVENT_CONSTS.CREATE_EVENT:
            return {
                ...state,
                eventData: {
                    ...state.eventData,
                    eventList: [ ...state.eventData.eventList, action.payload,],
                }
            };
        case EVENT_CONSTS.GET_EVENT_BY_ID:
            return {
                ...state,
                eventById: action.payload
            };
        case EVENT_CONSTS.UPDATE_EVENT_BY_ID:
            return {
                ...state,
                eventData: {
                    ...state.eventData,
                    eventList: state.eventData.eventList.map(item => item.id === action.payload?.id ? action.payload : item)
                }
            };
        case EVENT_CONSTS.DELETE_EVENT_BY_ID:
            return {
                ...state,
                eventData: {
                    ...state.eventData,
                    eventList: state.eventData.eventList.filter(item => item.id !== action.payload)
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
