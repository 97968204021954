import {request, _urlPool} from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {POOL_CONSTS} from "../constants";

export const GetPool = () => {
    const requestData = {
        url: _urlPool,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: POOL_CONSTS.GET_POOL,
                    payload: data
                });
            })
    }
};


export const UpdatePoolById = (data) => {
    const requestData = {
        url: _urlPool,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:POOL_CONSTS.GET_POOL,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Լողավազանը հաջողությամբ փոփոխվեց!"
                })
                history.push('/service/pool')
                return res.data
            })
            .catch(() => {
            })
    }
};
