import React from "react";
import {Link} from "react-router-dom";

//import assets
import "../../assets/styles/headerStyles/headerLogo.scss";
import { AppLogo } from "../../assets/images";


export function HeaderLogo() {
    return <Link to={'/event'} className="header-logo">
        <div className={'orange'}>
            Orange Fitness
        </div>
        {/*<AppLogo/>*/}
        {/*<div className="brand-name">Shat.am</div>*/}
    </Link>
}
