import { request, _urlFitness, _urlFitnessService } from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import { FITNESS_CONSTS  } from "../constants";

export const GetFitness = () => {
    const requestData = {
        url: _urlFitness,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                console.log(data);
                dispatch({
                    type: FITNESS_CONSTS.GET_FITNESS,
                    payload: data
                });
            })
    }
};

export const UpdateFitness = ( data) => {
    const requestData = {
        url: `${_urlFitness}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:FITNESS_CONSTS.UPDATE_FITNESS,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ֆիթնեսը հաջողությամբ փոփոխվեց!"
                })
                history.push('/service/fitness')
            })
            .catch(() => {
            })
    }
};

export const GetFitnessServiceById = id => {
    const requestData = {
        url: `${_urlFitnessService}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: FITNESS_CONSTS.GET_FITNESS_SERVICE_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const DeleteFitnessServiceById = id => {
    const requestData = {
        url: `${_urlFitnessService}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FITNESS_CONSTS.DELETE_FITNESS_SERVICE_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateFitnessService = data => {
    const requestData = {
        url:_urlFitnessService,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FITNESS_CONSTS.CREATE_FITNESS_SERVICE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ֆիթնես ծառայությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/service/fitness')
            })
            .catch(() => {
            })
    }
};

export const UpdateFitnessServiceById = (id, data) => {
    const requestData = {
        url: `${_urlFitnessService}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:FITNESS_CONSTS.UPDATE_FITNESS_SERVICE_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ֆիթնես ծառայությունը հաջողությամբ փոփոխվեց!"
                })
                history.push('/service/fitness')
            })
            .catch(() => {
            })
    }
};
