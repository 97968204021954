// Import packages
import React from "react";
import swal from "sweetalert";

// Import components
import {CustomDropdown} from "../uiElements/CustomDropdown"

// Import styles
import "../../assets/styles/headerStyles/right-menu.scss";
import { AppLogoSmall } from "../../assets/images";

//import utils

export function RightMenu(props) {


    const logUserOut = () => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք դուրս գալ?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                props.LogOut();
            }
        });
    };



    return <div className="right-menu">
        {/*<CustomDropdown inner={<MaterialIcon className="notification-icon" icon="notifications"/>}
                        title={"Notifications"}
                        rightProps={<div className="clear-notifications-btn"><MaterialIcon icon="clear_all"/>Մաքրել</div>}
                        content={<>
                            <div className="empty-view-wrapper">
                                <img src={defaultEmptyView} alt=""/>
                                <div className="empty-view-content">
                                    <h2>No notifications right now</h2>
                                    <p>When your team starts working on things, we’ll show the notifications here.</p>
                                </div>
                            </div>
                        </>}
        />*/}
        {/*<Link to={"/settings"} className={`right-menu-item ${location.pathname.includes("settings") ? "active" : ""}`}>*/}
        {/*    <MaterialIcon icon="settings"/>*/}
        {/*</Link>*/}
        <CustomDropdown className={"menu-wrapper"}
                        inner={<div className="inner-img">
                            <AppLogoSmall title={''}/>
                        </div>}
                        content={<>
                            <div className="menu-item" onClick={() => logUserOut()} key={'log-out'}>Դուրս գալ</div>
                        </>}/>
    </div>
}
