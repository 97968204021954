import { AUTH_CONSTS, TRAINERS_CONSTS } from "../constants";

export const initialState = {
    team: {},
    trainerList: [],
    trainerById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case TRAINERS_CONSTS.GET_TEAM:
            return {
                ...state,
                team: action.payload,
            };
        case TRAINERS_CONSTS.UPDATE_TEAM:
            return {
                ...state,
                team: action.payload,
            };
        case TRAINERS_CONSTS.GET_TRAINERS:
            return {
                ...state,
                trainerList: action.payload || [],
            };
        case TRAINERS_CONSTS.CREATE_TRAINER:
            return {
                ...state,
                trainerList: [action.payload].concat(state.trainerList)
            };
        case TRAINERS_CONSTS.GET_TRAINER_BY_ID:
            return {
                ...state,
                trainerById: action.payload
            };
        case TRAINERS_CONSTS.UPDATE_TRAINER_BY_ID:
            return {
                ...state,
                trainerList: state.trainerList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case TRAINERS_CONSTS.DELETE_TRAINER_BY_ID:
            return {
                ...state,
                trainerList: state.trainerList.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
