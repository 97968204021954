import { AUTH_CONSTS, GROUP_WORKOUT_CONSTS } from "../constants";

export const initialState = {
    groupWorkout: {
        groupWorkoutList:[],
        hasMore: false,
    },
    groupWorkoutById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case GROUP_WORKOUT_CONSTS.GET_GROUP_WORKOUTS:
            return {
                ...state,
                groupWorkout: {
                    groupWorkoutList:action.payload.reset ? action.payload.data : [...state.groupWorkout.groupWorkoutList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };
        case GROUP_WORKOUT_CONSTS.CREATE_GROUP_WORKOUT:
            return {
                ...state,
                groupWorkout: {
                    groupWorkoutList: [action.payload].concat(state.groupWorkout.groupWorkoutList)
                }
            };
        case GROUP_WORKOUT_CONSTS.GET_GROUP_WORKOUT_BY_ID:
            return {
                ...state,
                groupWorkoutById: action.payload
            };
        case GROUP_WORKOUT_CONSTS.UPDATE_GROUP_WORKOUT_BY_ID:
            return {
                ...state,
                groupWorkout: {
                    groupWorkoutList: state.groupWorkout.groupWorkoutList.map(item => item.id === action.payload?.id ? action.payload : item)
                }
            };
        case GROUP_WORKOUT_CONSTS.DELETE_GROUP_WORKOUT_BY_ID:
            return {
                ...state,
                groupWorkout: {
                    groupWorkoutList: state.groupWorkout.groupWorkoutList.filter(item => item.id !== action.payload)
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
