import { request, _urlFAQ } from "../api";
import { FAQ_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetFAQs = () => {
    const requestData = {
        url: _urlFAQ,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type:FAQ_CONSTS.GET_FAQS,
                    payload: data
                });
            })
    }
};

export const GetFAQById = id => {
    const requestData = {
        url: `${_urlFAQ}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: FAQ_CONSTS.GET_FAQ_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const DeleteFAQById = id => {
    const requestData = {
        url: `${_urlFAQ}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type:FAQ_CONSTS.DELETE_FAQ_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateFAQ = data => {
    const requestData = {
        url:_urlFAQ,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FAQ_CONSTS.CREATE_FAQ,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Հարց-Պատասխանը հաջողությամբ ստեղծվեց!"
                })
                history.push('/faq')
            })
            .catch(() => {
            })
    }
};

export const UpdateFAQById = (id, data) => {
    const requestData = {
        url: `${_urlFAQ}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:FAQ_CONSTS.UPDATE_FAQ_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Հարց-Պատասխանը հաջողությամբ փոփոխվեց!"
                })
                history.push('/faq')
            })
            .catch(() => {
            })
    }
};

export const UpdateFAQPositions = items => {
    const requestData = {
        url: _urlFAQ,
        token: true,
        method: "PATCH",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Հարց-Պատասխանները հաջողությամբ փոփոխվեցին!"
                })
            })
            .catch(() => {
            })
    }
};
