import { AUTH_CONSTS, COMPOSITE_CONSTS } from "../constants";

export const initialState = {
    contactData: {},
    ruleData: {},
    regions: [],
    regionResidences: [],
    resourceData: {},
    appVersionData: {},
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case COMPOSITE_CONSTS.GET_CONTACT:
        case COMPOSITE_CONSTS.UPDATE_CONTACT:
            return {
                ...state,
                contactData: action.payload
            };
        case COMPOSITE_CONSTS.GET_RULE:
        case COMPOSITE_CONSTS.UPDATE_RULE:
            return {
                ...state,
                ruleData: action.payload
            };
        case COMPOSITE_CONSTS.GET_REGIONS:
            return {
                ...state,
                regions: action.payload
            };
        case COMPOSITE_CONSTS.GET_REGION_RESIDENCES:
            return {
                ...state,
                regionResidences: action.payload
            };
        case COMPOSITE_CONSTS.CLEAR_REGION_RESIDENCES:
            return {
                ...state,
                regionResidences: []
            };
        case COMPOSITE_CONSTS.GET_RESOURCE:
        case COMPOSITE_CONSTS.UPDATE_RESOURCE:
            return {
                ...state,
                resourceData: action.payload
            };
        case COMPOSITE_CONSTS.GET_APP_VERSION:
        case COMPOSITE_CONSTS.UPDATE_APP_VERSION:
            return {
                ...state,
                appVersionData: action.payload
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
