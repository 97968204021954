import {
    ACCEPT_FILE_TYPES,
    MAX_FILE_SIZE,
    ACCEPT_IMAGE_TYPES,
    ACCEPT_LOGO_ADD_TYPES
} from "../../../../constants/acceptedTypes";
import swal from "sweetalert";
import { FILE_TYPES } from "../../../../constants/fileTypes";
import { setUpLoadProgresses } from "../../../../redux/actions";
import { store } from "../../../../redux/store";
import { uploadBox } from "../../UploadBox";
import { GetFileUploadS3SignedUrl } from "../../../../redux/actions";

export async function uploadMedias () {
    const { folderList, uploadProgresses } = this.props;
    const currentFolder = folderList?.[folderList?.length - 1];

    const { addingFiles, currentUploadingId } = this.state;
    let errors = {}
    const filesUploadDataPromises = addingFiles.map(async (item)=>{
        const uploadingFile = item.croppedImageFile || item.file
        const getUploadUrlPayload = {
            "fileName": uploadingFile.name,
            "prefix": currentFolder ? currentFolder.key : ""
        }
        const fileUploadingUrl = await GetFileUploadS3SignedUrl(getUploadUrlPayload)
        return {
            url: fileUploadingUrl,
            file: uploadingFile,
        }
    })
    const filesUploadData= await Promise.all(filesUploadDataPromises)
    if (Object.keys(errors).length) {
        this.setState({
            errors
        })
        return
    }

    const uploadId = currentUploadingId || Date.now() // errori jamanak vor eli upload ani currentUploadingId nuyn@ piti mna,
    await this.setState({
        uploading: true,
        currentUploadingId: uploadId,
        errors, // delete old errors
    })
    await setUpLoadProgresses({
        ...uploadProgresses,
        [uploadId]: {
            progress: 0,
            itemsCount: addingFiles.length,
            status: 'active',
            folderId: currentFolder?.key,
            folderList,
            pathName: 'Ֆայլեր' + folderList.map(f => '/' + f.name)
        }
    })

    const filesUploadPromises=filesUploadData.map(async item=>{
       return this.props.UploadFile(item.url, item.file,  this.onUploadProgress, uploadId)
    })
    await Promise.all(filesUploadPromises).then(async() => {
        const { uploadProgresses, folderList } = store.getState().media;
        const currentFolder = folderList?.[folderList?.length - 1];
        setUpLoadProgresses({
            ...uploadProgresses,
            [uploadId]: {
                ...uploadProgresses[uploadId],
                progress: 100,
                status: 'success',
            }
        })
        if (currentFolder?.key === uploadProgresses[uploadId].folderId) {
            this.props.GetMedia({ parent: currentFolder?.key });
        }
        if (this.state.currentUploadingId === uploadId) {
            this.closeAddMedia();
            const newUploadedProgresses = { ...uploadProgresses }
            delete newUploadedProgresses[uploadId];
            setUpLoadProgresses(newUploadedProgresses)
        }

    }).catch(() => {
        const { uploadProgresses, } = store.getState().media;
        setUpLoadProgresses({
            ...uploadProgresses,
            [uploadId]: {
                ...uploadProgresses[uploadId],
                status: 'exception',
            }
        })
        this.setState({
            uploading: false,
        })
    });

}

export async function onDropFile (e) {
    e.stopPropagation();
    e.preventDefault();
    const files = e?.target?.files || e?.dataTransfer?.files;

    if (!files || !files.length) {
        return
    }
    let errorFiles = [];
    for ( let i = 0; i < files.length; i++ ) {
        let file = files[i];
        // console.log('file',file)
        const extensionSupported = hasExtension(file.type);
        if (
            !extensionSupported
            || file.size > MAX_FILE_SIZE
        ) {

            errorFiles.push({
                name: file.name,
                extension: !extensionSupported,
                size: file.size > MAX_FILE_SIZE
            })
        }
    }
    if (errorFiles.length) {
        swal({
            title: "Զգուշացում!",
            text: errorFiles.map(errFile => {
                return `${errFile.name} - ${errFile.extension ? ' չթույլատրվաց ֆորմատով է, ' : ''} ${errFile.size ? ' չափսը 200mb -ից մեծ է,' : ''}`
            }).join('\n'),
            icon: "info",
            button: "Ok"
        });

    } else {
        const addingFiles = [];
        for ( let i = 0; i < files.length; i++ ) {
            let file = files[i];
            let objectUrl = URL.createObjectURL(file);

            // console.log('file', file)
            addingFiles.push({
                imageUrl: objectUrl,
                file,
                originalFile: file,
                url: '',
                logoAdded: false,
            })
        }
        // console.log(addingFiles);
        this.setState({
            addingFiles,
            filesIsChoose: true,
            addFileCardOpen: false,
            chooseType: null,
            name: '',
            url: '',
            errors: {},
        })
    }
}


export function minimizeUpload () {
    const { currentUploadingId } = this.state;
    uploadBox(currentUploadingId)
    this.closeAddMedia();
}

export function onUploadProgress (progressEvent, uploadId) {
    const uploadProgresses = store.getState().media.uploadProgresses;
    // console.log('onUploadProgress', uploadProgresses)
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

    // console.log('percentCompleted', percentCompleted)
    setUpLoadProgresses({
        ...uploadProgresses,
        [uploadId]: {
            ...uploadProgresses[uploadId],
            progress: percentCompleted !== 100 ? percentCompleted : 99
        }
    })
}

export function hasExtension (fileType) {
    const type = FILE_TYPES[fileType];
    if (!type) {
        return false;
    }
    return ACCEPT_FILE_TYPES.some(acceptType => acceptType === type);
}

export function isImage (fileType) {
    const type = FILE_TYPES[fileType];
    if (!type) {
        return false;
    }
    return ACCEPT_IMAGE_TYPES.some(acceptType => acceptType === type);
}

export function getMedias (addingFiles) {
    this.setState({
        addingFiles
    });
    !addingFiles.length && this.closeAddMedia();
}

export function onUploadClick (e) {
    e.target.value = null;
}

export function triggerFileUpload () {
    this.inputElement.current.click();
}

export function closeAddMedia () {
    this.setState({
        uploading: false,
        filesIsChoose: false,
        currentUploadingId: null,
        addingFiles: [],
        errors: {}
    })
}
