// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetDevices,
} from "../../redux/actions";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";
import moment from "moment";
import {GET_LIMITS} from "../../constants/constsTypes";
import ModalSendNotification from "../../components/uiElements/ModalSendNotification";
import {LinkButton} from "../../components/buttons/buttons";
import InfiniteScroll from "react-infinite-scroller";
import {defaultEmptyView} from "../../assets/images";
import PageHeader from "../../components/pageContentViews/pageHeader";


class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            selectingActive: false,
            selectedUsers: [],
            sendModalOpen: false,
            allSelected: false,
        }
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.toggleSelecting = this.toggleSelecting.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetDevices({
            offset:0,
            limit: GET_LIMITS,
        });
    }

    toggleNotificationModal(notificationSuccessfulSend = false) {
        const newState = {
            sendModalOpen: !this.state.sendModalOpen
        }
        this.state.allSelected && (newState.allSelected = false)
        if (notificationSuccessfulSend) {
            newState.selectingActive = false;
            newState.selectedUsers = [];
            newState.sendModalOpen = false;
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    loadMoreItems(){
        const { hasMore,deviceList} = this.props.deviceDate;
        hasMore && this.props.GetDevices({
            reset: false,
            offset:deviceList.length,
            limit: GET_LIMITS,
        });
    }

    selectUser(userId) {
        let {selectingActive, selectedUsers} = this.state;
        if (selectingActive) {
            const filteredUsers = selectedUsers.filter(id => id !== userId);
            this.setState({
                selectedUsers: filteredUsers.length === selectedUsers.length ?
                    [userId, ...selectedUsers] : filteredUsers
            })
        }
    }

    selectAll() {
        this.setState({
            allSelected: true,
            selectedUsers: [],
            sendModalOpen: true
        })
    }


    toggleSelecting() {
        const newState = {selectingActive: !this.state.selectingActive};
        if (this.state.selectingActive) {
            newState.selectedUsers = [];
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    render() {
        let {deviceDate, requestLoading} = this.props;
        const { selectedUsers, sendModalOpen, allSelected,selectingActive} = this.state;

        let itemListHeaderInfo = {
            generalInfo: ["Հարթակ","Ծանուցման կարգավիճակը","Լեզու", "Ամսաթիվ"],
            actions: selectingActive && ["send"]
        };
        let itemListInfo = deviceDate?.deviceList?.map((device) => {
            const {device_id, created_at,platform,settings} = device;
            return {
                device_id,
                values: [
                    platform,
                    settings.is_notification_enabled ? "Ծանուցումը միացված է" : "Ծանուցումը անջատված է",
                    settings?.lang,
                    moment(created_at).format('DD/MM/YYYY HH:mm')
                ],
                openModalCb:this.selectUser.bind(this, device_id),
                selected: selectingActive && (selectedUsers.find(userId => userId === device.device_id) || allSelected),
            }
        });
        return <PageWrapper pageTitle={'Սարքեր'}>
            <PageHeader  rightPartProps={ <div className={'buttons-block'}>
                {selectingActive && <LinkButton title={'Առաջ'}
                                                className={`bg-white size-sm`}
                                                disabled={!selectedUsers?.length}
                                                cb={this.toggleNotificationModal}/>}
                {selectingActive && <LinkButton title={'Բոլորին'}
                                                className={`bg-white size-sm`}
                                                cb={this.selectAll}/>}
                <LinkButton title={selectingActive ? 'Չեղարկել' : 'Ուղարկել ծանուցում'}
                            className={`send-notification-btn size-sm ${selectingActive ? "bg-default-important" : ''}`}
                            cb={this.toggleSelecting}/>
            </div>}/>

            <InfiniteScroll
                hasMore={deviceDate?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'groupWorkoutList'}
                useWindow={true}
                initialLoad={false}>
                {!!deviceDate?.deviceList?.length ?
                    <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                               itemListInfo={itemListInfo}
                               loading={requestLoading}/>
                    : <div className={'empty-view'}>
                        <img src={defaultEmptyView}/>
                    </div>}
            </InfiniteScroll>
            <ModalSendNotification visible={sendModalOpen}
                                   sendToAllUsers={allSelected}
                                   userIds={selectedUsers}
                                   type={'device'}
                                   closeModal={this.toggleNotificationModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
