// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import { defaultEmptyView } from "../../assets/images";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    DeleteGroupWorkoutById,
    GetGroupWorkouts,
} from "../../redux/actions";
import InfiniteScroll from "react-infinite-scroller";
import {GET_LIMITS} from "../../constants/constsTypes";
import GenerateTimeLineImage from "./GenerateTimelineImage";

class GroupWorkout extends Component {
    constructor(props) {
        super(props);
        this.deleteItem = deleteItem.bind(this, props.DeleteGroupWorkoutById, 'Խմբային մարզումները');
        this.onPositionChange = this.onPositionChange.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetGroupWorkouts({
            offset:0,
            limit: GET_LIMITS,
        });
    }

    getNestableItems(list, parent = null) {
        const items = [];
        let date = []
        date.push(list)
        date && !!date.length && date.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.logo_url,
                link: {
                    pathname: `/groupWorkout/edit/${item.id}`,
                },
                detailsLink: {
                    pathname: `/groupWorkout/timeline/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    loadMoreItems(){
        const { hasMore,groupWorkoutList} = this.props.groupWorkout;
        hasMore && this.props.GetGroupWorkouts({
            reset: false,
            offset:groupWorkoutList.length,
            limit: GET_LIMITS,
        });
    }


    async onPositionChange(items, item) {
        const movedSliderId = item.id;
        const {groupWorkout} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~groupWorkout?.groupWorkoutList.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            // this.props.GetGroupWorkouts({
            //     offset:0,
            //     limit: 6,
            // });
        }
    }

    render() {
        let {groupWorkout} = this.props;
        return <PageWrapper pageTitle={'Խմբային մարզումները'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/groupWorkout/add"}/>
            <GenerateTimeLineImage/>
            <InfiniteScroll
                hasMore={groupWorkout?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'groupWorkoutList'}
                useWindow={true}
                initialLoad={false}>
                {!!groupWorkout?.groupWorkoutList.length ? groupWorkout?.groupWorkoutList.map((item) => {
                        return <Nestable
                            items={this.getNestableItems(item)}
                            confirmChange={()=>false}
                            maxDepth={1}
                            handler={()=>{}}
                            renderItem={NestableItem}
                        />
                    }) :
                    <div className={'empty-view'}>
                        <img src={defaultEmptyView}/>
                    </div>}
            </InfiniteScroll>
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetGroupWorkouts,
    DeleteGroupWorkoutById,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupWorkout);
