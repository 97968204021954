// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetWorkoutSections,
    DeleteWorkoutSectionById,
} from "../../redux/actions";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {deleteItem} from "../../utils/helperFunctions";


class WorkoutSection extends Component {
    constructor(props) {
        super(props);

        this.deleteWorkoutSectionItem = this.deleteWorkoutSectionItem.bind(this);
    }

    componentDidMount() {
        this.props.GetWorkoutSections();
    }

    deleteWorkoutSectionItem(id){
        this.props.DeleteWorkoutSectionById(id).then(()=>{
            this.props.GetWorkoutSections();
        })
    }


    findParent(currentItem, id) {
        if (currentItem.children && currentItem.children.length &&
            currentItem.children.find(item => item.id === id)) {
            let movedItemNewIndex = -1;
            const positionedItems = currentItem.children.map((item, index) => {
                item.id === id && (movedItemNewIndex = index);
                return {id: item.id, position: index}
            });
            return {
                parent: currentItem.id,
                movedItemNewIndex: movedItemNewIndex,
                positionedItems
            };
        } else {
            let resData = null;
            currentItem.children && currentItem.children.length && currentItem.children.forEach(item => {
                let result = this.findParent(item, id);
                if (result) {
                    resData = result;
                }
            });
            return resData;
        }
    }

    getNestableItems(list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                isHidden: item.is_hidden,
                link: {
                    pathname: `workoutSection/edit/${item.id}`,
                },
                deleteCb: deleteItem.bind(this, this.deleteWorkoutSectionItem, 'Մարզումների տեսակ', item.id),
            })
        });
        return items;
    }


    render() {
        let {workoutSectionsList} = this.props;
        let items = this.getNestableItems(workoutSectionsList);
        return <PageWrapper pageTitle={'Մարզումների տեսակ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/workoutSection/add"}/>
            {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={3}
                    handler={()=>{}}
                    renderItem={NestableItem}
                />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetWorkoutSections,
    DeleteWorkoutSectionById,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutSection);
