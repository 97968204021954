import swal from "sweetalert";
import { LANGUAGE_TYPES } from "../constants/constsTypes";
import { transformImage } from "./transformImage";

export function changeStateField (field, value) {
    this.setState({
        [field]: value,
    })
}

export function changeStateInputValue ({ value, name }) {
    this.setState({
        [name]: value,
    })
}

export function toggleStateField (field) {
    this.setState({
        [field]: !this.state[field],
    })
}

export function initTranslations (fieldsList) {
    const translationsData = [];
    let obj = {}
    Object.values(LANGUAGE_TYPES).forEach(lg => {
        obj = {
            lang: lg
        }
        fieldsList.forEach(field => {
            if (field === 'duration_options' || field === 'web_details') {
                obj[field] = []
            } else {
                obj[field] = ''
            }
        })
        translationsData.push(obj);
    });
    return translationsData;
}

export function getTranslationsInitialValues (initialData = {}, fieldsList = []) {
    const translationsData = [];
    Object.values(LANGUAGE_TYPES).forEach(lg => {
        const trObj = {
            lang: lg
        }
        const trInitialData = initialData.find(item => item.lang === lg);
        fieldsList.forEach(field => {
            if (field === 'duration_options') {
                trObj[field] = trInitialData?.[field] || []
            } else if (field === 'web_details') {
                trObj[field] = trInitialData?.[field] || []
            } else if (field === 'price_list_object_name') {
                trObj[field] = trInitialData?.[field] ? transformImage(trInitialData?.[field], trInitialData?.price_list_file) : null
            } else {
                trObj[field] = trInitialData?.[field] || ''
            }
        })
        // console.log(trObj,'field')
        translationsData.push(trObj);
    });
    return translationsData;
}

export function getInputValues ({ name, value, haveChanges }) {
    console.log(name, value,'name, value',haveChanges)
    // console.log( name, value, haveChanges ,' name, value, haveChanges ')
    const { isEditing, fieldsData, errors = {} } = this.state;
    if ((name === 'latitude') && value && (value < -90 || value > 90)) {
        return;
    }
    if ((name === 'longitude') && value && (value < -180 || value > 180)) {
        return;
    }
    if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
        return
    }
    if (name === 'slug') {
        value = value.replace(/\\|\/| /gi, '-');
    }
    if (isEditing && name !== 'repeatPassword' && name !== 'home_text_is_hidden') {
        let mapValue = value
        if (name === 'parent' || name === 'measurementUnit' || name === 'price' || name === 'oldPrice' || name === 'badges'
            || name === 'quantity' || name === 'bonusPoints' || name === 'resourceId') {
            mapValue = value || null;
        }
        haveChanges
            ? this.updatedDataMap.set(name, mapValue)
            : this.updatedDataMap.delete(name);
    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            [name]: value,
        },
        errors: {
            ...errors,
            [name]: false,
        }
    })
}

export function getTranslatableInputValues ({ name, value, haveChanges }) {
    const { fieldsData, languageTab, errors = {} } = this.state;
    const { translations } = fieldsData;
    if (name === 'slug') {
        value = value.replace(/\\|\/| /gi, '-');
    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            translations: translations.map(item => item.lang === languageTab ? {
                ...item,
                [name]: value
            } : item)
        },
        errors: {
            ...errors,
            [name + languageTab]: false,
        }
    })
    //console.log("updatedDataMap", this.updatedDataMap)
}

export function getTranslationData (translations) {
    const TR_data = [];
    Object.keys(translations).forEach(key => {
        const reqTrItem = {};
        let translationIsEmpty = true;
        const trItem = translations[key];
        Object.keys(trItem).forEach(field => {
            if (field === 'price_list_object_name') {
                reqTrItem[field] = trItem[field]?.key;
                translationIsEmpty = false;
            } else {
                reqTrItem[field] = trItem[field];
                translationIsEmpty = false;
            }
        });
        //if translation is empty not add to list
        !translationIsEmpty && TR_data.push(reqTrItem);
    });
    return TR_data;
}


export function disabledStartDate (startValue, endValue) {

    if (!startValue || !endValue) {
        return false;
    }
    return startValue.valueOf() > endValue.valueOf();
}

export function disabledEndDate (endValue, startValue) {
    if (!endValue || !startValue) {
        return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
}


export const arraysEquals = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        return false;
    }
    return arr1.length === arr2.length
        && arr1.every(id => arr2.some(_id => _id === id));
};


export function deleteItem (deletingFunction, itemName, id) {
    swal({
        title: "Զգուշացում!",
        text: `Ցանկանում եք ջնջել ${itemName}?`,
        icon: "warning",
        buttons: ["Ոչ", "Այո"]
    }).then(confirm => {
        if (confirm) {
            deletingFunction(id);
        }
    });
}


export function getPostReqData (fieldsData) {
    const reqData = {};

    Object.keys(fieldsData).forEach(field => {
            const dataItem = fieldsData[field];
            if (field === 'translations') {
                reqData.translations = getTranslationData(dataItem)
            } else if (field === 'image' && dataItem) {
                reqData['image_object_name'] = dataItem?.key || null
            } else if (field === 'logo' && dataItem) {
                reqData['logo_object_name'] = dataItem?.key || null
            } else if (field === 'cover' && dataItem) {
                reqData['cover_object_name'] = dataItem?.key || null
            } else if (field === 'web_cover' && dataItem) {
                reqData['web_cover_object_name'] = dataItem?.key || null
            } else if (field === 'pdf' && dataItem) {
                reqData['pdf_object_name'] = dataItem?.key || null
            } else if (field === 'images') {
                reqData[field] = dataItem.map(item => item.key)
            } else if ((dataItem && dataItem !== '' && dataItem !== 0 && !Array.isArray(dataItem)) || (Array.isArray(dataItem))) {
                reqData[field] = dataItem
            }
        }
    )
    return reqData;
}

export function getPatchReqData (TR_data, whiteFields = null, blackFields = null) {
    const reqData = {};
    this.updatedDataMap.forEach((value, key) => {
        if (this.translationsFields.some(field => key.includes(field))) {
            reqData.translations = TR_data;
        } else if ((!whiteFields && !blackFields)
            || (whiteFields && whiteFields?.some(field => field === key))
            || (blackFields && blackFields?.every(field => field !== key))) {
            reqData[key] = value;
        }
    })
    return reqData
}

