// import packages
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

//Import assets
import "./services.scss"


//Import Components
import { mapStateToProps } from "../../redux/mapStateToProps";
import Spa from "./spa/Spa";
import Fitness from "./fitness/Fitness";
import Cafe from "./cafe/Cafe";
import Pool from "./pool/Pool"
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { Tabs } from "antd";
import { history } from "../../configs/history";;

// Import utils


const Services = () => {
    const { pageName } = useParams()
    useEffect(() => {

        if (!pageName) {
            history.push('/service/fitness')
        }
    }, [pageName])
    let data = [
        {
            id: "1",
            title: 'Ֆիթնես',
            slug: 'fitness',
            page: <Fitness/>,
        },
        {
            id: "2",
            title: 'Սպա',
            slug: 'spa',
            page: <Spa/>,
        },
        {
            id: "3",
            title: 'Սրճարան',
            slug: 'cafe',
            page: <Cafe/>,
        },
        {
            id: "4",
            title: 'Լողավազան',
            slug: 'pool',
            page: <Pool/>,
        }
    ]

    return <PageWrapper pageTitle={"Ծառայություններ"}>
        <section className={'services'}>
            <div className={`aside-menu-list`}>
                <Tabs
                    type="card"
                    activeKey={pageName || data[0]?.slug}
                    items={data.map(item => {
                        return {
                            label: <Link to={`/service/${item?.slug}`} activeClassName='active'>
                                <span>{item?.title}</span>
                            </Link>,
                            key: item?.slug,
                            children: item?.page,
                        };
                    })}
                />
            </div>
        </section>
    </PageWrapper>
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
