// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import components
import { NestableItem } from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { deleteItem } from "../../utils/helperFunctions";
import {
    GetEvents,
    DeleteEventById,
} from "../../redux/actions";
import { defaultEmptyLogo, defaultEmptyView, defaultUserLogo } from "../../assets/images";
import InfiniteScroll from "react-infinite-scroller";
import { GET_LIMITS } from "../../constants/constsTypes";
import ItemsList from "../../components/ItemsList";
import moment from "moment";


class Events extends Component {
    constructor (props) {
        super(props);
        this.deleteItem = deleteItem.bind(this, props.DeleteEventById, 'Իրադարձությունը');
        this.onPositionChange = this.onPositionChange.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount () {
        this.props.GetEvents({
            offset: 0,
            limit: GET_LIMITS,
        });
    }

    loadMoreItems () {
        const { hasMore, eventList } = this.props.eventData;
        hasMore && this.props.GetEvents({
            reset: false,
            offset: eventList.length,
            limit: GET_LIMITS,
        });
    }

    async onPositionChange (items, item) {
        const movedSliderId = item.id;
        const { eventList } = this.props;
        const positionedItems = items.map((item, index) => {
            return { id: item.id, position: index }
        });
        if (~eventList.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            this.props.GetEvents();
        }
    }

    render () {
        let { eventData, requestLoading } = this.props;

        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Ամսաթիվ - 1", "Ամսաթիվ - 2"],
            // largeItems: [1],
            status: true,
            actions: ["details", "edit", 'delete'],
        };
        let itemListInfo = eventData?.eventList?.map(item => {
            const { id, title, image_url, is_hidden,event_day_dates } = item;
            return {
                id: id,
                values: [
                    {
                        title: <img src={image_url ? image_url : defaultEmptyLogo}
                                    alt="event"/>
                    },
                    title || " - ",
                    event_day_dates?.[0] ? moment(event_day_dates?.[0]).format("DD.MM.YY / HH:mm"): " - ",
                    event_day_dates?.[1] ? moment(event_day_dates?.[1]).format("DD.MM.YY / HH:mm"): " - ",
                ],
                status: !is_hidden,
                deleteCb: () => this.deleteItem(item.id)
            };
        });
        return <PageWrapper pageTitle={'Իրադարձություն'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/event/add"}/>
            <InfiniteScroll
                hasMore={eventData?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'groupWorkoutList'}
                useWindow={true}
                initialLoad={false}>
                {!!eventData?.eventList?.length ?
                    <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                               itemListInfo={itemListInfo}
                               loading={requestLoading}
                               editLink={"/event/edit"}
                               detailsLink={"event/details"}/>
                    : <div className={'empty-view'}>
                        <img src={defaultEmptyView}/>
                    </div>}
            </InfiniteScroll>
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetEvents,
    DeleteEventById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
