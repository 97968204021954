// Import packages
import React from "react";
import { Route, Switch, Router, Redirect } from "react-router-dom";

// Import utils
import { history } from "../configs/history";
import { ProtectedRoute } from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import NotFound from '../containers/NotFound';
import Contact from '../containers/Contact';
import Rule from '../containers/Rule';

import Moderators from '../containers/moderators/Moderators';
import AddEditModerator from "../containers/moderators/AddEditModerator";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Slider from "../containers/slider/Slider";
import AddEditSlider from "../containers/slider/AddEditSlider";

import Category from "../containers/category/Category";
import AddEditCategory from "../containers/category/AddEditCategory";

import Event from "../containers/events/Event";
import EventDetails from "../containers/events/EventDetails";
import AddEditEvent from "../containers/events/AddEditEvent"


import Notifications from "../containers/notification/Notifications";
import Article from "../containers/article/Article";
import AddEditArticle from "../containers/article/AddEditArticle";
import Package from "../containers/package/Package";
import AddEditPackage from "../containers/package/AddEditPackage";
import Trainer from "../containers/trainer/Trainer";
import AddEditTrainer from "../containers/trainer/AddEditTrainer";
import Services from "../containers/services/Services";
import ServiceRequests from "../containers/service-request/ServiceRequest";
import ServiceRequestDetails from "../containers/service-request/ServiceRequestDetails";
import AddEditFitness from "../containers/services/fitness/AddEditFitness";

import AddEditSpaService from "../containers/services/spa/AddEditSpaService";
import AddEditSpaWorker from "../containers/services/spa/AddEditSpaWorker";

import User from "../containers/user/User";
import UserDetails from "../containers/user/UserDetails";
import WorkoutSection from "../containers/workoutSection/WorkoutSection";
import AddEditWorkoutSections from "../containers/workoutSection/AddEditWorkoutSection";
import GroupWorkout from "../containers/groupWorkout/GroupWorkout";
import WorkoutTimeLine from "../containers/groupWorkout/WorkoutTimeline";
import AddEditGroupWorkout from "../containers/groupWorkout/AddEditGroupWorkout";
import Devices from "../containers/device/Device";
import Invitations from "../containers/invitations/Invitations";

import StaticTexts from "../containers/staticTexts/StaticTexts";

import FAQ from "../containers/faq/FAQ";
import AddEditFAQ from "../containers/faq/AddEditFAQ";

import Resource from "../containers/Resource";
import About from "../containers/about/About";
import AboutBlockAddEdit from "../containers/about/AboutBlockAddEdit";

import Activity from "../containers/activity-notification/Activity";


export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/categories" component={Category}/>
                    <ProtectedRoute exact path="/categories/add" component={AddEditCategory}/>
                    <ProtectedRoute exact path="/categories/edit/:id" component={AddEditCategory}/>

                    <ProtectedRoute exact path="/event" component={Event}/>
                    <ProtectedRoute exact path="/event/details/:id" component={EventDetails}/>
                    <ProtectedRoute exact path="/event/add" component={AddEditEvent}/>
                    <ProtectedRoute exact path="/event/edit/:id" component={AddEditEvent}/>

                    <ProtectedRoute exact path="/article" component={Article}/>
                    <ProtectedRoute exact path="/article/add" component={AddEditArticle}/>
                    <ProtectedRoute exact path="/article/edit/:id" component={AddEditArticle}/>

                    <ProtectedRoute exact path="/trainer" component={Trainer}/>
                    <ProtectedRoute exact path="/trainer/add" component={AddEditTrainer}/>
                    <ProtectedRoute exact path="/trainer/edit/:id" component={AddEditTrainer}/>

                    <ProtectedRoute exact path="/workoutSection" component={WorkoutSection}/>
                    <ProtectedRoute exact path="/workoutSection/add" component={AddEditWorkoutSections}/>
                    <ProtectedRoute exact path="/workoutSection/edit/:id" component={AddEditWorkoutSections}/>

                    <ProtectedRoute exact path="/groupWorkout" component={GroupWorkout}/>
                    <ProtectedRoute exact path="/groupWorkout/timeline/:id" component={WorkoutTimeLine}/>
                    <ProtectedRoute exact path="/groupWorkout/add" component={AddEditGroupWorkout}/>
                    <ProtectedRoute exact path="/groupWorkout/edit/:id" component={AddEditGroupWorkout}/>

                    <ProtectedRoute exact path="/package" component={Package}/>
                    <ProtectedRoute exact path="/package-request" component={ServiceRequests}/>
                    <ProtectedRoute exact path="/package-request/:id" component={ServiceRequestDetails}/>

                    <ProtectedRoute exact path="/package/add" component={AddEditPackage}/>
                    <ProtectedRoute exact path="/package/edit/:id" component={AddEditPackage}/>

                    <ProtectedRoute exact path="/slider" component={Slider}/>
                    <ProtectedRoute exact path="/slider/add" component={AddEditSlider}/>
                    <ProtectedRoute exact path="/slider/edit/:id" component={AddEditSlider}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/rule" component={Rule}/>

                    <ProtectedRoute exact path="/user" component={User}/>
                    <ProtectedRoute exact path="/user/details/:id" component={UserDetails}/>
                    <ProtectedRoute exact path="/devices" component={Devices}/>
                    <ProtectedRoute exact path="/invitations" component={Invitations}/>
                    <ProtectedRoute exact path="/notifications" component={Notifications}/>

                    <ProtectedRoute exact path="/service" component={Services}/>
                    <ProtectedRoute exact path="/service/:pageName" component={Services}/>

                    <ProtectedRoute exact path="/service/fitness/edit/:id" component={AddEditFitness}/>
                    <ProtectedRoute exact path="/fitness/edit/:id" component={AddEditFitness}/>
                    <ProtectedRoute exact path="/service/fitness/add" component={AddEditFitness}/>
                    <ProtectedRoute exact path="/fitness/add" component={AddEditFitness}/>

                    <ProtectedRoute exact path="/service/spa/service/add" component={AddEditSpaService}/>
                    <ProtectedRoute exact path="/service/spa/service/edit/:id" component={AddEditSpaService}/>

                    <ProtectedRoute exact path="/service/spa/worker/add" component={AddEditSpaWorker}/>
                    <ProtectedRoute exact path="/service/spa/worker/edit/:id" component={AddEditSpaWorker}/>

                    <ProtectedRoute exact path="/faq" component={FAQ}/>
                    <ProtectedRoute exact path="/faq/add" component={AddEditFAQ}/>
                    <ProtectedRoute exact path="/faq/edit/:id" component={AddEditFAQ}/>

                    <ProtectedRoute exact path="/resource" component={Resource}/>

                    <ProtectedRoute exact path="/activity_notification" component={Activity}/>

                    <ProtectedRoute exact path="/about" component={About}/>
                    <ProtectedRoute exact path="/about/add" component={AboutBlockAddEdit}/>
                    <ProtectedRoute exact path="/about/edit/:id" component={AboutBlockAddEdit}/>

                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
