import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import Reducer from './reducers';
// import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
    key: 'root',
    storage: storage,
};

let store;

const persistedReducer = persistReducer(persistConfig, Reducer);

store = createStore(persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

let persistor = persistStore(store);

export {store, persistor};
