import { request, _urlAbout, _urlAboutBlock } from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import { ABOUT_CONSTS } from "../constants";

export const GetAbout = () => {
    const requestData = {
        url: _urlAbout,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                console.log(data);
                dispatch({
                    type: ABOUT_CONSTS.GET_ABOUT,
                    payload: data
                });
            })
    }
};

export const UpdateAbout = ( id, data) => {
    const requestData = {
        url: `${_urlAbout}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:ABOUT_CONSTS.UPDATE_ABOUT,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/about')
            })
            .catch(() => {
            })
    }
};

export const GetAboutBlockById = id => {
    const requestData = {
        url: `${_urlAboutBlock}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: ABOUT_CONSTS.GET_ABOUT_BLOCK_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const CreateAboutBlock = data => {
    const requestData = {
        url:_urlAboutBlock,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ABOUT_CONSTS.CREATE_ABOUT_BLOCK,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ ստեղծվեց!"
                })
                history.push('/about')
            })
            .catch(() => {
            })
    }
};

export const UpdateAboutBlockById = (id, data) => {
    const requestData = {
        url: `${_urlAboutBlock}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:ABOUT_CONSTS.UPDATE_ABOUT_BLOCK_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/about')
            })
            .catch(() => {
            })
    }
};

export const DeleteAboutBlockById = id => {
    const requestData = {
        url: `${_urlAboutBlock}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ABOUT_CONSTS.DELETE_ABOUT_BLOCK_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};
