// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import styles
import "../../assets/styles/media/media-selector-modal.scss";
import { iconList, iconGrid } from "../../assets/images";

// Import Components
import { Modal } from "antd";
import { MediaFileCard } from "./MediaFileCard";
import { MediaFolderCard } from "./MediaFolderCard";
import { LinkButton } from "../buttons/buttons";
import AddMediaFolder from "./AddFolder";
import MediaUploader from "./AddMedia/MediaUploader";

// Import utils
import { GetMedia, setFolderList, toggleMediaView, UploadFile } from "../../redux/actions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    closeAddMedia,
    getMedias,
    onDropFile,
    onUploadClick,
    onUploadProgress,
    triggerFileUpload,
    uploadMedias,
} from "./AddMedia/functions/action-functions";
import { MEDIA_MODAL_TYPES, } from "../../constants/constsTypes";
import { iconCloseDark, iconNewFile } from "../../assets/images";


class MediaSelectorModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            selectedImages: [],
            fetching: false,
            uploading: false,
            currentUploadingId: null,
            addingFiles: [],
            filesIsChoose: false,
            //new
            errors: {},
        };
        this.selectImage = this.selectImage.bind(this);
        this.insertWithDoubleClick = this.insertWithDoubleClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.insertMedia = this.insertMedia.bind(this);
        this.openFolder = this.openFolder.bind(this);
        this.getFiles = this.getFiles.bind(this,);

        this.inputElement = React.createRef();
        this.onUploadClick = onUploadClick.bind(this);
        this.triggerFileUpload = triggerFileUpload.bind(this);
        this.getMedias = getMedias.bind(this);
        this.onDropFile = onDropFile.bind(this);
        this.uploadMedias = uploadMedias.bind(this);
        this.closeAddMedia = closeAddMedia.bind(this);
        this.onUploadProgress = onUploadProgress.bind(this);
    }

    componentDidMount () {
        const { mediaItems } = this.props;
        // console.log('folderList',folderList)
        // console.log('mediaItems',mediaItems)
        if (!mediaItems?.itemsList) {
            this.getFiles();
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const { isOpen, appendMedias } = this.props;
        if (!prevProps.isOpen && isOpen && Array.isArray(appendMedias)) {
            this.setState({
                selectedImages: appendMedias
            })
        }
    }

    async getFiles (parent = null) {
        await this.setState({ fetching: true })
        await this.props.GetMedia({ parent });
        this.setState({ fetching: false })
    }

    selectImage (item, isSelected) {
        const { multiSelect } = this.props;
        const { selectedImages } = this.state;
        if (multiSelect) {
            this.setState({
                selectedImages: isSelected ? selectedImages.filter(m => m.key !== item.key) : [item, ...selectedImages]
            })
        } else {
            this.setState({
                selectedImages: isSelected ? [] : [item]
            })
        }
    }

    insertMedia () {
        const { selectedImages } = this.state;
        this.props.getMedia(selectedImages, this.props?.multiSelect);
        this.closeModal();
    }

    insertWithDoubleClick (item) {
        this.props.getMedia([item], this.props?.multiSelect);
        this.closeModal();
    }

    closeModal () {
        this.setState({
            selectedImages: []
        });
        this.props.closeModal();
    }

    openFolder (folderData) {
        const currentFolderList = this.props.folderList || [];
        setFolderList([...currentFolderList, folderData])
        this.getFiles(folderData.key)
    }

    changeFolder = (key) => {
        const { folderList } = this.props;
        if (key === null) {
            this.getFiles()
            setFolderList([])
        } else {
            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.key === key
            });
            const currentFolder = newList?.[newList.length - 1]
            setFolderList(newList)
            this.getFiles(currentFolder?.key)
        }
    };

    render () {
        const {
            isOpen, mediaItems, acceptTypes, folderList, mediaView,
            uploadProgresses, multiSelect
        } = this.props;
        const {
            selectedImages, filesIsChoose, addingFiles, uploading, currentUploadingId, errors
        } = this.state;
        let itemsList = mediaItems ? mediaItems.itemsList : [];
        const { progress = 0, status = null } = uploadProgresses[currentUploadingId] || {}
        return <Modal
            visible={isOpen}
            onCancel={this.closeModal}
            wrapClassName={"custom-modal"}>

            <div className="media-selector-modal">
                {
                    filesIsChoose ?
                        <MediaUploader addingFiles={addingFiles}
                                       uploading={uploading}
                                       status={status}
                                       progress={progress}
                                       errors={errors}
                                       getMedias={this.getMedias}
                                       uploadMedias={this.uploadMedias}
                                       cacnel={this.closeAddMedia}/> :
                        <div className="media-selector-modal-inner">
                            <div className="selector-modal-header">
                                <div className="selector-modal-title">
                                    <h3>
                                            <span className={'path-item'} onClick={() => this.changeFolder(null)}>
                                                Ֆայլեր
                                            </span>
                                        {
                                            folderList && !!folderList.length && folderList.map((folder) => {
                                                return <>
                                                    <span> / </span>
                                                    <span className={'path-item'}
                                                          onClick={() => this.changeFolder(folder.key)}>
                                                            {folder.name}
                                                        </span>
                                                </>
                                            })
                                        }
                                    </h3>
                                </div>
                                <button className="selector-modal-closing-btn"
                                        onClick={this.closeModal}>
                                    <img src={iconCloseDark}
                                         className="close_icon" alt=""/>
                                </button>
                            </div>
                            <div className={'action-btns'}>
                                <div className="right-wrapper">
                                    <AddMediaFolder folderList={folderList}
                                                    folderDropDownClassName={'in-selector-modal'}/>
                                    <div className={'media-upload-in-selector-modal'}>
                                        <div className={'add-file-btn'} onClick={this.triggerFileUpload}>
                                            <img src={iconNewFile} alt=""/>
                                            Ավելացնել Ֆայլ
                                        </div>
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            ref={this.inputElement}
                                            multiple={true}
                                            onChange={this.onDropFile}
                                            onClick={this.onUploadClick}
                                        />
                                    </div>
                                </div>
                                <div className={"toggle-track-view"}>
                                    <LinkButton
                                        className={`list-view-button ${mediaView ? 'selected' : ''}`}
                                        title={<img src={iconList} alt=""/>}
                                        cb={() => toggleMediaView(true)}/>
                                    <LinkButton
                                        className={`list-view-button ${!mediaView ? 'selected' : ''}`}
                                        title={<img src={iconGrid} alt=""/>}
                                        cb={() => toggleMediaView(false)}/>
                                </div>
                            </div>
                            <div className={'medias'}>
                                <div className={'media-items-wrapper'}>
                                    {itemsList && !!itemsList.length && itemsList.map((item, index) => {
                                        const isSelected = selectedImages.find(v => v.key === item.key);
                                        const disabled = acceptTypes.every(type => type !== item.extension)
                                        if (item.type === "FOLDER") {
                                            return <MediaFolderCard key={item.key}
                                                                    openFolder={this.openFolder}
                                                                    item={item}
                                                                    listView={mediaView}/>
                                        }
                                        return <MediaFileCard key={item.key}
                                                              selectable={true}
                                                              isSelected={isSelected}
                                                              selectImage={this.selectImage}
                                                              withCopy={true}
                                                              disabled={disabled}
                                                              onDoubleClick={this.insertWithDoubleClick}
                                                              item={item}
                                                              listView={mediaView}/>
                                    })}
                                </div>
                            </div>
                            {!!selectedImages?.length && <div className={'upload-modal-choose-info'}>

                                {
                                    multiSelect ? <>
                                            Ընտրված է՝ {selectedImages?.length} ֆայլ
                                        </>
                                        : <>
                                        Ֆայլ։ {selectedImages[0].name}
                                        </>
                                }
                            </div>}
                            <div className={'choose-button'}>
                                <LinkButton title={'Ընտրել'}
                                            disabled={!selectedImages.length}
                                            cb={this.insertMedia}/>
                            </div>
                        </div>}
            </div>
        </Modal>
    }
}

MediaSelectorModal.defaultProps = {
    multiSelect: false,
}
const mapDispatchToProps = {
    GetMedia,
    UploadFile
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSelectorModal);
