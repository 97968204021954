
export function  validateData(errors, validationData) {
    this.errorsSet.clear();

    errors.requiredFields && errors.requiredFields.forEach(key => {
        if (!validationData[key] || validationData[key] === "") {
            this.errorsSet.add(key);
        }
        if ( key === 'password' && validationData?.[key]?.length < 8) {
            this.errorsSet.add(key);
        }
        if (key === 'phone'  && validationData?.[key]?.length < 8) {
            this.errorsSet.add(key);
        }
    });

    return !this.errorsSet.size;
}
