// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, DatePicker } from "antd";

//import styles
import '../../assets/styles/containerStyles/appended-product-section.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField, disabledEndDate,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationData, getTranslationsInitialValues,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    CreateEvent,
    GetEventById,
    UpdateEventById,
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { history } from "../../configs/history";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import moment from "moment";
import { MAIN_LANGUAGE_TYPES } from "../../constants/constsTypes";
import { transformImage } from "../../utils/transformImage";
import { Editor } from "../../components/uiElements/Editor";

class AddEditEvent extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                start_date: null,
                start_date2: null,
                image: null,
                cover: null,
                web_cover: null,
                is_for_club_members: false,
                is_hidden: false,
                join_url: '',
                members_limit: '',
            },
            errors: {},
            today: new Date(),
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
            mediaModalType: '',
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.addEditEvent = this.addEditEvent.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;


        if (isEditing) {
            await this.props.GetEventById(this.props.id);
            const editingData = this.props.eventById
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations, ['title', 'description']),
                    image: editingData?.image_object_name ?
                        transformImage(editingData.image_object_name, editingData.image_url)
                        : null,
                    cover: editingData?.cover_object_name ?
                        transformImage(editingData.cover_object_name, editingData.cover_url)
                        : null,
                    web_cover: editingData?.web_cover_object_name ?
                        transformImage(editingData.web_cover_object_name, editingData.web_cover_url)
                        : null,
                    start_date: editingData?.event_day_dates?.[0] ? moment(editingData?.event_day_dates?.[0]) : moment(),
                    start_date2: editingData?.event_day_dates?.[1] && moment(editingData?.event_day_dates?.[1]),
                    is_hidden: editingData?.is_hidden,
                    is_for_club_members: editingData?.is_for_club_members,
                    join_url: editingData?.join_url || "",
                    members_limit: editingData?.members_limit || "",
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }

    getMedia (type, mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia (type) {
        const { fieldsData, } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: null,
            }
        })
    }

    toggleMediaModalType (type = '') {
        this.setState({
            mediaModalType: type
        })
    }

    async addEditEvent () {
        await this.setState({
            requestLoading: true
        });
        const {  start_date, start_date2, ...info } = this.state.fieldsData;
        const { translations, image, } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!image) {
            errors.image = true;
            result = false;
        }

        if (!start_date) {
            errors.start_date = true;
            result = false;
        }

        if (result) {
            if (!this.state.isEditing) {
                let reqData = this.getPostReqData(info);
                reqData.event_day_dates = [
                    start_date,
                ]
                if(start_date2){
                    reqData.event_day_dates.push(start_date2)
                }
                this.props.CreateEvent(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                // const trData = getTranslationData(translations);
                // const reqData = this.getPatchReqData(trData);
                let reqData = this.getPostReqData(info);

                reqData.join_url = this.state.fieldsData.join_url || null
                reqData.members_limit = this.state.fieldsData.members_limit || null
                reqData.event_day_dates = [
                    start_date,
                ]
                if(start_date2){
                    reqData.event_day_dates.push(start_date2)
                }
                if (Object.keys(reqData).length) {
                    this.props.UpdateEventById(this.props.id, reqData)
                        .finally(() => {
                            this.setState({ requestLoading: false });
                            history.push('/event')
                        });
                }

            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }


    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            isEditing,
            errorsTabs,
            requestLoading,
            today,
            mediaModalType
        } = this.state;
        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Իրադարձություն' : 'Փոփոխել Իրադարձություն'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.is_hidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.is_hidden}
                            onChange={(checked) => this.getInputValues({
                                name: "is_hidden",
                                value: !checked,
                                haveChanges: this.currentData?.is_hidden !== !checked
                            })}/>
                </div>
                <div className="fixed-top-right-member">
                    <label>Ակումբի անդամ</label>
                    <Switch checked={fieldsData.is_for_club_members}
                            onChange={(checked) => this.getInputValues({
                                name: "is_for_club_members",
                                value: checked,
                                haveChanges: this.currentData?.is_for_club_members !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Cover image <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'image')}
                    />
                </div>
                {fieldsData?.image && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.image}
                    />
                </div>}
                <div className="top-side">
                    <label>Mobile image</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn`}
                                cb={this.toggleMediaModalType.bind(this, 'cover')}
                    />
                </div>
                {fieldsData?.cover && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.cover}
                                   customDelete={this.deleteMedia.bind(this, 'cover')}
                    />
                </div>}
                <div className="top-side">
                    <label>Web image</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn`}
                                cb={this.toggleMediaModalType.bind(this, 'web_cover')}
                    />
                </div>
                {fieldsData?.web_cover && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.web_cover}
                                   customDelete={this.deleteMedia.bind(this, 'web_cover')}
                    />
                </div>}
                <div className={'join-block'}>
                    <InputGroup inputType="input"
                                type="text"
                                label="URL"
                                placeholder="url"
                                name="join_url"
                                required={false}
                                value={fieldsData.join_url}
                                initValue={fieldsData.join_url}
                                maxLength={100}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                integerNumber={true}
                                minValue={1}
                                maxValue={100}
                                label="Members limit"
                                placeholder="Members limit"
                                name="members_limit"
                                required={false}
                                value={fieldsData.members_limit}
                                initValue={fieldsData.members_limit}
                                onChange={this.getInputValues}/>
                </div>
                <div className={'flex-row'}>
                    <InputGroup inputType={'wrapper'} label={'Միջոցառման մեկնարկ'}
                                error={errors.start_date}
                                required={true}
                                value={fieldsData.start_date}>
                        <DatePicker value={fieldsData.start_date}
                                    showTime={{ format: 'HH:mm' }}
                                    format="DD/MM/YY HH:mm"
                                    placeholder={'DD/MM/YYYY'}
                                    status={errors.start_date ? 'error' : ''}
                                    showToday={false}
                                    allowClear={true}
                                    disabledDate={(date) => disabledEndDate(date, today)}
                                    className={`input-time ${errors.start_date ? 'error' : ""}`}
                                    onChange={(value) => this.getInputValues({
                                        name: "start_date",
                                        value: value,
                                    })}/>
                    </InputGroup>
                    {fieldsData.start_date && <InputGroup inputType={'wrapper'} label={'Միջոցառման մեկնարկ 2'}
                                                          error={errors.start_date2}
                                                          value={fieldsData.start_date2}
                                                          style={{ marginLeft: "30px" }}>
                        <DatePicker value={fieldsData.start_date2}
                                    showTime={{ format: 'HH:mm' }}
                                    format="DD/MM/YY HH:mm"
                                    placeholder={'DD/MM/YYYY'}
                                    showToday={false}
                                    allowClear={true}
                                    disabledDate={(date) => disabledEndDate(date, today)}
                                    className={`input-time ${errors.start_date2 ? 'error' : ""}`}
                                    onChange={(value) => this.getInputValues({
                                        name: "start_date2",
                                        value: value,
                                    })}/>
                    </InputGroup>}
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslatableInputValues}/>

                <div className={'editors'}>
                    <div className={'editor-wrapper'}>
                        <label>Նկարագրություն <span className={'required-badge'}>*</span></label>
                        <Editor
                            value={currentData.description || ""}
                            name={'description'}
                            initValue={fieldsData?.description}
                            error={errors['description' + languageTab]}
                            disableToolbar={true}
                            onChange={this.getTranslatableInputValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditEvent}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, mediaModalType)}
                closeModal={this.toggleMediaModalType.bind(this)}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateEvent,
    GetEventById,
    UpdateEventById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEvent)
