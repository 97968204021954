import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as media} from "../reducers/media";
import {initialState as composite} from "../reducers/composite";
import {initialState as filter} from "../reducers/filter";
import {initialState as category} from "../reducers/category";
import {initialState as slider} from "../reducers/slider";
import {initialState as general} from "../reducers/general";
import {initialState as notifications} from "../reducers/notifications";
import {initialState as event} from "../reducers/event";
import {initialState as article} from "../reducers/article";
import {initialState as service} from "../reducers/service";
import {initialState as trainer} from "../reducers/trainer";
import {initialState as spa} from "../reducers/spa";
import {initialState as fitness} from "../reducers/fitness";
import {initialState as cafe} from "../reducers/cafe";
import {initialState as workoutSection} from "../reducers/workoutSection";
import {initialState as groupWorkout} from "../reducers/groupWorkout";
import {initialState as staticTexts} from "../reducers/staticTexts";
import {initialState as faq} from "../reducers/faq";
import {initialState as about} from "../reducers/about";
import {initialState as pool} from "../reducers/pool";
import {initialState as activity} from "../reducers/activity";


const reducers = {
    auth,
    utils,
    moderators,
    media,
    composite,
    filter,
    category,
    slider,
    general,
    notifications,
    event,
    article,
    service,
    trainer,
    spa,
    fitness,
    cafe,
    pool,
    workoutSection,
    groupWorkout,
    staticTexts,
    faq,
    about,
    activity,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};

