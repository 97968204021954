import { AUTH_CONSTS, SERVICES_CONSTS } from "../constants";

export const initialState = {
    serviceList: [],
    serviceRequests:{
        list: [],
        hasMore:false,
    },
    serviceById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case SERVICES_CONSTS.GET_SERVICES:
            return {
                ...state,
                serviceList: action.payload || [],
            };
        case SERVICES_CONSTS.GET_SERVICE_REQUESTS:
            return {
                ...state,
                serviceRequests: {
                    list:action.payload.reset ? action.payload.data : [...state.serviceRequests.list, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };
        case SERVICES_CONSTS.DELETE_SERVICE_REQUEST_BY_ID:
            return {
                ...state,
                serviceRequests:  {
                    list: state.serviceRequests.list.filter(item => item.id !== action.payload),
                }

            };
        case SERVICES_CONSTS.CREATE_SERVICE:
            return {
                ...state,
                serviceList: [action.payload].concat(state.serviceList)
            };
        case SERVICES_CONSTS.GET_SERVICE_BY_ID:
            return {
                ...state,
                serviceById: action.payload
            };
        case SERVICES_CONSTS.UPDATE_SERVICE_BY_ID:
            return {
                ...state,
                serviceList: state.serviceList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case SERVICES_CONSTS.DELETE_SERVICE_BY_ID:
            return {
                ...state,
                serviceList: state.serviceList.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
