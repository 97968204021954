//--------------------------------------- Official-server --------------------------------------------
const OFFICIAL_URLS = {
    AUTH_URL: "https://app.orangefit.am/api/v1/auth",
    ADMIN_URL: "https://app.orangefit.am/api/v1/admin",
    S3_STORAGE_PREFIX: 'https://app.orangefitness.am/files'
}
//--------------------------------------- 4Steps-server --------------------------------------------

const TESTING_URLS = {
    AUTH_URL: "http://173.212.233.220:7000/api/v1/auth",
    ADMIN_URL: "http://173.212.233.220:7100/api/v1/admin",
    S3_STORAGE_PREFIX: 'https://eu2.contabostorage.com/43872eb706df4c1589134289f7606038:orange-distribution'
}
const { AUTH_URL, USER_URL, ADMIN_URL, SOCKET_URL, HOST_MEDIA_URL, S3_STORAGE_PREFIX } =
     OFFICIAL_URLS;
     // TESTING_URLS;
export const s3StoragePrefix = S3_STORAGE_PREFIX


export const _urlOauth = AUTH_URL ;
export const _urlMedia = ADMIN_URL + "/file";
export const _urlRegion = USER_URL + "/regions";
export const _urlOthers = ADMIN_URL + "/others";
export const _urlContact = ADMIN_URL + "/contact";
export const _urlRule = ADMIN_URL + "/rule";
export const _urlNotif = ADMIN_URL + "/notification";
export const _urlModerator = ADMIN_URL + "/moderator";
export const _urlFilters = ADMIN_URL + "/filters";
export const _urlCategories = ADMIN_URL + "/category";
export const _urlSlider = ADMIN_URL + "/slider";
export const _urlEvent = ADMIN_URL + "/event";
export const _urlEventJoin = ADMIN_URL + "/event-join";
export const _urlArticle = ADMIN_URL + "/article";
export const _urlService = ADMIN_URL + "/service";
export const _urlServiceRequest = ADMIN_URL + "/service-request";
export const _urlTeam = ADMIN_URL + "/team";
export const _urlTrainer = ADMIN_URL + "/trainer";
export const _urlFitness = ADMIN_URL + "/fitness";
export const _urlFitnessService = ADMIN_URL + "/fitness-service";
export const _urlSpa = ADMIN_URL + "/spa";
export const _urlSpaService = ADMIN_URL + "/spa-service";
export const _urlSpaWorker = ADMIN_URL + "/spa-worker";
export const _urlCafe = ADMIN_URL + "/cafe";
export const _urlPool = ADMIN_URL + "/pool";
export const _urlUser = ADMIN_URL + "/user";
export const _urlDevice = ADMIN_URL + "/device";
export const _urlWorkoutSection = ADMIN_URL + "/workout-section";
export const _urlGroupWorkout = ADMIN_URL + "/group-workout";
export const _urlWorkoutTimeline = ADMIN_URL + "/workout-timeline";
export const _urlWorkoutTimelineCancellation = ADMIN_URL + "/workout-cancellation";
export const _urlInvitations = ADMIN_URL + "/voucher-code";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlFAQ = ADMIN_URL + "/faq";
export const _urlResource = ADMIN_URL + "/resource";
export const _urlAppVersion = ADMIN_URL + "/app-version";
export const _urlAbout = ADMIN_URL + "/about";
export const _urlAboutBlock = ADMIN_URL + "/about-block";
export const _urlActivityUser = ADMIN_URL + "/user-action-log";
export const _urlActivityNotification = ADMIN_URL + "/fcm-notification-log";

export const _urlSocket = SOCKET_URL;
export const _hostMedia = HOST_MEDIA_URL;

