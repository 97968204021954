// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/user-details.scss';
import {defaultEmptyLogo, defaultUserLogo} from "../../assets/images";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import moment from "moment";
import {SkeletonSmallItem} from "../../components/uiElements/SkelatonISmalltem";
import {GetUserActivities, GetUserById, RemoveUserActivities} from "../../redux/actions";
import {Link} from "react-router-dom";
import {history} from "../../configs/history";
import ItemsList from "../../components/ItemsList";
import {LOG_ACTION_TYPES_CONST} from "../../constants/constsTypes";
import dayjs from "dayjs";

class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            requestLoading: false,
            requestActivityLoading: true,
        }
        this.getUser = this.getUser.bind(this)
        this.getActivity = this.getActivity.bind(this)
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    async componentDidMount() {
        this.props.RemoveUserActivities()
        this.getUser()
        this.getActivity()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {id} = this.props;
        if (id !== prevProps?.id) {
            this.getUser()
            this.getActivity()
        }
    }

    async getUser() {
        const {id} = this.props;
        const user = await this.props.GetUserById(id);
        if (!user) {
            history?.push('/user')
        }
        user && this.setState({
            user
        })
    }

    async getActivity() {
        const {id} = this.props;
        this.setState({
            requestActivityLoading: true
        })
        this.props.GetUserActivities({
            userId: id
        }).then(() => {
            this.setState({
                requestActivityLoading: false
            })
        })
    }

    async loadMoreItems() {
        if (!this.fetching) {
            this.fetching = true;
            const {activityUserList} = this.props;
            activityUserList?.hasMore && await this.props.GetUserActivities({
                reset: false,
                offset: activityUserList?.itemsList?.length,
            });
            this.fetching = false
        }
    };

    render() {
        const {user, requestLoading, requestActivityLoading} = this.state;
        const {activityUserList} = this.props;
        let fullName = `${user?.first_name || ""} ${user?.last_name || ""}`
        const deviceListInfo = [];
        user?.devices?.forEach(device => {
            const {device_id, app_version, os_version, model, created_at, updated_at,platform,settings} = device;
            deviceListInfo.push( {
                device_id,
                values: [
                    platform,
                    app_version || "-",
                    os_version || "-",
                    model || "-",
                    settings?.is_notification_enabled ? "Միացված է" : "Անջատված է",
                    settings?.is_workout_notification_enabled ? "Միացված է" : "Անջատված է",
                    settings?.lang,
                    moment(created_at).format('DD/MM/YYYY HH:mm'),
                    moment(updated_at).format('DD/MM/YYYY HH:mm')
                ],
            })
        });
        const deviceListHeaderInfo = {
            generalInfo: ["Հարթակ", "Հավել. Տարբերակ", "Օհ Տարբերակ", "Մոդել", "Ծանուցում` Հավելված","Ծանուցում` Մարզում","Լեզու", "Ստեղծվել է", "Թարմացվել է"],
        };




        const activityUserListInfo = [];
        activityUserList?.itemsList?.forEach(item => {
            activityUserListInfo.push({
                id: item.id,
                nestedItemId: item?.req_data?.event_id,
                values: [
                    dayjs(item.created_at).format('DD.MM.YYYY HH:mm'),
                    `${item?.user?.first_name || ""} ${item?.user?.last_name || ""}`,
                    LOG_ACTION_TYPES_CONST[item?.action_type]?.title || " - ",
                    item?.ip_address || " - ",
                    item?.device?.platform || " - ",
                    item?.res_data?.is_error ? 'Failed' : "Success",
                    item?.res_data?.message || " - ",
                    {
                        twoRow: true,
                        title: item?.details?.eventName || " - ",
                        subTitle: `${item?.details?.dates?.[0] ? dayjs(item?.details?.dates?.[0]).format('DD.MM.YYYY HH:mm') : ""}` || " - ",
                    },
                    // `${item?.details?.eventName} - ${item?.details?.dates?.[0] ? dayjs(item?.details?.dates?.[0]).format('DD.MM.YYYY HH:mm') : ""}` || " - ",
                    item?.details?.is_registration_closed ? "Closed" : "Open",
                ],
            });
        });
        const activityUserListHeaderInfo = {
            generalInfo: ["Date", "User", "Action", "IP", "Platform", "Status", "Message", "Event", "Registration State"],
            largeItems:[6],
            // actions: ["details"]
        };

        let voucherFullName = `${user?.used_voucher_codes[0]?.user?.first_name || ""} ${user?.used_voucher_codes[0]?.user?.last_name || ""}`
        return <PageWrapper pageTitle={`Օգտատեր`}>
            <section className="user-details">
                <div className="top-part">
                    <div className="user-image">
                        <img src={user?.image_url ? user.image_url : defaultUserLogo}
                             alt="user"/>
                    </div>

                    <span className="username">{
                        requestLoading ?
                            <SkeletonSmallItem/> : fullName
                    }
                        </span>
                </div>
                <div className={'user-info'}>
                    <div className={'row'}>
                        <span>Հեռախոս:</span>
                        <span>{user?.username || " - "}</span>
                    </div>
                    <div className={'row'}>
                        <span>Էլ․ հասցե:</span>
                        <span>{user?.email || " - "}</span>
                    </div>
                    <div className={'row'}>
                        <span>Գրանցման Օր/ժամ:</span>
                        <span>{user?.created_at ? moment(user.created_at).format("DD.MM.YYYY / HH:mm") : " - "}</span>
                    </div>
                    <div className={'row'}>
                        <span>Ծննդյան Ամսաթիվ:</span>
                        <span>{user?.birthday ? moment(user.birthday).format("DD.MM.YYYY") : " - "}</span>
                    </div>
                    <div className={'row'}>
                        <span>Գեներացված ID:</span>
                        <span>{user?.referral_voucher_code?.voucher_code ? user?.referral_voucher_code?.voucher_code : " - "}</span>
                    </div>
                    <div className={'row'}>
                        <span>Օգտագործման քանակ:</span>
                        <span>{user?.referral_voucher_code?.usage_count ? user?.referral_voucher_code?.usage_count : " - "}</span>
                    </div>
                    <div className={'row'}>
                        <span>Վաուչերի օգտատեր:</span>
                           <div className={'users-list'}>
                               {
                                   user?.used_voucher_codes?.length  ? <Link to={`/user/details/${user?.used_voucher_codes[0]?.user?.id}`}
                                                                             className={"id_used_user"}
                                                                             key={user?.used_voucher_codes[0]?.user?.id}>
                                       <div className={'name'}>{voucherFullName}</div>
                                       <div className={'username'}>+{user?.used_voucher_codes[0]?.user?.username}</div>
                                   </Link> : '-'
                               }
                            </div>
                    </div>
                    {user?.referral_voucher_code && <div className={'row'}>
                        <span>ID-ն օգտագործած օգտատերը:</span>
                        <div className={'users-list'}>
                            {user?.referral_voucher_code?.users?.map(voucherUser => {
                                let fullName = `${voucherUser?.first_name || ""} ${voucherUser?.last_name || ""}`
                                return <Link to={`/user/details/${voucherUser?.id}`}
                                             className={"id_used_user"}
                                             key={voucherUser?.id}>
                                    <div className={'name'}>{fullName}</div>
                                    <div className={'username'}>{voucherUser?.username || " - "}</div>
                                </Link>
                            })}
                        </div>
                    </div>}
                </div>
                <label className={'list-label'}>Active Devices</label>
                <ItemsList itemListInfo={deviceListInfo}
                           itemListHeaderInfo={deviceListHeaderInfo}
                           withLong={true}
                           loading={requestActivityLoading}
                />
                <label className={'list-label'}>Activity logs</label>
                <ItemsList itemListInfo={activityUserListInfo}
                           itemListHeaderInfo={activityUserListHeaderInfo}
                           listRef={this.listRef}
                           loading={requestActivityLoading}
                           // detailsLink={"event/details"}
                           infiniteScroll={true}
                           withLong={true}
                           hasMore={activityUserList?.hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>
        </PageWrapper>
    }
}


const mapDispatchToProps = {
    GetUserById,
    RemoveUserActivities,
    GetUserActivities,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
