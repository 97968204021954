import React from "react";
// import {Icon as ANTDIcon} from "antd";

// export function Icon(props) {
//     return <ANTDIcon type={props.icon}
//                      theme={props.theme}
//                      onClick={props.onClick}
//                      className={props.className || ""}/>
// }

export function MaterialIcon(props) {
    return <i className={`material-icons ${props.className || ""}`}
              onClick={props.onClick}>{props.icon}</i>
}
