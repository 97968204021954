// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import styles
import "../assets/styles/containerStyles/contact-rule-resource.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetRule, UpdateRule} from "../redux/actions";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues,
    toggleStateField,
    getTranslationsInitialValues,
    getPostReqData
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import {Editor} from "../components/uiElements/Editor";
import MediaSelectorModal from "../components/media/MediaSelectorModal";
import {ACCEPT_IMAGE_TYPES} from "../constants/acceptedTypes";
import {MAIN_LANGUAGE_TYPES} from "../constants/constsTypes";

export class Rule extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['text'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
            },
            mediaModalOpen: false,
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: MAIN_LANGUAGE_TYPES.en,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.updateRule = this.updateRule.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
    }

    async componentDidMount() {
        !this.props?.ruleData?.id && await this.props.GetRule();
        console.log('rule',this.props?.ruleData)
        const {ruleData} = this.props;

        ruleData && this.setState({
            fieldsData: {
                translations: getTranslationsInitialValues(ruleData.translations,
                    ['text']),
            },
        });
    }

    updateRule() {
        this.setState({
            requestLoading: true
        });
        const {translations} = this.state.fieldsData;
        const translationRequiredFields = ['text']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        if (result) {
            let reqData = this.getPostReqData(this.state.fieldsData);
            this.props.UpdateRule(reqData).then(() => {
                this.setState({
                    requestLoading: false,
                    errors: errors,
                    errorsTabs: validationTr.errorsTabs
                })
            })
        }
    }

    render() {
        const {requestLoading} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs, mediaModalOpen} = this.state;
        const {translations} = fieldsData;
        const trData = translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={'Կանոնակարգ'}>
            <section className="contact-rule-resource">
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className={'editor-wrapper'}>
                    <label>Ներքին կանոնակարգ</label>
                    <Editor
                        value={trData?.text || ""}
                        name={'text'}
                        initValue={fieldsData?.text}
                        error={errors['text' + languageTab]}
                        onChange={this.getTranslatableInputValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                loading={requestLoading}
                                cb={this.updateRule}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetRule,
    UpdateRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rule);
