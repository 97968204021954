// Import packages
import React, {useEffect, useRef, useState} from "react";
import {SketchPicker} from "react-color";
import '../../assets/styles/components/uiElements/color-piker.scss';


export function ColorPicker(props) {
    const {color, onChange, initColor, name='color'} = props;
    const [showPicker, setShowPicker] = useState(false);
    const pickerStateRef = useRef(showPicker);
    pickerStateRef.current = showPicker;
    const picker = useRef();
    useEffect(() => {
        window.addEventListener('click', () => checkClickOutside());
        return () => {
            window.removeEventListener('click', checkClickOutside);
        }
    }, [])

    const checkClickOutside = () => {
        pickerStateRef.current && setShowPicker(false);
    }

    function toggleColorPicker(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowPicker(!showPicker)
    }
    return <div className="color-piker">
        <label>Գույն</label>
        <div className={'color-wrapper'}>
            <div className={'display-color'} onClick={toggleColorPicker}>
                <div className={'color-inner'} style={{backgroundColor: color}} ref={picker}/>
            </div>
            {showPicker ? <div className={'popover-wrapper'} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <div className={'cover'} onClick={toggleColorPicker}/>
                <SketchPicker color={color||''}
                              onChange={(newColor) => onChange({
                                  value: newColor?.hex,
                                  name: name,
                                  haveChanges: initColor !== newColor?.hex
                              })}/>
            </div> : null}

        </div>
    </div>
}
