// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    DeleteArticleById,
    GetArticles,
} from "../../redux/actions";
import {GET_LIMITS} from "../../constants/constsTypes";
import {defaultEmptyView} from "../../assets/images";
import InfiniteScroll from "react-infinite-scroller";


class Articles extends Component {
    constructor(props) {
        super(props);
        this.deleteItem = deleteItem.bind(this, props.DeleteArticleById, 'Բլոգը');
        this.onPositionChange = this.onPositionChange.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetArticles({
            offset:0,
            limit: GET_LIMITS,
        });
    }

    loadMoreItems(){
        const { hasMore,articleList } = this.props.articleData;
        hasMore && this.props.GetArticles({
            reset: false,
            offset:articleList.length,
            limit: GET_LIMITS,
        });
    }

    getNestableItems(list, parent = null) {
        const items = [];
        list && !!list?.articleList.length && list?.articleList.forEach((item, index) => {
            const title = item?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.image_url,
                link: {
                    pathname: `/article/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedSliderId = item.id;
        const {articleList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~articleList.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            this.props.GetArticles();
        }
    }

    render() {
        let {articleData} = this.props;
        let items = this.getNestableItems(articleData);
        return <PageWrapper pageTitle={'Բլոգ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/article/add"}/>
            <InfiniteScroll
                hasMore={articleData?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'groupWorkoutList'}
                useWindow={true}
                initialLoad={false}>
                {!!articleData?.articleList?.length ?
                    <Nestable
                        items={items}
                        confirmChange={()=>false}
                        maxDepth={1}
                        handler={()=>{}}
                        renderItem={NestableItem}
                    />
                    : <div className={'empty-view'}>
                        <img src={defaultEmptyView}/>
                    </div>}
            </InfiniteScroll>
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetArticles,
    DeleteArticleById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);