// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetInvitations,
} from "../../redux/actions";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";
import moment from "moment";
import {defaultEmptyView} from "../../assets/images";
import InfiniteScroll from "react-infinite-scroller";
import {GET_LIMITS} from "../../constants/constsTypes";
import PageHeader from "../../components/pageContentViews/pageHeader";


class Invitations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: ""
        }
        this.getSearchValue = this.getSearchValue.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetInvitations({
            offset:0,
            limit: GET_LIMITS,
        });
    }
    getSearchValue (text) {
        this.setState({
            searchValue: text
        })
        this.props.GetInvitations({
            reset: true,
            text: text,
            offset: 0,
            limit: GET_LIMITS,
        });
    }

    loadMoreItems(){
        const { hasMore ,invitationsList} = this.props.invitations;
        hasMore && this.props.GetInvitations({
            reset: false,
            offset:invitationsList.length,
            limit: GET_LIMITS,
            text: this.state?.searchValue,
        });
    }

    render() {
        let {searchValue} = this.state;
        let {invitations, requestLoading} = this.props;

        let itemListHeaderInfo = {
            generalInfo: ["հեռախոսահամար","Գեներացված ID","Օգտագործման քանակ", "Ամսաթիվ","Վերջին օգտ. ամսաթիվ"],
            largeItems: [],
            status: false,
        };
        let itemListInfo = invitations?.invitationsList?.map((invitation) => {
            const {user,usage_count, voucher_code, usage_latest_date} = invitation;
            return {
                values: [
                    user?.username,
                    voucher_code,
                    usage_count,
                    moment(user?.created_at).format('DD/MM/YYYY HH:mm'),
                    moment(usage_latest_date).format('DD/MM/YYYY HH:mm')
                ],
            }
        });
        return <PageWrapper pageTitle={'Հրավերներ'}>
            <PageHeader search={true}
                        searchValue={searchValue}
                        getSearchValue={this.getSearchValue}/>
            <InfiniteScroll
                hasMore={invitations?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'groupWorkoutList'}
                useWindow={true}
                initialLoad={false}>
                {!!invitations?.invitationsList.length ?
                    <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                                          itemListInfo={itemListInfo}
                                          loading={requestLoading}/>
                     : <div className={'empty-view'}>
                        <img src={defaultEmptyView}/>
                    </div>}
            </InfiniteScroll>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetInvitations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invitations);
