// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

//import styles


// Import components
import MediaSelectorModal from "../../../components/media/MediaSelectorModal";
import { InputGroup } from "../../../components/uiElements/inputGroup";
import { LinkButton } from "../../../components/buttons/buttons";
import { MediaFileCard } from "../../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationsInitialValues,
    initTranslations, toggleStateField,
} from "../../../utils/helperFunctions";
import { mapStateToProps } from "../../../redux/mapStateToProps";
import {
    GetPool,
    UpdatePoolById,
} from "../../../redux/actions";
import { validateTranslations } from "../../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../../constants/acceptedTypes";
import { history } from "../../../configs/history";
import { LanguageTabs } from "../../../components/uiElements/Tabs";
import { MAIN_LANGUAGE_TYPES, MEDIA_MODAL_TYPES } from "../../../constants/constsTypes";
import { transformImage } from "../../../utils/transformImage";


class Pool extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                image: null,
                logo: null,
                images: [],
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
            mediaModalOpen: false,
            modalAllowedTypes: '',
            imagesModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = this.toggleMediaModal.bind(this);
        this.toggleImagesModal = toggleStateField.bind(this, 'imagesModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getImagesList = this.getImagesList.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditPool = this.addEditPool.bind(this);
    }

    async componentDidMount () {
        await this.props.GetPool();
        const editingData = this.props.poolDate
        if (editingData) {
            const initStateData = {
                translations: getTranslationsInitialValues(editingData.translations, ['title', 'description']),
                image: editingData?.image_object_name ?
                    transformImage(editingData.image_object_name, editingData.image_url)
                    : null,
                logo: editingData?.logo_object_name ?
                    transformImage(editingData.logo_object_name, editingData.logo_url)
                    : null,
                images: editingData?.images?.map(item => {
                    return transformImage(item.image_object_name, item.image_url)
                }) || [],
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getImagesList (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: mediaArray
            },
            errors: {
                ...errors,
                images: false
            }
        })
    }

    deleteMedia (key) {
        const { fieldsData } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: fieldsData.images.filter(item => item.key !== key)
            }
        })
    }

    deleteSingleMedia (key) {
        const { fieldsData } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                [key]: null
            }
        })
    }

    getMultiSelectData = (checkedKeys, name) => {
        const { fieldsData } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };

    getMedia (mediaArray) {
        const { fieldsData, errors, modalAllowedTypes } = this.state;
        let newState;
        if (modalAllowedTypes === 'image') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    image: mediaArray[0],
                },
                errors: {
                    ...errors,
                    image: false
                }
            }
        } else if (modalAllowedTypes === 'image_logo') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    logo: mediaArray[0],
                },
                errors: {
                    ...errors,
                    logo: false
                }
            }
        }

        this.setState(newState)
    }

    toggleMediaModal (type = '') {
        console.log('type', type)
        this.setState({
            mediaModalOpen: !this.state.mediaModalOpen,
            modalAllowedTypes: type
        })
    }

    onMediaPositionChange (items) {
        const { fieldsData } = this.state;
        const mediaArray = items.map(item => item.media);
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: mediaArray
            }
        })
    }

    mediaCardWrapper (data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={(key) => this.deleteMedia(key, MEDIA_MODAL_TYPES.MEDIA_ARRAY)}/>
    }

    async addEditPool () {
        await this.setState({
            requestLoading: true
        });
        const { translations, image, logo } = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };


        if (!image) {
            errors.image = true;
            result = false;
        }
        if (!logo) {
            errors.logo = true;
            result = false;
        }
        if (result) {
            // const trData = getTranslationData(translations);
            // const reqData = this.getPatchReqData(trData);
            let reqData = this.getPostReqData(this.state.fieldsData);
            if (Object.keys(reqData).length) {
                this.props.UpdatePoolById(reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            } else {
                await this.setState({ requestLoading: false });
                history.push('/service/pool')
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }


    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            errorsTabs,
            imagesModalOpen,
        } = this.state;

        let mediaItems = [];

        fieldsData.images.forEach((media, index) => {
            mediaItems.push({
                id: index,
                media: media
            })
        });

        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        return <div>
            <section className="general-add-edit">
                <div className={'tops-wrapper'}>
                    <div className="top-side">
                        <label>Նկար <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'image')}
                        />
                        {fieldsData?.image && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.image}
                                           customDelete={this.deleteSingleMedia.bind(this, 'image')}
                            />
                        </div>}
                    </div>
                    <div className="top-side">
                        <label>Նկար Կայք<span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.logo ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'image_logo')}
                        />
                        {fieldsData?.logo && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.logo}
                                           customDelete={this.deleteSingleMedia.bind(this, 'logo')}
                            />
                        </div>}
                    </div>
                </div>
                <div className={'posts-medias-list-wrapper '}>
                    <div className={'label-and-action'}>
                        <label>Նկարներ</label>
                        <LinkButton title="Ընտրել Մեդիադարանից"
                                    className={`media-select-btn`}
                                    cb={this.toggleImagesModal}/>
                    </div>
                    <section className={'items-wrapper items-medias-list-wrapper'}>
                        {fieldsData.images.length ? <Nestable
                                items={mediaItems}
                                maxDepth={1}
                                onChange={this.onMediaPositionChange}
                                renderItem={this.mediaCardWrapper}
                            /> :
                            <p className={'empty-text'}>Նկարներ չկան</p>}
                    </section>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            value={currentData.description}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                cb={this.addEditPool}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <MediaSelectorModal
                isOpen={!!imagesModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getImagesList}
                multiSelect={true}
                closeModal={this.toggleImagesModal}
            />
        </div>
    }
}

const mapDispatchToProps = {
    GetPool,
    UpdatePoolById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pool)
