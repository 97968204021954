import {request, _urlCategories} from "../api";
import {CATEGORY_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetCategories = () => {
    const requestData = {
        url: _urlCategories,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: CATEGORY_CONSTS.GET_CATEGORIES,
                    payload: data
                });
            })
    }
};

export const GetCategoryById = (id) => {
    const requestData = {
        url: `${_urlCategories}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CATEGORY_CONSTS.GET_CATEGORY_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const DeleteCategoryById = id => {
    const requestData = {
        url: `${_urlCategories}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: CATEGORY_CONSTS.DELETE_CATEGORY_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateCategory = data => {
    const requestData = {
        url: _urlCategories,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CATEGORY_CONSTS.CREATE_CATEGORY,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Կատեգորիան հաջողությամբ ստեղծվեց!"
                })
                history.push('/categories')

            })
    }
};

export const UpdateCategoryById = (id, data, redirect= true) => {
    const requestData = {
        url: `${_urlCategories}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                redirect && notificationSuccess({
                    description: "Կատեգորիան հաջողությամբ փոփոխվեց!"
                })
                redirect && history.push('/categories')
            })
    }
};

export const UpdateCategoriesPositions = items => {
    const requestData = {
        url: _urlCategories,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Կատեգորիան հաջողությամբ թարմացվեց!"
                })
            })
    }
};
