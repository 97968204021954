import {LANGUAGE_TYPES, MAIN_LANGUAGE_TYPES} from "../constants/constsTypes";

export const validateTranslations = (validationData,
                                     requiredFields,
                                     onlyMainRequired) => {
    let result = true;
    const errorsTabs = [];
    const errors = {};
    const activeLanguages = LANGUAGE_TYPES;
    const mainLanguage = MAIN_LANGUAGE_TYPES.en;
    activeLanguages && Object.values(activeLanguages).forEach(lg => {
        const trData = validationData?.find(item => item?.lang === lg) || {};
        const isMain = lg === mainLanguage;
        let translationIsNotEmpty = false;
        const errorFields = [];

        requiredFields.forEach(key => {
            if (!trData[key] || trData[key] === "" || (Array.isArray(trData[key]) && !trData[key].length)) {
                // console.groupCollapsed(` %c Translation`, "color:red");
                // console.log( trData);
                // console.log( key);
                // console.log( lg);
                // console.groupEnd();
                errorFields.push(key + lg);
            } else {
                translationIsNotEmpty = true;
            }
        });
        if (errorFields.length && (isMain || !onlyMainRequired || translationIsNotEmpty)) {
            errorFields.forEach(field => errors[field] = true);
            errorsTabs.push(lg);
            result = false;
        }
    });
    return {
        result,
        errorsTabs,
        errors,
    };
};
