// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import components
import { NestableItem } from "../../../components/cards/nestableItem";

// Import utils
import { mapStateToProps } from "../../../redux/mapStateToProps";
import {
    changeStateField,
    deleteItem,
    getTranslatableInputValues, getTranslationData, getTranslationsInitialValues,
    initTranslations,
    toggleStateField
} from "../../../utils/helperFunctions";
import {
    GetSpa,
    UpdateSpa,

    DeleteSpaServiceById,
    DeleteSpaWorkerById,

    UpdateSpaServicePositions,
    UpdateSpaWorkerPositions
} from "../../../redux/actions";
import { LinkButton } from "../../../components/buttons/buttons";
import { MediaFileCard } from "../../../components/media/MediaFileCard";
import MediaSelectorModal from "../../../components/media/MediaSelectorModal";
import { transformImage } from "../../../utils/transformImage";
import { ACCEPT_IMAGE_TYPES } from "../../../constants/acceptedTypes";
import {MAIN_LANGUAGE_TYPES} from "../../../constants/constsTypes";
import {LanguageTabs} from "../../../components/uiElements/Tabs";


class Spa extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'price_list_object_name',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                logo: null,
            },
            errors: {},
            languageTab: MAIN_LANGUAGE_TYPES.en,
            mediaModalOpen: false,
            modalAllowedTypes: '',
            pdfFileModalOpen: false,
        };
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.togglePdfFileModal = toggleStateField.bind(this, 'pdfFileModalOpen');
        this.getPdfFile = this.getPdfFile.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.toggleMediaModal = this.toggleMediaModal.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);

        this.delete_service_Item = deleteItem.bind(this, props.DeleteSpaServiceById, 'Ծառայությունը');
        this.delete_worker_Item = deleteItem.bind(this, props.DeleteSpaWorkerById, 'Աշխատակիցին');
        this.onServicePositionChange = this.onServicePositionChange.bind(this);
        this.onWorkerPositionChange = this.onWorkerPositionChange.bind(this);
        this.updateSpa = this.updateSpa.bind(this);
    }

    async componentDidMount () {
        await this.props.GetSpa();
        let { fieldsData } = this.state;
        let { spa } = this.props;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: getTranslationsInitialValues(spa.translations, ['price_list_object_name']),
                logo: spa?.logo_file_object_name ? transformImage(spa?.logo_file_object_name, spa?.logo_url) : null
            }
        })

    }

    getPdfFile(pdfFile) {
        console.log(pdfFile,'pdfFile')
        const {fieldsData,languageTab} = this.state;
        const { translations } = fieldsData;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: translations.map(item => item.lang === languageTab ? {
                    ...item,
                    price_list_object_name: pdfFile[0]
                } : item )
            },
        })
    }


    getMedia (mediaArray) {
        const { fieldsData, errors, modalAllowedTypes } = this.state;
        let newState;
        if (modalAllowedTypes === 'logo') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    logo: mediaArray[0],
                },
                errors: {
                    ...errors,
                    logo: false
                }
            }
        } else {
            this.setState({
                fieldsData: {
                    ...fieldsData,
                    file: mediaArray[0],
                },
            })
        }
        this.setState(newState)
    }

    deleteMedia () {
        const { fieldsData } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                file: null,
            }
        })
    }

    updateSpa () {
        const {  logo,translations } = this.state.fieldsData
        console.log(translations,'translations')
        let { spa } = this.props;
        if (!logo?.key) {
            this.setState({
                errors: {
                    logo: true,
                }
            })
            return;
        }
        let TR_data = getTranslationData(translations);
        console.log(TR_data,'translations')

        this.props.UpdateSpa(spa.id, {
            logo_object_name: logo?.key,
            translations: TR_data,
        })
    }

    toggleMediaModal (type = '') {
        console.log('type', type)
        this.setState({
            mediaModalOpen: !this.state.mediaModalOpen,
            modalAllowedTypes: type
        })
    }

    getNestableItems (list, key) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.[key === 'service' ? 'title' : 'name'] || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.image_url,
                link: {
                    pathname: `/service/spa/${key}/edit/${item.id}`,
                },
                deleteCb: () => this?.[`delete_${key}_Item`](item.id),
                children: []
            })
        });
        return items;
    }

    async onServicePositionChange (items) {
        const positionedItems = items.map((item, index) => {
            return { id: item.id, position: index + 1 }
        });
        await this.props.UpdateSpaServicePositions(positionedItems);
        this.props.GetSpa();
    }

    async onWorkerPositionChange (items) {
        const positionedItems = items.map((item, index) => {
            return { id: item.id, position: index + 1 }
        });
        await this.props.UpdateSpaWorkerPositions(positionedItems);
        this.props.GetSpa();
    }

    render () {
        let { fieldsData, mediaModalOpen, errors, modalAllowedTypes,pdfFileModalOpen ,languageTab,errorsTabs,} = this.state;
        let { spaServices, spaWorkers, spa } = this.props;
        let serviceItems = this.getNestableItems(spaServices, 'service');
        let workerItems = this.getNestableItems(spaWorkers, 'worker');
        console.log(fieldsData?.translations,'fieldsData?.translations')

        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData?.translations?.find(item => item?.lang === languageTab) || {};
        return <div className={'general-add-edit spa-page'}>
            <div className={'tops-wrapper'}>
                <div className="top-side">
                    <label>Նկար Կայք<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.logo ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal.bind(this, 'logo')}
                    />
                    {fieldsData?.logo && <div className={'main-media'}>
                        <MediaFileCard item={fieldsData?.logo}
                        />
                    </div>}
                </div>
            </div>
            <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                          errorsTabs={errorsTabs}
                          activeTab={languageTab}/>

            <div className={'label-and-action'}>
                <label>Կցել PDF File</label>
                <LinkButton className={"media-select-btn bg-white"}
                            title={"Ընտրել PDF ֆայլ"}
                            cb={this.togglePdfFileModal}/>
            </div>
            <div className={'contract-wrapper'}>
                <div className={'wrapper'}>
                    <span>Անվանում՝ {currentData?.price_list_object_name?.name || currentData?.price_list_object_name || initTranslations?.price_list_object_name?.name}</span>
                </div>
            </div>

            <div className="flex-wrapper-right">
                <LinkButton title="Փոփոխել"
                            disabled={!((fieldsData?.logo?.key !== spa?.logo_file_object_name) || currentData?.price_list_object_name)}
                            cb={this.updateSpa}
                />
            </div>

            <div className="flex-wrapper-right">
                <LinkButton title={'Ավելացնել Ծառայություն'}
                            link={'/service/spa/service/add'}/>
            </div>
            {serviceItems && serviceItems.length !== 0 &&
            <Nestable
                items={serviceItems}
                maxDepth={1}
                onChange={this.onServicePositionChange}
                renderItem={NestableItem}
            />}



            <div className="flex-wrapper-right" style={{ marginTop: '50px' }}>
                <LinkButton title={'Ավելացնել աշխատակից'}
                            link={'/service/spa/worker/add'}/>
            </div>
            {workerItems && workerItems.length !== 0 &&
            <Nestable
                items={workerItems}
                maxDepth={1}
                onChange={this.onWorkerPositionChange}
                renderItem={NestableItem}
            />}


            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={modalAllowedTypes === 'pdf' ? ['pdf'] : ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />

            <MediaSelectorModal
                isOpen={!!pdfFileModalOpen}
                acceptTypes={['pdf']}
                getMedia={this.getPdfFile}
                appendMedias={fieldsData?.price_list_object_name}
                closeModal={this.togglePdfFileModal}
            />
        </div>

    }
}

const mapDispatchToProps = {
    GetSpa,
    UpdateSpa,

    DeleteSpaServiceById,
    DeleteSpaWorkerById,

    UpdateSpaServicePositions,
    UpdateSpaWorkerPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Spa);
