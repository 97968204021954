// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    DeleteFAQById,
    GetFAQs,
    UpdateFAQPositions,
} from "../../redux/actions";

// Import components
import { NestableItem } from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import { deleteItem } from "../../utils/helperFunctions";


class FAQ extends Component {
    constructor (props) {
        super(props);
        this.deleteCategoryItem = this.deleteCategoryItem.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount () {
        this.props.GetFAQs();
    }

    deleteCategoryItem (id) {
        this.props.DeleteFAQById(id).then(() => {
            this.props.GetFAQs();
        })
    }


    async onPositionChange (items, item) {
        const positionedItems = items.map((item, index) => {
            return { id: item.id, position: index + 1 }
        });
        await this.props.UpdateFAQPositions(positionedItems);
        this.props.GetFAQs();
    }

    getNestableItems (list, parent = null) {
        const items = [];

        list && !!list.length && list.forEach((item, index) => {
            const title = item?.question || ' - ';
            items.push({
                id: item.id,
                index: index,
                title: title,
                isHidden: item.is_hidden,
                link: {
                    pathname: `faq/edit/${item.id}`,
                },
                deleteCb: deleteItem.bind(this, this.deleteCategoryItem, 'Հարց-Պատասխանը', item.id),
                children:  []
            })
        });
        return items;
    }


    render () {
        let { faqList } = this.props;
        let items = this.getNestableItems(faqList);
        return <PageWrapper pageTitle={'Հարց-Պատասխան'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/faq/add"}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFAQs,
    DeleteFAQById,
    UpdateFAQPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
