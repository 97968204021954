// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import components
import { NestableItem } from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { deleteItem } from "../../utils/helperFunctions";
import {
    DeleteTrainerById, GetTeam,
    GetTrainers, UpdateTeam,
} from "../../redux/actions";
import { LinkButton } from "../../components/buttons/buttons";
import { MediaFileCard } from "../../components/media/MediaFileCard";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { transformImage } from "../../utils/transformImage";


class Trainers extends Component {
    constructor (props) {
        super(props);
        this.state = {
            fieldsData: {
                logo: null,
            },
            errors: {},
            mediaModalOpen: false,
        };
        this.toggleMediaModal = this.toggleMediaModal.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteItem = deleteItem.bind(this, props.DeleteTrainerById, 'Մարզիչը');
        this.onPositionChange = this.onPositionChange.bind(this);
        this.updateTeam = this.updateTeam.bind(this);
    }

    async componentDidMount () {
        this.props.GetTrainers();
        await this.props.GetTeam();
        let { fieldsData } = this.state;
        let { team } = this.props;

        this.setState({
            fieldsData: {
                ...fieldsData,
                logo: team?.logo_file_object_name ? transformImage(team?.logo_file_object_name, team?.logo_url) : null
            }
        })
    }

    getNestableItems (list, parent = null) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.name || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.image_url,
                link: {
                    pathname: `/trainer/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange (items, item) {
        const movedSliderId = item.id;
        const { trainerList } = this.props;
        const positionedItems = items.map((item, index) => {
            return { id: item.id, position: index }
        });
        if (~trainerList.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            this.props.GetTrainers();
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
                fieldsData: {
                    ...fieldsData,
                    logo: mediaArray[0],
                },
                errors: {
                    ...errors,
                    logo: false
                }
            }
        )
    }

    updateTeam () {
        const { logo } = this.state.fieldsData
        if (!logo?.key) {
            this.setState({
                errors: {
                    logo: true,
                }
            })
            return;
        }
        this.props.UpdateTeam({
            logo_object_name: logo?.key,
        })
    }

    toggleMediaModal () {
        this.setState({
            mediaModalOpen: !this.state.mediaModalOpen,
        })
    }

    render () {
        let { fieldsData, mediaModalOpen, errors } = this.state;
        let { trainerList, team } = this.props;
        let items = this.getNestableItems(trainerList);
        return <PageWrapper pageTitle={'Մարզիչը'}>
            <div className="top-side">
                <label>Նկար Կայք<span className={'required-badge'}>*</span></label>
                <LinkButton title="Ընտրել մեդիադարանից"
                            className={`media-select-btn ${errors.logo ? 'media-select-error' : ''}`}
                            cb={this.toggleMediaModal}
                />
                {fieldsData?.logo && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.logo}
                    />
                </div>}
            </div>
            <div className="flex-wrapper-right">
                <LinkButton title="Փոփոխել"
                            disabled={!(fieldsData?.logo?.key !== team?.logo_file_object_name)}
                            cb={this.updateTeam}
                />
            </div>
            <div className={'general-add-edit'}>
                <PageHeader linkTitle={"Ավելացնել"}
                            addingLink={"/trainer/add"}/>
                {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    confirmChange={() => false}
                    maxDepth={1}
                    handler={() => {
                    }}
                    renderItem={NestableItem}
                />}
            </div>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetTeam,
    UpdateTeam,
    GetTrainers,
    DeleteTrainerById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trainers);
