import { AUTH_CONSTS, FITNESS_CONSTS } from "../constants";

export const initialState = {
    fitness: {},
    fitnessServices: [],
    fitnessServiceById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case FITNESS_CONSTS.GET_FITNESS:
            const { services, ...fitness } = action.payload
            return {
                ...state,
                fitness,
                fitnessServices: services || [],
            };
        case FITNESS_CONSTS.UPDATE_FITNESS:
            return {
                ...state,
                fitness: action.payload,
            };
        case FITNESS_CONSTS.CREATE_FITNESS_SERVICE:
            return {
                ...state,
                fitnessServices: [action.payload].concat(state.fitnessServices)
            };
        case FITNESS_CONSTS.GET_FITNESS_SERVICE_BY_ID:
            return {
                ...state,
                fitnessServiceById: action.payload
            };
        case FITNESS_CONSTS.UPDATE_FITNESS_SERVICE_BY_ID:
            return {
                ...state,
                fitnessServices: state.fitnessServices.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case FITNESS_CONSTS.DELETE_FITNESS_SERVICE_BY_ID:
            return {
                ...state,
                fitnessServices: state.fitnessServices.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
