import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import moderators from "./moderators";
import media from "./media";
import composite from "./composite";
import filter from "./filter";
import category from "./category";
import slider from "./slider";
import general from "./general";
import notifications from "./notifications";
import event from "./event";
import article from "./article";
import service from "./service";
import trainer from "./trainer";
import spa from "./spa";
import fitness from "./fitness";
import cafe from "./cafe";
import pool from "./pool";
import workoutSection from "./workoutSection";
import groupWorkout from "./groupWorkout";
import staticTexts from "./staticTexts";
import faq from "./faq";
import about from "./about";
import activity from "./activity";


export default combineReducers({
    auth,
    utils,
    media,
    composite,
    moderators,
    filter,
    category,
    slider,
    general,
    notifications,
    event,
    article,
    service,
    trainer,
    spa,
    fitness,
    cafe,
    workoutSection,
    groupWorkout,
    staticTexts,
    faq,
    about,
    pool,
    activity,
});
