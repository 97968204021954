import { AUTH_CONSTS, WORKOUT_SECTION_CONSTS } from "../constants";

export const initialState = {
    workoutSectionsList: [],
    workoutSectionById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case WORKOUT_SECTION_CONSTS.GET_WORKOUT_SECTIONS:
            return {
                ...state,
                workoutSectionsList: action.payload || [],
            };
        case WORKOUT_SECTION_CONSTS.CREATE_WORKOUT_SECTION:
            return {
                ...state,
                workoutSectionsList: [action.payload].concat(state.workoutSectionsList)
            };
        case WORKOUT_SECTION_CONSTS.GET_WORKOUT_SECTION_BY_ID:
            return {
                ...state,
                workoutSectionById: action.payload
            };
        case WORKOUT_SECTION_CONSTS.UPDATE_WORKOUT_SECTION_BY_ID:
            return {
                ...state,
                workoutSectionsList: state.workoutSectionsList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case WORKOUT_SECTION_CONSTS.DELETE_WORKOUT_SECTION_BY_ID:
            return {
                ...state,
                workoutSectionsList: state.workoutSectionsList.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
