import { AUTH_CONSTS, POOL_CONSTS } from "../constants";

export const initialState = {
    poolDate: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case POOL_CONSTS.GET_POOL:
            return {
                ...state,
                poolDate: action.payload ,
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
