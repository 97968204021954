// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tree } from "antd";

//import styles


// Import components
import PageWrapper from '../../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../../components/media/MediaSelectorModal";
import { InputGroup } from "../../../components/uiElements/inputGroup";
import { LinkButton } from "../../../components/buttons/buttons";
import { MediaFileCard } from "../../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationsInitialValues,
    initTranslations, toggleStateField,
} from "../../../utils/helperFunctions";
import { mapStateToProps } from "../../../redux/mapStateToProps";
import {
    CreateFitnessService,
    GetFitnessServiceById,
    UpdateFitnessServiceById,
} from "../../../redux/actions";
import { validateTranslations } from "../../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../../constants/acceptedTypes";
import { history } from "../../../configs/history";
import { LanguageTabs } from "../../../components/uiElements/Tabs";
import { MAIN_LANGUAGE_TYPES, MEDIA_MODAL_TYPES } from "../../../constants/constsTypes";
import Nestable from "react-nestable/dist/Nestable";
import { transformImage } from "../../../utils/transformImage";


class AddEditFitness extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                image: null,
                trainers: [],
                images: [],
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
            mediaModalOpen: false,
            imagesModalOpen: false,
        };
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleImagesModal = toggleStateField.bind(this, 'imagesModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getImagesList = this.getImagesList.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditFitness = this.addEditFitness.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;
        if (isEditing) {
            await this.props.GetFitnessServiceById(this.props.id);
            const editingData = this.props.fitnessServiceById
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations, ['title', 'description']),
                    image: editingData?.image_object_name ?
                        transformImage(editingData.image_object_name, editingData.image_url)
                        : null,
                    trainers: editingData?.trainers.map(item => item?.id) || [],
                    images: editingData?.images?.map(item => {
                        return transformImage(item.image_object_name, item.image_url)
                    }) || [],
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }


    getMultiSelectData = (checkedKeys, name) => {
        const { fieldsData } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };


    deleteMedia (key, type) {
        const { fieldsData } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: fieldsData.images.filter(item => item.key !== key)
            }
        })
    }

    getMedia (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                image: mediaArray[0],
            },
            errors: {
                ...errors,
                image: false
            }
        })
    }

    getImagesList (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: mediaArray
            },
            errors: {
                ...errors,
                images: false
            }
        })
    }

    onMediaPositionChange (items) {
        const { fieldsData } = this.state;
        const mediaArray = items.map(item => item.media);
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: mediaArray
            }
        })
    }

    mediaCardWrapper (data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={(key) => this.deleteMedia(key, MEDIA_MODAL_TYPES.MEDIA_ARRAY)}/>
    }

    treeData = (data, children) => {
        const treeData = [];
        data.forEach(item => {
            const treeItem = {
                title: item.name,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        })
        return treeData;
    };

    async addEditFitness () {
        await this.setState({
            requestLoading: true
        });
        const { fitness } = this.props
        const { translations, image } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        // if (!images?.length) {
        //     errors.images = true;
        //     result = false;
        // }

        if (!image) {
            errors.image = true;
            result = false;
        }
        if (result) {
            if (!this.state.isEditing) {
                let reqData = this.getPostReqData(this.state.fieldsData);
                reqData.fitness_id = fitness?.id;
                this.props.CreateFitnessService(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                let reqData = this.getPostReqData(this.state.fieldsData);
                if (Object.keys(reqData).length) {
                    this.props.UpdateFitnessServiceById(this.props.id, reqData)
                        .finally(() => {
                            this.setState({ requestLoading: false });
                        });
                } else {
                    await this.setState({ requestLoading: false });
                    history.push('/service/fitness')
                }

            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            isEditing,
            errorsTabs,
            imagesModalOpen,
            requestLoading
        } = this.state;
        const { trainerList } = this.props

        const mediaItems = [];

        fieldsData.images.forEach((media, index) => {
            mediaItems.push({
                id: index,
                media: media
            })
        });

        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Ֆիթնես' : 'Փոփոխել Ֆիթնես'}>
            <section className="general-add-edit">

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.image && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.image}
                    />
                </div>}

                <div className={'posts-medias-list-wrapper '}>
                    <div className={'label-and-action'}>
                        <label>Նկարներ</label>
                        <LinkButton title="Ընտրել Մեդիադարանից"
                                    className={`media-select-btn`}
                                    cb={this.toggleImagesModal}/>
                    </div>
                    <section className={'items-wrapper items-medias-list-wrapper'}>
                        {fieldsData.images.length ? <Nestable
                                items={mediaItems}
                                maxDepth={1}
                                onChange={this.onMediaPositionChange}
                                renderItem={this.mediaCardWrapper}
                            /> :
                            <p className={'empty-text'}>Նկարներ չկան</p>}
                    </section>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData?.title}
                            initValue={initData?.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            required={true}
                            value={currentData?.description}
                            initValue={initData?.description}
                            error={errors['description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <div className="tree-item">
                    <label>Մարզիչներ</label>
                    <Tree
                        checkable
                        checkedKeys={fieldsData.trainers}
                        onCheck={(keys) => this.getMultiSelectData(keys, 'trainers')}
                        showLine={true}
                        treeData={this.treeData(trainerList, "values")}
                    />
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditFitness}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <MediaSelectorModal
                isOpen={!!imagesModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getImagesList}
                multiSelect={true}
                closeModal={this.toggleImagesModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateFitnessService,
    GetFitnessServiceById,
    UpdateFitnessServiceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFitness)
