import {COMPOSITE_CONSTS} from "../constants";
import { _urlAbout, _urlAppVersion, _urlResource, _urlRule, request } from "../api";
import {_urlOthers, _urlRegion, _urlContact} from "../api";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetContact = () => {
    const requestData = {
        url: `${_urlContact}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                await dispatch({
                    type: COMPOSITE_CONSTS.GET_CONTACT,
                    payload: res.data
                })
            })
    }
};

export const UpdateContact = data => {
    const requestData = {
        url: `${_urlContact}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPOSITE_CONSTS.UPDATE_CONTACT,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Կոնտակտը հաջողությամբ փոփոխվեց!"
                })
            })

    }
};

export const GetRule = () => {
    const requestData = {
        url: `${_urlRule}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                await dispatch({
                    type: COMPOSITE_CONSTS.GET_RULE,
                    payload: res.data
                })
            })
    }
};

export const UpdateRule = data => {
    const requestData = {
        url: `${_urlRule}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPOSITE_CONSTS.UPDATE_RULE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Կանոնակարգը հաջողությամբ փոփոխվեց!"
                })
            })

    }
};

export const GetRegions = () => {
    const requestData = {
        url: `${_urlRegion}`,
        method: "GET",
        token: true,
        languageFlag: true
    };
    return dispatch => {
        return request(requestData).then((res) => {
            dispatch({
                type: COMPOSITE_CONSTS.GET_REGIONS,
                payload: res?.data
            });
        })
    }
};
export const GetRegionResidences = (regionId, returnData=false) => {
    const requestData = {
        url: `${_urlRegion}/${regionId}/residences`,
        method: "GET",
        token: true,
        languageFlag: true
    };
    return dispatch => {
        return request(requestData).then((res) => {
            !returnData && dispatch({
                type: COMPOSITE_CONSTS.GET_REGION_RESIDENCES,
                payload: res?.data
            });
            if (returnData){
                return res?.data;
            }
        })
    }
};
export const ClearRegionResidences = () => {
    return dispatch => {
        dispatch({
            type: COMPOSITE_CONSTS.CLEAR_REGION_RESIDENCES,
        });
    }
};


export const GetCoverPhoto = () => {
    const requestData = {
        url: `${_urlOthers}/cover-photo`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
    }
};


export const UpdateCoverPhoto = (data) => {
    const requestData = {
        url: `${_urlOthers}/cover-photo`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
    }
};



export const GetResource = () => {
    const requestData = {
        url: `${_urlResource}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                await dispatch({
                    type: COMPOSITE_CONSTS.GET_RESOURCE,
                    payload: res.data
                })
            })
    }
};

export const UpdateResourceData = data => {
    const requestData = {
        url: `${_urlResource}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPOSITE_CONSTS.UPDATE_RESOURCE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
            })

    }
};



export const GetAppVersion = () => {
    const requestData = {
        url: `${_urlAppVersion}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                await dispatch({
                    type: COMPOSITE_CONSTS.GET_APP_VERSION,
                    payload: res.data
                })
            })
    }
};

export const UpdateAppVersionData = data => {
    const requestData = {
        url: `${_urlAppVersion}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPOSITE_CONSTS.UPDATE_APP_VERSION,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
            })

    }
};

