import { AUTH_CONSTS, MEDIA_CONSTS } from "../constants";

export const initialState = {
    mediaItems: {
        itemsList: [],
        parent: null,
        hasMore: false,
        searchValue:''
    },
    mediaView:false,
    folderList: [],
    uploadProgresses: {},
    mediaHistory: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEDIA_CONSTS.GET_MEDIA:
            return {
                ...state,
                mediaItems: {
                    itemsList: action.payload.reset ? action.payload.data
                        : state.mediaItems.itemsList.concat(action.payload.data),
                    parent: action.payload.parent,
                    searchValue: action.payload.searchValue,
                    hasMore: action.payload.hasMore,
                }
            };
        case MEDIA_CONSTS.UPLOAD_FILE:
        case MEDIA_CONSTS.ADD_FOLDER:
            return {
                ...state,
                mediaItems: {
                    ...state.mediaItems,
                    itemsList: addFile(state.mediaItems.itemsList, action.payload.pathList, action.payload.data)
                }
            };

        case MEDIA_CONSTS.MOVE_MEDIA_BY_KEY:
        case MEDIA_CONSTS.DELETE_MEDIA_BY_ID:
        case MEDIA_CONSTS.UPDATE_MEDIA_PATH:
            return {
                ...state,
                mediaItems: {
                    ...state.mediaItems,
                    itemsList: state.mediaItems.itemsList.filter(file => file.key !== action.payload)
                }
            };
        case MEDIA_CONSTS.UPDATE_MEDIA:
            return {
                ...state,
                mediaItems: {
                    ...state.mediaItems,
                    itemsList: state.mediaItems.itemsList.map(img => img.id === action.payload.id ? action.payload : img),
                }
            };
        case MEDIA_CONSTS.TOGGLE_MEDIA_VIEW:
            return {
                ...state,
                mediaView: action.payload,
            };

        case MEDIA_CONSTS.SET_FOLDER_LIST:
            return {
                ...state,
                folderList: action.payload,
            };
        case MEDIA_CONSTS.SET_UPLOAD_PROGRESSES:
            return {
                ...state,
                uploadProgresses: action.payload,
            };

        case MEDIA_CONSTS.GET_MEDIA_HISTORY:
            let history = state.mediaHistory.filter(item =>  item !== action.payload);
            history.length > 5 && history.pop();
            return {
                ...state,
                mediaHistory: action.payload ? [action.payload, ...history] : state.mediaHistory,
            };
        case MEDIA_CONSTS.DELETE_MEDIA_HISTORY:
            return {
                ...state,
                mediaHistory: state.mediaHistory.filter((item, index) =>  index !== action.payload),
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}

const addFile = (filesList = [], pathList, items) => {
    const data = Array.isArray(items) ? items : [items];
    // debugger;
    // console.log('pathList', pathList)
    // console.log('filesList', filesList)
    if (!pathList || !pathList.length) {
        return [...data, ...filesList];
    }
    const currPath = pathList[0];
    const currFolderIndex = filesList && filesList.findIndex(folder => {
        return folder.type === "FOLDER" && folder.name === currPath
    });
    if (currFolderIndex === -1) {
        return filesList;
    }
    const pathRightPart = pathList.slice(1);
    const newList = [...filesList];
    const currentFolder = newList[currFolderIndex];
    //console.log('currentFolder', currentFolder)
    newList[currFolderIndex] = {
        ...currentFolder,
        children: addFile(currentFolder.children, pathRightPart, data)
    };
    //console.log('newList', newList)
    return newList;
};

const deleteFile = (filesList = [], pathList, id) => {
    if (!pathList || !pathList.length) {
        return filesList.filter(file => file.id !== id);
    }
    const currPath = pathList[0];
    const currFolderIndex = filesList && filesList.findIndex(folder => {
        return folder.type === "FOLDER" && folder.name === currPath
    });
    if (currFolderIndex === -1) {
        return filesList;
    }
    const pathRightPart = pathList.slice(1);
    const newList = [...filesList];
    const currentFolder = newList[currFolderIndex];
    //console.log('currentFolder', currentFolder)
    newList[currFolderIndex] = {
        ...currentFolder,
        children: deleteFile(currentFolder.children, pathRightPart, id)
    };
    //console.log('newList', newList)
    return newList;
};
