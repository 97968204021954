import { request, _urlEvent, _urlEventJoin } from "../api";
import { EVENT_CONSTS, UTIL_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetEvents = ({ reset = true, offset = 0, limit = 10 }) => {
    const requestData = {
        url: `${_urlEvent}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: EVENT_CONSTS.GET_EVENTS,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            })
    }
};


export const GetEventById = id => {
    const requestData = {
        url: `${_urlEvent}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: EVENT_CONSTS.GET_EVENT_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const GetEventJoinedMembers = ({
                                          id,
                                      }) => {
    const requestData = {
        url: `${_urlEventJoin}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                return data;
            })
    }
};

export const DeleteEventById = id => {
    const requestData = {
        url: `${_urlEvent}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: EVENT_CONSTS.DELETE_EVENT_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const DeleteJoinedMember = id => {
    const requestData = {
        url: `${_urlEventJoin}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
    }
};

export const CreateEvent = data => {
    const requestData = {
        url: _urlEvent,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: EVENT_CONSTS.CREATE_EVENT,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Իրադարձությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/event')
            })
            .catch(() => {
            })
    }
};

export const UpdateEventById = (id, data, redirect = true) => {
    const requestData = {
        url: `${_urlEvent}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: EVENT_CONSTS.UPDATE_EVENT_BY_ID,
                    payload: res.data
                });
                redirect && notificationSuccess({
                    description: "Իրադարձությունը հաջողությամբ փոփոխվեց!"
                })
                redirect && history.push('/event')
            })
            .catch(() => {
            })
    }
};

