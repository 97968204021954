// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

//import assets
import "../../assets/styles/containerStyles/workout-timeline.scss"

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import { Switch, TimePicker, Tooltip } from 'antd';
import swal from "sweetalert";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { getInputValues } from "../../utils/helperFunctions";
import {
    AddWorkoutTimeline,
    CancelWorkoutTimeline,
    ActivateWorkoutTimeline,
    clearGroupWorkoutData,
    DeleteWorkoutTimeline,
    GetGroupWorkoutById,
    GetGroupWorkoutTimeline,
    UpdateWorkoutTimeline
} from "../../redux/actions";
import { WEEKDAY_TYPES } from "../../constants/constsTypes";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";

import { compareDates } from "../../utils/compareDates";
import { MaterialIcon } from "../../components/utils/Icon";
import { localTime, makeUTCTime } from "../../utils/dateHelper";


const timelineTypeOptions = Object.values(WEEKDAY_TYPES).map(weekday => ({
    id: weekday.key,
    name: weekday.name
}));

class WorkoutTimeLine extends Component {
    constructor (props) {
        super(props);
        this.state = {
            week: 0,
            timeline: null,
            workoutTimeLine: null,
            fieldsData: {
                type: undefined,
                startTime: undefined,
                endTime: undefined,
                trainers: [],
                room: '',
                reason: '',
                isNotify: true,
            },
            hoveredWeekDayTrainers: null,
            errors: {},
            requestLoading: false,
            editingTimeline: null,
            cancelingTimeline: null,
        }
        this.getInputValues = getInputValues.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.cancelTimeline = this.cancelTimeline.bind(this);
        this.disabledMinutes = this.disabledMinutes.bind(this);
        this.addUpdateTimeline = this.addUpdateTimeline.bind(this);
    }

    async componentDidMount () {
        this.getTimeLine()
    }

    onMouseEnter (trainers) {
        this.setState({
            hoveredWeekDayTrainers: trainers.map(tr => tr.id)
        })
    }

    onMouseLeave () {
        this.setState({
            hoveredWeekDayTrainers: null
        })
    }

    getTimeLine = async() => {
        await this.props.GetGroupWorkoutById(this.props.id);
        const timeline = await this.props.GetGroupWorkoutTimeline(this.props.id);
        const workoutTimeLine = {}
        Object.keys(WEEKDAY_TYPES).forEach(key => {
            workoutTimeLine[key] = timeline.filter(item => item.weekday === key)
        })
        this.setState({
            timeline,
            workoutTimeLine,
            fieldsData: {
                type: undefined,
                startTime: undefined,
                endTime: undefined,
                trainers: [],
                room: '',
                reason: '',
                isNotify: true,
            },
            editingTimeline: null,
            cancelingTimeline: null,
        })
    }

    componentWillUnmount () {
        this.props.clearGroupWorkoutData()
    }

    async addUpdateTimeline () {
        await this.setState({
            requestLoading: true
        });
        const { fieldsData, editingTimeline } = this.state;
        const errors = {};
        let result = true;


        if (!fieldsData.type) {
            errors.type = true;
            result = false;
        }
        if (!fieldsData.startTime) {
            errors.startTime = true;
            result = false;
        }
        if (!fieldsData.endTime) {
            errors.endTime = true;
            result = false;
        }
        // if (!fieldsData.room) {
        //     errors.room = true;
        //     result = false;
        // }
        if (result && fieldsData.endTime.hour() !== 0 && (fieldsData.endTime.valueOf() - fieldsData.startTime.valueOf() < 1000 * 60 * 14)) {
            errors.endTime = true;
            result = false;
        }
        // if (result && !fieldsData.trainers?.length) {
        //     errors.trainers = true;
        //     result = false;
        // }
        if (result) {
            const reqData = {
                workout_id: this.props.id,
                start_time: fieldsData.startTime.utc(false).format("HH:mm"),
                end_time: fieldsData.endTime.utc(false).format("HH:mm"),
                room: fieldsData.room || "",
                weekday: fieldsData.type,
            }
            if (!editingTimeline?.id) {
                if (fieldsData?.trainers?.length) {
                    reqData.weekday_trainers = fieldsData.trainers
                }
            } else {
                reqData.weekday_trainers = fieldsData.trainers
            }
            const promise = editingTimeline
                ? this.props.UpdateWorkoutTimeline(editingTimeline.id, reqData)
                : this.props.AddWorkoutTimeline(reqData)
            promise.then(this.getTimeLine).finally(() => {
                this.setState({ requestLoading: false });
            })
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }

    }

    handleChangeTime (field, value) {
        const { fieldsData } = this.state
        const newFieldsData = {
            ...this.state.fieldsData,
        }
        this.disabledMinutesList = []
        if (field === 'startTime') {
            const hour = value.hour()
            if (hour === 0) {
                value.set('hour', 8);
            }
            newFieldsData.endTime = null
        }
        const hour = value.hour()
        if (field === 'endTime') {
            if (hour === fieldsData?.startTime?.hour()) {
                const minute = fieldsData?.startTime?.minute()
                for ( let i = 0; i < minute + 15; i += 5 ) {
                    this.disabledMinutesList.push(i);
                }
            }
            if (hour === 0) {
                value.set('minute', 0);
                for ( let i = 5; i <= 55; i += 5 ) {
                    this.disabledMinutesList.push(i);
                }
            }
            if (hour === 23) {
                for ( let i = 50; i <= 55; i += 5 ) {
                    this.disabledMinutesList.push(i);
                }
            }
        }
        newFieldsData[field] = value
        this.setState({
            fieldsData: newFieldsData,
            errors: {
                ...this.state.errors,
                [field]: false
            }
        })
    }

    editTimeline (item) {
        if (this.state?.editingTimeline?.id === item.id) {
            this.setState({
                editingTimeline: null,
                cancelingTimeline: null,
                fieldsData: {
                    type: undefined,
                    startTime: undefined,
                    endTime: undefined,
                    trainers: [],
                    room: '',
                    reason: ''
                }
            })
        } else {
            this.setState({
                editingTimeline: item,
                cancelingTimeline: null,
                fieldsData: {
                    type: item.weekday,
                    startTime: makeUTCTime(item.start_time),
                    endTime: makeUTCTime(item.end_time),
                    trainers: item?.trainers ? item?.trainers?.map(tr => tr.id) : [],
                    room: item?.room || "",
                    reason: '',
                }
            })
        }

    }

    setCancelingTimeline (item) {
        if (this.state?.cancelingTimeline?.id === item.id) {
            this.setState({
                cancelingTimeline: null,
                editingTimeline: null,
            })
        } else {
            this.setState({
                cancelingTimeline: item,
                editingTimeline: null,
            })
        }
    }

    cancelTimeline () {
        const { fieldsData, cancelingTimeline, week } = this.state;
        const weekday = WEEKDAY_TYPES[cancelingTimeline.weekday];
        const weekAddition = weekday.index + (week * 7)
        const date = makeUTCTime(cancelingTimeline.start_time).day(weekAddition).add(8, 'minutes').toISOString()
        const reqData = {
            weekday_id: cancelingTimeline.id,
            date: date,
            // reason: fieldsData?.reason || '',
            isNotify: !!fieldsData?.isNotify,
        }
        this.props.CancelWorkoutTimeline(reqData).then(this.getTimeLine)
    }

    deleteTimeline (timeline) {
        swal({
            title: "Զգուշացում!",
            text: `Ցանկանում եք ջնջել պարապմունքը?`,
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteWorkoutTimeline(timeline.id).then(this.getTimeLine)
            }
        });
    }

    activateTimeline (cancellationDate) {
        swal({
            title: "Զգուշացում!",
            text: `Ցանկանում եք ակտիվացնել պարապմունքը?`,
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                const reqData = {
                    ids: [cancellationDate?.id],
                    workout_id: this.props.id,
                }
                this.props.ActivateWorkoutTimeline(reqData).then(this.getTimeLine)
            }
        });
    }

    changeWeek (number) {
        this.setState({
            week: number
        })
    }

    disabledHours = () => {
        const hours = [1, 2, 3];
        if (this.state.fieldsData?.startTime) {
            const startHour = moment(this.state.fieldsData.startTime).hour()
            // console.log(startHour);
            for ( let i = 1; i < startHour; i++ ) {
                hours.push(i);
            }
        }
        return hours;
    }

    disabledMinutes () {
        return this.disabledMinutesList;
    }

    getTrainersView = () => {
        const { groupWorkoutById } = this.props;
        const { hoveredWeekDayTrainers } = this.state;
        const trainerView = [];
        const trainers = groupWorkoutById?.workout_trainers;
        const count = trainers?.length
        if (trainers?.length) {
            for ( let i = 0; i < 5 && i < count; i++ ) {
                trainerView.push(<img
                    className={`trainer ${hoveredWeekDayTrainers?.includes(trainers[i]?.id) ? "hovered" : ""}`}
                    key={i}
                    style={{ zIndex: i, left: i * 35 }}
                    src={trainers[i]?.image_url} alt="trainer"/>)
            }
        }
        if (count > 5) {
            trainerView.push(
                <div className={'trainer trainers-wrapper-count'} style={{ zIndex: 6, left: 140 }}>
                    <span>+{count - 5}</span>
                </div>
            )
        }
        return <div className={'trainers'}
                    style={{ width: Math.min(trainers.length, 5) * 35 + 10 }}>  {trainerView}</div>;
    }

    render () {
        const {
            workoutTimeLine,
            fieldsData,
            errors,
            requestLoading,
            editingTimeline,
            cancelingTimeline,
            week
        } = this.state;
        const { groupWorkoutById } = this.props;

        const trainersOptions = groupWorkoutById?.workout_trainers?.map(tr => {
            return {
                id: tr.id,
                name: tr.name,
                imageUrl: tr?.image_url,
            }
        })

        const time = moment().format("HH:mm")
        return <PageWrapper pageTitle={'Դասացուցակ'}>
            <div className={'workout-timeline-page'}>
                {groupWorkoutById && <div className={'info'}>
                    <div className={'workout-info'}>
                        <img className={'workout-logo'} src={groupWorkoutById?.logo_url}/>
                        <span className={'workout-title'}>
                        {groupWorkoutById?.translations?.find(tr => tr.lang = 'hy')?.title}
                    </span>
                    </div>
                    <div className={'trainers-wrapper'}>
                        <span className={'title'}> Մարզիչներ՝ </span>
                        {this.getTrainersView()}
                    </div>
                </div>}
                <div className={'workout-timeline'}>
                    {!!week && <MaterialIcon icon="expand_less"
                                             className={"week_btn prev"}
                                             onClick={this.changeWeek.bind(this, 0)}/>}
                    {!week && <MaterialIcon icon="expand_more"
                                            className={"week_btn next"}
                                            onClick={this.changeWeek.bind(this, 1)}/>}

                    {Object.values(WEEKDAY_TYPES).map(weekday => {
                        const weekAddition = weekday.index + (week * 7)

                        const currentDay = new Date().getDay() === weekAddition
                        return <div key={weekday.key} className={'weekday-item'}>
                            <div className={'left-block'}>
                                <span className={'weekday'}>{weekday.shortName}</span>
                                <span className={`day ${currentDay ? 'current-day' : ""}`}>
                                    {moment().day(weekAddition).format("DD")}
                                </span>
                            </div>
                            <div className={'timeline-items'}>
                                {workoutTimeLine?.[weekday.key]?.map((item, index, arr) => {
                                    const endTimeWeekAddition = weekAddition + (makeUTCTime(item.end_time).hour() === 0 ? 1 : 0)

                                    const now = currentDay && compareDates(time, localTime(item?.start_time)) && compareDates(localTime(item?.end_time), time)
                                    const editing = editingTimeline?.id === item.id || cancelingTimeline?.id === item.id;
                                    const cancellationDate = item?.workout?.workout_cancellations?.find(c => {

                                        return makeUTCTime(item.start_time).day(weekAddition).valueOf()
                                            < moment(c.date).valueOf() && moment(c.date).valueOf() <
                                            makeUTCTime(item.end_time).day(endTimeWeekAddition).valueOf()
                                    })
                                    const canceled = !!cancellationDate
                                    return <div key={item.id}
                                                onMouseEnter={this.onMouseEnter.bind(this, item.trainers)}
                                                onMouseLeave={this.onMouseLeave.bind(this)}
                                                className={`timeline-item ${now ? 'now' : ''} ${canceled ? 'canceled' : ''} ${editing ? 'editing' : ''} `}>
                                        {localTime(item?.start_time)} - {localTime(item?.end_time)}
                                        {
                                            index + 1 !== arr.length && <span className={'border-left'}/>
                                        }
                                        <div className={'actions'}>
                                            {!canceled && <Tooltip placement="bottom" title={'Չեղարկել պարապմունքը'}>
                                                <div className={'action-button cancel'}
                                                     onClick={this.setCancelingTimeline.bind(this, item)}>
                                                    <MaterialIcon icon="event_busy"/>
                                                </div>
                                            </Tooltip>}
                                            <Tooltip placement="bottom" title={'Փոփոխել պարապմունքը'}>
                                                <div className={`action-button edit`}
                                                     onClick={this.editTimeline.bind(this, item)}>
                                                    <MaterialIcon icon="edit"/>
                                                </div>
                                            </Tooltip>
                                            {canceled && <Tooltip placement="bottom" title={'Ակտիվացնել պարապմունքը'}>
                                                <div className={'action-button activate'}
                                                     onClick={this.activateTimeline.bind(this, cancellationDate)}>
                                                    <MaterialIcon icon="event_repeat"/>
                                                </div>
                                            </Tooltip>}
                                            <Tooltip placement="bottom" title={'Ջնջել պարապմունքը'}>
                                                <div className={'action-button delete'}
                                                     onClick={this.deleteTimeline.bind(this, item)}>
                                                    <MaterialIcon icon="delete"/>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>

                {!cancelingTimeline ?
                    <div className={'timeline-add-section'}>
                        <h3>{editingTimeline ? "Փոփոխել պարապմունքը" : "Ավելացնել Դասացուցակ"}</h3>
                        <div className={'inputs-wrapper'}>
                            <InputGroup inputType="selectCustom"
                                        label="Շաբաթվա օր"
                                        placeholder="Շաբաթվա օր"
                                        name="type"
                                        showSearch={false}
                                        value={fieldsData?.type}
                                        error={errors?.type}
                                        required={true}
                                        onChange={this.getInputValues}
                                        options={timelineTypeOptions}/>
                            <InputGroup inputType={'wrapper'}
                                        required={true}
                                        label={'Սկսվելու ժամ'}
                                        error={errors.startTime}>
                                <TimePicker
                                    value={fieldsData.startTime}
                                    placeholder={"Սկսվելու ժամ"}
                                    minuteStep={5}
                                    disabledHours={() => [0, 1, 2, 3]}
                                    disabledMinutes={() => fieldsData?.startTime?.hour() === 23 ? [50, 55] : []}
                                    format="HH:mm"
                                    // defaultValue={moment('08:00', 'HH:mm')}
                                    allowClear={false}
                                    showNow={false}
                                    hideDisabledOptions={true}
                                    className={errors.startTime && 'error'}
                                    onSelect={(value) =>
                                        this.handleChangeTime('startTime', value)}/>
                            </InputGroup>
                            <InputGroup inputType={'wrapper'}
                                        required={true}
                                        label={'Ավարտվելու ժամ'}
                                        error={errors.endTime}>
                                <TimePicker
                                    value={fieldsData.endTime}
                                    placeholder={"Ավարտվելու ժամ"}
                                    disabled={!fieldsData.startTime}
                                    disabledHours={this.disabledHours}
                                    disabledMinutes={this.disabledMinutes}
                                    hideDisabledOptions={true}
                                    minuteStep={5}
                                    format="HH:mm"
                                    allowClear={false}
                                    showNow={false}
                                    className={errors.endTime && 'error'}
                                    onSelect={(value) =>
                                        this.handleChangeTime('endTime', value)}/>
                            </InputGroup>
                            <InputGroup inputType="input"
                                        label="Սրահ"
                                        placeholder="Սրահ"
                                        name="room"
                                        value={fieldsData?.room}
                                        error={errors?.room}
                                // required={true}
                                        onChange={this.getInputValues}/>
                            <div className={'trainers-wrapper'}>
                                <InputGroup inputType="selectCustom"
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            label="Մարզիչներ"
                                            placeholder="Մարզիչներ"
                                            name="trainers"
                                            showSearch={false}
                                            value={fieldsData?.trainers}
                                            error={errors?.trainers}
                                            onChange={this.getInputValues}
                                            options={trainersOptions}/>
                            </div>
                        </div>
                        <div className="flex-wrapper-right">
                            {editingTimeline && <LinkButton title={'Չփոփոխել'} style={{ marginRight: "10px" }}
                                                            cb={this.editTimeline.bind(this, editingTimeline)}/>}
                            <LinkButton title={editingTimeline ? 'Պահպանել' : 'Ավելացնել'}
                                        loading={requestLoading}
                                        disabled={!fieldsData.type || !fieldsData.startTime || !fieldsData.endTime}
                                        cb={this.addUpdateTimeline}/>
                        </div>
                    </div>
                    :
                    <div className={'timeline-cancel-section'}>
                        <div className={'timeline-cancel-top'}>
                            <h3>Չեղարկել {localTime(cancelingTimeline?.start_time)} - {localTime(cancelingTimeline?.end_time)} պարապմունքը</h3>
                            <div className="notify-wrapper">
                                <label>{fieldsData.isNotify ? "Ուղարկել ծանուցում " : "Չուղարկել ծանուցում "}</label>
                                <Switch checked={fieldsData.isNotify}
                                        onChange={(checked) => this.getInputValues({
                                            name: "isNotify",
                                            value: checked,
                                        })}/>
                            </div>
                        </div>
                        {/*<div className={'inputs-wrapper'}>
                            <InputGroup inputType="textarea"
                                        label="Պատճառ"
                                        placeholder="Պատճառ"
                                        name="reason"
                                        showSearch={false}
                                        required={true}
                                        value={fieldsData?.reason}
                                        onChange={this.getInputValues}/>
                        </div>*/}
                        <div className="flex-wrapper-right">
                            <LinkButton title={'Չփոփոխել'}
                                        style={{ marginRight: "10px" }}
                                        cb={this.setCancelingTimeline.bind(this, cancelingTimeline)}/>
                            <LinkButton title={'Չեղարկել'}
                                // disabled={!fieldsData.reason}
                                        loading={requestLoading}
                                        cb={this.cancelTimeline}/>
                        </div>
                    </div>}
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps =
    {
        GetGroupWorkoutById,
        GetGroupWorkoutTimeline,
        clearGroupWorkoutData,
        AddWorkoutTimeline,
        UpdateWorkoutTimeline,
        DeleteWorkoutTimeline,
        CancelWorkoutTimeline,
        ActivateWorkoutTimeline
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutTimeLine);
