// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, DatePicker, Tree } from "antd";

//import styles
import '../../assets/styles/containerStyles/appended-product-section.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    arraysEquals,
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationsInitialValues,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    clearGroupWorkoutData,
    CreateGroupWorkout,
    GetGroupWorkoutById,
    UpdateGroupWorkoutById,
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { history } from "../../configs/history";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MAIN_LANGUAGE_TYPES, TYPE_CONST, WORKOUT_TYPE_CONST } from "../../constants/constsTypes";
import { transformImage } from "../../utils/transformImage";


class AddEditGroupWorkout extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                image: null,
                is_hidden: false,
                logo: null,
                workout_sections: [],
                workout_trainers: [],
                workout_types: [],
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
            imageModalOpen: false,
            logoModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleImageModal = toggleStateField.bind(this, 'imageModalOpen');
        this.toggleLogoModal = toggleStateField.bind(this, 'logoModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.addEditGroupWorkout = this.addEditGroupWorkout.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;

        if (isEditing) {
            await this.props.GetGroupWorkoutById(this.props.id);
            const editingData = this.props.groupWorkoutById
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData?.translations, ['title', 'description']),
                    image: editingData?.image_object_name ?
                        transformImage(editingData.image_object_name, editingData.image_url)
                        : null,
                    logo: editingData?.logo_object_name ?
                        transformImage(editingData.logo_object_name, editingData.logo_url)
                        : null,
                    workout_sections: editingData?.workout_sections?.map(item => {
                        return item?.id
                    }) || [],
                    workout_trainers: editingData?.workout_trainers?.map(item => {
                        return item?.id
                    }) || [],
                    workout_types: editingData?.workout_types || [],
                    is_hidden: editingData?.is_hidden,
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }

    componentWillUnmount () {
        this.props.clearGroupWorkoutData()
    }

    getMedia (item, mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                [item]: mediaArray[0],
            },
            errors: {
                ...errors,
                [item]: false
            }
        })
    }


    async addEditGroupWorkout () {
        await this.setState({
            requestLoading: true
        });
        const { translations, image, logo,workout_sections,workout_trainers,workout_types, is_hidden} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!image) {
            errors.image = true;
            result = false;
        }
        if (!logo) {
            errors.logo = true;
            result = false;
        }
        if(workout_types?.length < 1){
            errors.types = true;
            result = false
        }
        // if(workout_trainers?.length < 1){
        //     errors.trainers = true;
        //     result = false
        // }
        if(workout_sections?.length < 1){
            errors.sections = true;
            result = false
        }
        if (result) {
            if (!this.state.isEditing) {
                let reqData = this.getPostReqData(this.state.fieldsData);
                this.props.CreateGroupWorkout(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                let reqData = this.getPostReqData(this.state.fieldsData);
                reqData.is_hidden=is_hidden
                if (Object.keys(reqData).length) {
                    this.props.UpdateGroupWorkoutById(this.props.id, reqData)
                        .finally(() => {
                            this.setState({ requestLoading: false });
                        });
                } else {
                    await this.setState({ requestLoading: false });
                    history.push('/groupWorkout')
                }

            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getMultiSelectData = (checkedKeys, name) => {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            const updatedValues = checkedKeys?.length ? checkedKeys : []
            arraysEquals(this.currentData?.[name], checkedKeys)
                ? this.updatedDataMap.delete(name)
                : this.updatedDataMap.set(name, updatedValues);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };

    treeData = (data, children) => {
        const treeData = [];
        data.forEach(item => {
            const treeItem = {
                title: item.title || item.name,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        })
        return treeData;
    };


    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            imageModalOpen,
            isEditing,
            errorsTabs,
            logoModalOpen,
        } = this.state;

        const typeOptions = Object.values(WORKOUT_TYPE_CONST).map(type => {
            return {
                id: type || '',
                title: type || ' '
            }
        });

        const { workoutSectionsList, trainerList } = this.props
        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Խմբային մարզումները' : 'Փոփոխել Խմբային մարզումները'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.is_hidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.is_hidden}
                            onChange={(checked) => this.getInputValues({
                                name: "is_hidden",
                                value: !checked,
                                haveChanges: this.currentData?.is_hidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                cb={this.toggleImageModal}
                    />
                </div>
                {fieldsData?.image && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.image}
                    />
                </div>}

                <div className="top-side">
                    <label>Լոգո <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.logo ? 'media-select-error' : ''}`}
                                cb={this.toggleLogoModal}
                    />
                </div>
                {fieldsData?.logo && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.logo}
                    />
                </div>}

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="textarea"
                            type="text"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            required={true}
                            value={currentData.description}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <div className="tree-item">
                    <label className={`${errors.sections ? 'workout-err' : ''}`}>Մարզումների տեսակները
                        <span className={'required-badge'}>*</span>
                    </label>
                    <Tree
                        checkable
                        checkedKeys={fieldsData.workout_sections}
                        onCheck={(keys) => this.getMultiSelectData(keys, 'workout_sections')}
                        showLine={true}
                        treeData={this.treeData(workoutSectionsList, "values")}
                    />
                </div>


                <div className="tree-item">
                    <label className={`${errors.trainers ? 'workout-err' : ''}`}>Մարզիչներ
                    </label>
                    <Tree
                        checkable
                        checkedKeys={fieldsData.workout_trainers}
                        onCheck={(keys) => this.getMultiSelectData(keys, 'workout_trainers')}
                        showLine={true}
                        treeData={this.treeData(trainerList, "values")}
                    />
                </div>

                <div className="tree-item">
                    <label className={`${errors.types ? 'workout-err' : ''}`}>Տեսակներ
                        <span className={'required-badge'}>*</span>
                    </label>
                    <Tree
                        checkable
                        checkedKeys={fieldsData.workout_types}
                        onCheck={(keys) => this.getMultiSelectData(keys, 'workout_types')}
                        showLine={true}
                        treeData={this.treeData(typeOptions, "values")}
                    />
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                        // loading={requestLoading}
                                cb={this.addEditGroupWorkout}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!imageModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, 'image')}
                closeModal={this.toggleImageModal}
            />
            <MediaSelectorModal
                isOpen={!!logoModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, 'logo')}
                closeModal={this.toggleLogoModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateGroupWorkout,
    GetGroupWorkoutById,
    UpdateGroupWorkoutById,
    clearGroupWorkoutData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditGroupWorkout)
