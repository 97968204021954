// Import packages
import React, {Component, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import moment from "moment";
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
// import html2canvas from "html2canvas-pro";
import {toPng, toJpeg,} from 'html-to-image';

//import assets
import "../../assets/styles/dataDisplay/generateTimelineImage.scss"

// Import components

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetAllGroupWorkoutTimelines,
} from "../../redux/actions";
import {WEEKDAY_TYPES, WORKOUT_TYPE_CONST} from "../../constants/constsTypes";
import {LinkButton} from "../../components/buttons/buttons";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {localTime, makeUTCTime} from "../../utils/dateHelper";

const timelineTypeOptions = Object.values(WEEKDAY_TYPES).map(weekday => ({
    id: weekday.key,
    name: weekday.name
}));

const trainerIds = ['8d8ade1c-77a8-40f1-8e99-34859b55c689']

function GenerateTimeLineImage(props) {
    const [workoutTimeLine, setWorkoutTimeLine] = useState(null);
    const [weekday, setWeekday] = useState(WEEKDAY_TYPES.Monday);
    const [loading, setLoading] = useState(false);
    const contentRef = useRef();
    useEffect(() => {
        getTimeLine()
    }, []);

    useEffect(() => {
        if (loading) {
            handleDownloadTimelineImage()
        }
    }, [loading]);

    async function getTimeLine() {
        const timeline = await props.GetAllGroupWorkoutTimelines();
        const workoutTimeLine = {}
        Object.keys(WEEKDAY_TYPES).forEach(key => {
            workoutTimeLine[key] = timeline.filter(item => item.weekday === key && !item?.workout?.is_hidden)
        })
        console.log('workoutTimeLine', workoutTimeLine);
        setWorkoutTimeLine(workoutTimeLine)
    }

    function getWeekDayValue({value}) {
        setWeekday(WEEKDAY_TYPES[value])
    }

    function handleDownloadPdf() {
        const element = contentRef.current;
        const options = {
            margin: 0,
            filename: 'my-document.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {
                windowWidth: 1500,
                windowHeight: 1000,
                x: 1,
                width: 739,
                scale: 1,
                backgroundColor: "#000000",
            },
            jsPDF: {
                format: 'letter',
                compress: false,
                compressPdf: false,
                unit: 'mm',
                // hotfixes: ['px_scaling'],
                orientation: 'portrait'
            }
        };

        html2pdf().set(options).from(element).save();
    }

    function downloadImage() {
        setLoading(true);
    }

    async function handleDownloadTimelineImage() {
        // setLoading(true)
        // const canvas = await html2canvas(contentRef.current, {
        //     windowWidth: 1500,
        //     windowHeight: 1000,
        //     backgroundColor: "#000000",
        //     x: 1,
        //     width: 739,
        // });
        //
        // const canvasWidth = canvas.width;
        // const canvasHeight = canvas.height;
        //
        // console.log('can - width ', canvasWidth)
        // console.log('can - Height ', canvasHeight)
        //
        // canvas.toBlob((blob) => {
        //     const link = document.createElement('a');
        //     link.href = URL.createObjectURL(blob);
        //     link.download = `${weekday.key}-${moment().format("DD-MM-YYYY")}.jpg`;
        //     link.click();
        //
        //     // Clean up the URL object
        //     URL.revokeObjectURL(link.href);
        //     setTimeout(() => {
        //         setLoading(false)
        //     }, 500)
        // }, 'image/jpg', 0.99);

        toJpeg(contentRef.current, {quality: 0.99}).then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = `${weekday.key}-${moment().format("DD-MM-YYYY")}.jpg`;
            link.href = dataUrl;
            link.click();
            setTimeout(() => {
                setLoading(false)
            }, 500)
        })
    }

    function renderTimeLineBlock(list, badge) {
        let items = [];
        list?.map((item, index, arr) => {
            const weekAddition = weekday.index;
            const endTimeWeekAddition = weekAddition + (makeUTCTime(item.end_time).hour() === 0 ? 1 : 0)
            const cancellationDate = item?.workout?.workout_cancellations?.find(c => {
                return makeUTCTime(item.start_time).day(weekAddition).valueOf()
                    < moment(c.date).valueOf() && moment(c.date).valueOf() <
                    makeUTCTime(item.end_time).day(endTimeWeekAddition).valueOf()
            })
            if (!cancellationDate) {
                items.push(<div className={'row'} key={item.id}>
                    <span className={'time'}>{localTime(item?.start_time)}</span>
                    <span className={`workout ${item?.workout?.title?.includes('Tennis') ? 'tennis' : ''}`}>
                        {item?.workout?.title}
                    </span>
                    <span className={'trainer'}>{
                        item?.trainers?.length ?
                            `(${item?.trainers?.map(t => {
                                const [firstName, lastName] = t?.name?.split(' ');
                                let name = `${firstName}${trainerIds?.includes(t.id) ? ` ${lastName?.slice(0, 3)}` : ''}`
                                return name
                            }).join('/')})`
                            : ''}</span>
                    <span className={'hall'}>{item?.room || ""}</span>
                </div>)
            }
        })

        if (badge && items?.length) {
            items.unshift(<div className={`badge ${badge}`}><span>{badge}</span></div>)
        }
        return items
    }

    const adultTimeline = workoutTimeLine?.[weekday.key]?.filter(item => item?.workout?.workout_types?.includes(WORKOUT_TYPE_CONST.ADULT));
    const aquaTimeline = workoutTimeLine?.[weekday.key]?.filter(item => item?.workout?.workout_types?.includes(WORKOUT_TYPE_CONST.AQUA));
    const kidsTimeline = workoutTimeLine?.[weekday.key]?.filter(item => item?.workout?.workout_types?.includes(WORKOUT_TYPE_CONST.CHILDREN));

    return <div className={'generate-timeline-wrapper'}>
        <div className={'visible-part'}>
            <div className={'title'}> Ընտրել շաբաթվա օր</div>
            <InputGroup inputType="selectCustom"
                // label="Շաբաթվա օր"
                        placeholder="Ընտրել շաբաթվա օր"
                        name="weekDay"
                        showSearch={false}
                // allowClear={true}
                        value={weekday.key}
                        required={true}
                        onChange={getWeekDayValue}
                        options={timelineTypeOptions}/>
            <LinkButton title={"Download timeline image"}
                        disabled={!workoutTimeLine}
                        loadingWithTitle={true}
                        loading={loading}
                        cb={downloadImage}
                // cb={handleDownloadPdf}
            />
        </div>
        <div className={`content-wrapper ${loading? 'visible': ''}`} ref={contentRef}>
            <div className={'header'}>
                <div className={'title'}>Group Workout | Խմբային Մարզումներ</div>
                <div className={'sub-title'}>{weekday.key} | {weekday.name}</div>
            </div>
            <div className={'pdf-body'}>
                <div className={'th'}>
                    <div className={'th-info'}>
                        <span>Ժամ</span>
                        <span>Մարզում</span>
                    </div>
                    <div className={'th-info'}>
                        <span>Մարզիչ</span>
                        <span>Սրահ</span>
                    </div>
                </div>
                {renderTimeLineBlock(adultTimeline)}

                {renderTimeLineBlock(aquaTimeline, 'aqua')}

                {renderTimeLineBlock(kidsTimeline, 'kids')}

            </div>
        </div>
    </div>

}

const mapDispatchToProps = {
    GetAllGroupWorkoutTimelines,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateTimeLineImage);
