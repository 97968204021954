// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

//import styles
import '../../assets/styles/containerStyles/faq-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getPostReqData,
    getTranslatableInputValues,
    getTranslationsInitialValues,
    initTranslations,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateFAQ, UpdateFAQById, GetFAQById } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { MAIN_LANGUAGE_TYPES, TYPE_CONST } from "../../constants/constsTypes";
import { Editor } from "../../components/uiElements/Editor";
import { MaterialIcon } from "../../components/utils/Icon";
import { ColorPicker } from "../../components/uiElements/ColorPicker";

class AddEditFAQ extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'answer',
            'question',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                position: 0,
                is_hidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,

        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.addEditFAQ = this.addEditFAQ.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;
        if (isEditing) {
            await this.props.GetFAQById(this.props.id);
            const editingData = this.props.faqById;
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations,[ 'answer','question',]),
                    position: editingData?.position,
                    is_hidden: editingData?.is_hidden,
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }


    async addEditFAQ () {
        await this.setState({
            requestLoading: true
        });
        const {
            translations,
        } = this.state.fieldsData;
        const translationRequiredFields = ['answer', 'question']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (result) {
            if (!this.state.isEditing) {
                let { faqList } = this.props;

                let position = undefined
                let num = []
                if(faqList?.length){
                    faqList?.forEach(item => {
                        num.push(item?.position)
                        return position = Math.max(...num)
                    })
                }else {
                    position = 0
                }

                let reqData = this.state.fieldsData;
                reqData.position = position + 1
                this.props.CreateFAQ(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                let reqData = this.state.fieldsData;
                delete reqData.position;
                this.props.UpdateFAQById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            requestLoading,
            isEditing,
            errorsTabs,
        } = this.state;

        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Հարց-Պատասխան' : 'Փոփոխել Հարց-Պատասխանը'}>
            <section className="faq-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.is_hidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.is_hidden}
                            onChange={(checked) => this.getInputValues({
                                name: "is_hidden",
                                value: !checked,
                                haveChanges: this.currentData?.is_hidden !== !checked
                            })}/>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="faq-wrapper">
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Հարց"
                                placeholder="Հարց"
                                name="question"
                                value={currentData.question}
                                initValue={initData.question}
                                error={errors['question' + languageTab]}
                                maxLength={2000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Պատասխան"
                                placeholder="Պատասխան"
                                resizable={true}
                                name="answer"
                                value={currentData.answer}
                                initValue={initData.answer}
                                error={errors['answer' + languageTab]}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditFAQ}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateFAQ,
    UpdateFAQById,
    GetFAQById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFAQ)
