import React from "react";
import "../../assets/styles/dataDisplay/nestableItem.scss";

import {MaterialIcon} from "../utils/Icon";
import {Image} from "../utils/Image";
import {LinkButton} from "../buttons/buttons";
import { iconMenu } from "../../assets/images";


export const NestableItem = ({item}) => {
    const {withImage, path, customIcon, description, title, link, detailsLink, isHidden = null, color, deleteCb, } = item;

    const showIsHidden = isHidden !== null;
    return <div className={`nestable-list-item ${withImage ? ' largeItem ' : ''}`}>
        <div className="nestable-item-inner">
            <div className="moving-icon">
                <img src={customIcon? customIcon: iconMenu} alt="menu"/>
            </div>
            {withImage &&
            <div className="item-image">
                <Image path={path}/>
            </div>}
            {title && description ? <div className="info-wrapper-flex">
                <div className="item-title">
                    <span>{title}</span>
                </div>
                <div className="item-description">
                    <span>{description}</span>
                </div>
            </div> : <>
                {title &&
                <div className="item-title">
                    <span>{title}</span>
                </div>}
                {description &&
                <div className="item-description">
                    <span>{description}</span>
                </div>}

            </>}

            {showIsHidden &&
            <div className="status">
                <span>{isHidden ? "Ոչ ակտիվ " : "Ակտիվ "}</span>
            </div>}
            {color &&
            <div className={'color'} style={{backgroundColor: color}}/>}
            <div className="action-buttons">
                {detailsLink && <LinkButton link={detailsLink}
                                            className={'details-button'}
                                            title={<MaterialIcon icon="feed"/>}/>}
                {link && <LinkButton link={link}
                                     className={'edit-button'}
                                     title={<MaterialIcon icon="edit"/>}/>}
                <LinkButton className={'bg-red'}
                            cb={deleteCb}
                            disabled={!deleteCb}
                            title={<MaterialIcon icon="delete"/>}/>
            </div>
        </div>
    </div>
};
