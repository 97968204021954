export const GROUP_WORKOUT_CONSTS = {
    GET_GROUP_WORKOUTS: "GET_GROUP_WORKOUTS",
    CREATE_GROUP_WORKOUT: "CREATE_GROUP_WORKOUT",
    GET_GROUP_WORKOUT_BY_ID: "GET_GROUP_WORKOUT_BY_ID",
    UPDATE_GROUP_WORKOUT_BY_ID: "UPDATE_GROUP_WORKOUT_BY_ID",
    DELETE_GROUP_WORKOUT_BY_ID: "DELETE_GROUP_WORKOUT_BY_ID",
    CLEAR_GROUP_WORKOUT_DATA: "CLEAR_GROUP_WORKOUT_DATA",

    GET_WORKOUT_TIMELINE: "GET_WORKOUT_TIMELINE",
};
