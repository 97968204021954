import {request, _urlArticle} from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {ARTICLE_CONSTS, UTIL_CONSTS} from "../constants";

export const GetArticles = ({reset = true,offset = 0, limit= 10}) => {
    const requestData = {
        url: `${_urlArticle}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({ type:UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: ARTICLE_CONSTS.GET_ARTICLES,
                    payload:{
                        data:data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            })
    }
};


export const GetArticleById = id => {
    const requestData = {
        url: `${_urlArticle}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: ARTICLE_CONSTS.GET_ARTICLE_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};
export const DeleteArticleById = id => {
    const requestData = {
        url: `${_urlArticle}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ARTICLE_CONSTS.DELETE_ARTICLE_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateArticle = data => {
    const requestData = {
        url: _urlArticle,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ARTICLE_CONSTS.CREATE_ARTICLE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Բլոգը հաջողությամբ ստեղծվեց!"
                })
                history.push('/article')
            })
            .catch(() => {
            })
    }
};

export const UpdateArticleById = (id, data) => {
    const requestData = {
        url: `${_urlArticle}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:ARTICLE_CONSTS.UPDATE_ARTICLE_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Բլոգը հաջողությամբ փոփոխվեց!"
                })
                history.push('/article')
            })
            .catch(() => {
            })
    }
};