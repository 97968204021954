// Import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import Breadcrumbs from "../uiElements/BreadCrumbs";
import {SetRoutes} from "../../redux/actions";

// Import components


function PageWrapper(props) {

    const {pageTitle} = props
    const location = useLocation()

    useEffect(() => {
        // console.log("pageTitle", pageTitle)
        // console.log("routesList", props.routesList)
        if (pageTitle) {

            // console.log("routesList.length", props.routesList.length)
            if(!props.routesList.length) {
                props.SetRoutes([{
                    path: location.pathname,
                    name: pageTitle
                }])
            }else {
                const newList = [...props.routesList]
                newList && newList.length && (newList[newList.length - 1].name = pageTitle)
                props.SetRoutes(newList)
            }

        }
    },[pageTitle])


    const {leftSideMenuOpen, children, mediaPageTitle} = props;

    // console.log("props.routesList", props.routesList)

    return <div className={`admin-inner-component ${leftSideMenuOpen ? "size-sm" : ""}`}>
        <section className="content-wrapper">
            {/*{pageTitle && <h1 className="page-title">{pageTitle}</h1>}*/}

            <div className={'main-content'}>
                {!location.pathname.includes("media") && <Breadcrumbs crumbs={props.routesList}/>}
                {mediaPageTitle}
                {children}
            </div>
        </section>
    </div>
}


const mapDispatchToProps = {
    SetRoutes
};

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper)
