import { AUTH_CONSTS, STATIC_TEXT_CONSTS } from "../constants";

export const initialState = {
    staticTextsList: {}
};

export default (state = initialState, action) => {
    // console.log(action.payload);
    switch (action.type) {
        case STATIC_TEXT_CONSTS.GET_STATIC_TEXTS:
            return {
                staticTextsList: action.payload
            };
        case STATIC_TEXT_CONSTS.UPDATE_STATIC_TEXTS:
            const changeLanguage = state.staticTextsList[action.payload.code];
            return {
                staticTextsList: {
                    ...state.staticTextsList,
                    [action.payload.code]: {
                        ...changeLanguage,
                        [action.payload.key]: action.payload.value
                    }
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
