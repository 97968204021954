// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact-rule-resource.scss";

// Import components
import PageWrapper from '../components/pageContentViews/pageWrapper'
import { LinkButton } from "../components/buttons/buttons";

// Import utils
import { mapStateToProps } from "../redux/mapStateToProps";
import { GetResource, UpdateResourceData } from "../redux/actions";
import {
    getInputValues, toggleStateField,
} from "../utils/helperFunctions";
import MediaSelectorModal from "../components/media/MediaSelectorModal";
import { ACCEPT_IMAGE_TYPES, ACCEPT_VIDEO_TYPES } from "../constants/acceptedTypes";
import { MAIN_LANGUAGE_TYPES } from "../constants/constsTypes";
import { transformImage } from "../utils/transformImage";
import { MediaFileCard } from "../components/media/MediaFileCard";
import AppVersion from "./AppVersion";
import {Switch} from "antd";
import {InputGroup} from "../components/uiElements/inputGroup";


export class Resource extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [];
        this.state = {
            fieldsData: {
                home_image: "",
                home_image_responsive_name: "",
                home_text_is_hidden: false,
                thumbnail: "",
                home_video: '',
            },
            appSettingsOpen: false,
            mediaModalOpen: false,
            modalAllowedTypes: '',
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: MAIN_LANGUAGE_TYPES.en,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.getInputValues = getInputValues.bind(this);
        this.toggleAppSettingsOpen = toggleStateField.bind(this, 'appSettingsOpen');
        this.toggleMediaModal = this.toggleMediaModal.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateResource = this.updateResource.bind(this);
    }

    async componentDidMount () {
        !this.props?.resourceData?.id && await this.props.GetResource();
        const { resourceData } = this.props;
        console.log(resourceData,'resourceData')

        resourceData && this.setState({
            fieldsData: {
                home_image: resourceData?.home_image_file_object_name ?
                    transformImage(resourceData.home_image_file_object_name, resourceData.home_image_url)
                    : null,
                home_image_responsive_name: resourceData?.home_image_file_responsive_object_name ?
                    transformImage(resourceData.home_image_file_responsive_object_name, resourceData.home_image_responsive_url)
                    : null,
                home_text_is_hidden: resourceData?.home_text_is_hidden,
                home_video: resourceData?.home_video_file_object_name ?
                    transformImage(resourceData.home_video_file_object_name, resourceData.home_video_url)
                    : null,
                thumbnail: resourceData?.thumbnail_file_object_name ?
                    transformImage(resourceData.thumbnail_file_object_name, resourceData.thumbnail_url)
                    : null,
            },
        });
    }

    updateResource () {
        this.setState({
            requestLoading: true
        });
        const { home_image, thumbnail, home_video,home_image_responsive_name,home_text_is_hidden } = this.state.fieldsData;
        console.log(home_text_is_hidden,'home_text_is_hidden')
        let result = true
        const errors = {};
        if (!home_image) {
            errors.home_image = true;
            result = false;
        }
        if (!home_image_responsive_name) {
            errors.home_image_responsive_name = true;
            result = false;
        }

        if (!thumbnail) {
            errors.thumbnail = true;
            result = false;
        }

        if (!home_video) {
            errors.home_video = true;
            result = false;
        }

        if (result) {
            let reqData = {
                home_image_file_object_name: home_image?.key,
                home_image_file_responsive_object_name: home_image_responsive_name?.key,
                thumbnail_file_object_name: thumbnail?.key,
                home_video_file_object_name: home_video?.key,
                home_text_is_hidden:home_text_is_hidden
            }
            console.log('reqData', reqData);
            this.props.UpdateResourceData(reqData).then(() => {
                this.setState({
                    requestLoading: false,
                    errors: errors,
                })
            })
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, modalAllowedTypes } = this.state;
        let newState;
        if (modalAllowedTypes === 'image_thumbnail') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    thumbnail: mediaArray[0],
                },
                errors: {
                    ...errors,
                    thumbnail: false
                }
            }
        }else if (modalAllowedTypes === 'image') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    home_image: mediaArray[0],
                },
                errors: {
                    ...errors,
                    home_image: false
                }
            }
        }else if (modalAllowedTypes === 'image_responsive') {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    home_image_responsive_name: mediaArray[0],
                },
                errors: {
                    ...errors,
                    home_image_responsive_name: false
                }
            }
        } else {
            newState = {
                fieldsData: {
                    ...fieldsData,
                    home_video: mediaArray[0],
                },
                errors: {
                    ...errors,
                    home_video: false
                }
            }
        }
        this.setState(newState)
    }

    toggleMediaModal (type = '') {
        this.setState({
            mediaModalOpen: !this.state.mediaModalOpen,
            modalAllowedTypes: type
        })
    }


    render () {
        const { requestLoading, isAdmin } = this.props;
        const { fieldsData, errors, mediaModalOpen, modalAllowedTypes, appSettingsOpen } = this.state;
        return <PageWrapper pageTitle={'Տվյալներ'}>
            <section className="general-add-edit">
                <div className={'tops-wrapper'}>
                    <div className="top-side">
                        <label>Նկար <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.home_image ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'image')}
                        />
                        {fieldsData?.home_image && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.home_image}/>
                        </div>}
                    </div>
                    <div className="top-side">
                        <label>Մոբայլի Նկար <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.home_image_responsive_name ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'image_responsive')}
                        />
                        {fieldsData?.home_image_responsive_name && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.home_image_responsive_name}/>
                        </div>}
                    </div>

                    <div className="top-side">
                        <label>Վիդեո <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.home_video ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'video')}
                        />
                        {fieldsData?.home_video && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.home_video}/>
                        </div>}
                    </div>

                    <div className="top-side">
                        <label>Վիդեո-Նկար <span className={'required-badge'}>*</span></label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors.thumbnail ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, 'image_thumbnail')}
                        />
                        {fieldsData?.thumbnail && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.thumbnail}/>
                        </div>}
                    </div>
                </div>

                <InputGroup inputType="wrapper"
                            label="Home img text is hidden">
                    <div style={{paddingTop: '5px', display: 'flex', alignItems: "center"}}>
                        <Switch checked={fieldsData.home_text_is_hidden}
                                onChange={(checked) => this.getInputValues({
                                    name: "home_text_is_hidden",
                                    value: checked,
                                    haveChanges: this.currentData?.home_text_is_hidden !== !checked
                                })}/>
                        <label
                            style={{margin: '0 0 0 15px'}}>{fieldsData.home_text_is_hidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    </div>

                </InputGroup>


                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                loading={requestLoading}
                                cb={this.updateResource}/>
                </div>
            </section>
            {isAdmin && <LinkButton title="Show App Settings"
                                    className={appSettingsOpen ? 'bg-grey' : ""}
                                    loading={requestLoading}
                                    style={{marginBottom: '15px'}}
                                    cb={this.toggleAppSettingsOpen}/>}
            {appSettingsOpen && <AppVersion/>}
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={modalAllowedTypes === 'video' ? ACCEPT_VIDEO_TYPES : ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetResource,
    UpdateResourceData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Resource);
