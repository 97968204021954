// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles


// Import components
import PageWrapper from '../../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../../components/media/MediaSelectorModal";
import { InputGroup } from "../../../components/uiElements/inputGroup";
import { LinkButton } from "../../../components/buttons/buttons";
import { MediaFileCard } from "../../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getPostReqData,
    getTranslatableInputValues, getTranslationsInitialValues,
    initTranslations, toggleStateField,
} from "../../../utils/helperFunctions";
import { mapStateToProps } from "../../../redux/mapStateToProps";
import {
    CreateSpaService,
    GetSpaServiceById,
    UpdateSpaServiceById,
} from "../../../redux/actions";
import { validateTranslations } from "../../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../../constants/acceptedTypes";
import { history } from "../../../configs/history";
import {LanguageTabs} from "../../../components/uiElements/Tabs";
import {MAIN_LANGUAGE_TYPES} from "../../../constants/constsTypes";
import {transformImage} from "../../../utils/transformImage";


class AddEditSpaService extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                image:null,
                phone:''
            },
            errors: {},
            today:'',
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
            mediaModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.addEditSpaService = this.addEditSpaService.bind(this);
    }

    async componentDidMount () {
        const { isEditing} = this.state;

        if (isEditing) {
            await this.props.GetSpaServiceById(this.props.id);
            const editingData = this.props.spaServiceById
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations, ['title','description']),
                    image: editingData?.image_object_name ?
                        transformImage(editingData.image_object_name, editingData.image_url )
                        : null,
                    phone:editingData?.phone || ""
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    fieldsData: initStateData,
                });
            }
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                image: mediaArray[0],
            },
            errors: {
                ...errors,
                image: false
            }
        })
    }

    async addEditSpaService () {
        await this.setState({
            requestLoading: true
        });
        const { spa } = this.props;
        const { translations, image} = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!image) {
            errors.image = true;
            result = false;
        }
        if (result) {
            if (!this.state.isEditing) {
                let reqData = this.getPostReqData(this.state.fieldsData);
                reqData.spa_id = spa?.id;
                this.props.CreateSpaService(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                // const trData = getTranslationData(translations);
                // const reqData = this.getPatchReqData(trData);
                let reqData = this.getPostReqData(this.state.fieldsData);
                if (Object.keys(reqData).length) {
                    this.props.UpdateSpaServiceById(this.props.id, reqData)
                        .finally(() => {
                            this.setState({ requestLoading: false });
                        });
                } else {
                    await this.setState({ requestLoading: false });
                    history.push('/service/spa')
                }

            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    handleChangeData(field, value) {
        this.setState({
            filterData: {
                ...this.state.filterData,
                [field]: value
            }
        }, this.searchItems)
    }


    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            isEditing,
            requestLoading,
            errorsTabs,
        } = this.state;
        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Սպա ծառայություն' : 'Փոփոխել Սպա ծառայությունը'}>
            <section className="general-add-edit">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.image && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.image}
                    />
                </div>}

                <InputGroup inputType="input"
                            type="text"
                            label="Հեռախոսահամար"
                            placeholder="Հեռախոսահամար"
                            name="phone"
                            required={false}
                            value={fieldsData?.phone}
                            initValue={this.currentData?.phone}
                            error={errors?.phone}
                            maxLength={100}
                            onChange={this.getInputValues}/>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            required={true}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            onChange={this.getTranslationValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            value={currentData.description}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            maxLength={1000}
                            onChange={this.getTranslationValues}/>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditSpaService}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateSpaService,
    GetSpaServiceById,
    UpdateSpaServiceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSpaService)
