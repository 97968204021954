// import React from "react";
import {history} from "../../configs/history";
import {store} from "../store";


//import utils
// import {notificationSound} from "../../utils/notificationSound";
import {ACTION_TYPES} from "../../socket/constants";
import {checkBrowserTabNotVisible} from "../../utils/checkBrowserTabNotVisible";
// import {GetNotSeenOrdersCount} from "./orderActions";


// export const newNotification = (notification) => {
//     console.log('new Notif', notification);
//     let scope = store.getState().auth.scope;
//     let path = history?.location?.pathname;
//     let inNotificationPage = path && path.startsWith('/divination');
//
//     store.dispatch({
//         type: ACTION_TYPES.NEW_NOTIFICATION,
//         payload: notification
//     });
//
//     GetNotSeenOrdersCount()(store.dispatch);
//     if (!inNotificationPage || checkBrowserTabNotVisible()) {
//         notificationSound.play()
//
//         // GetNonOpenedNotificationsCount();
//     } else {
//         // SetAllNotificationsAsOpened();
//     }
// };
export const newOrder = (order) => {
    // console.log('new Notif', order);
    let path = history?.location?.pathname;
    let inNotificationPage = path && path.startsWith('/orders');

    store.dispatch({
        type: ACTION_TYPES.NEW_ORDER,
        payload: order
    });

    // GetNotSeenOrdersCount()(store.dispatch);
    // if (!inNotificationPage || checkBrowserTabNotVisible()) {
    //     notificationSound.play()
    // }
};


