import { ARTICLE_CONSTS, AUTH_CONSTS } from "../constants";

export const initialState = {
    articleData: {
        articleList: [],
        hasMore: false,
    },
    articleById: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case ARTICLE_CONSTS.GET_ARTICLES:
            return {
                ...state,
                articleData: {
                    articleList: action.payload.reset ? action.payload.data : [...state.articleData.articleList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };
        case ARTICLE_CONSTS.CREATE_ARTICLE:
            return {
                ...state,
                articleData: {
                    ...state.articleData,
                    articleList: [action.payload, ...state.articleData.articleList],
                }
            };
        case ARTICLE_CONSTS.GET_ARTICLE_BY_ID:
            return {
                ...state,
                articleById: action.payload
            };
        case ARTICLE_CONSTS.UPDATE_ARTICLE_BY_ID:
            return {
                ...state,
                articleData: {
                    ...state.articleData,
                    articleList: state.articleData.articleList.map(item => item.id === action.payload?.id ? action.payload : item)
                }
            };
        case ARTICLE_CONSTS.DELETE_ARTICLE_BY_ID:
            return {
                ...state,
                articleData: {
                    ...state.articleData,
                    articleList: state.articleData.articleList.filter(item => item.id !== action.payload)
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
