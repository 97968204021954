import { AUTH_CONSTS, ACTIVITY_CONSTS } from "../constants";

export const initialState = {
    activityNotificationList: {
        itemsList: [],
        hasMore: false,
    },
    activityUserList: {
        itemsList: [],
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIVITY_CONSTS.GET_NOTIFICATION_ACTIVITY:
            return {
                ...state,
                activityNotificationList: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.activityNotificationList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case ACTIVITY_CONSTS.GET_USER_ACTIVITY:
            return {
                ...state,
                activityUserList: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.activityUserList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case ACTIVITY_CONSTS.REMOVE_USER_ACTIVITY:
            return {
                ...state,
                activityUserList: {
                    itemsList: [],
                    hasMore: false,
                },
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
