// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from 'lodash/debounce';

// Import Styles
import '../../assets/styles/containerStyles/activities.scss';

// Import components
import { InputGroup } from "../../components/uiElements/inputGroup";
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ItemsList from "../../components/ItemsList";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    GetNotificationActivities, GetModerators, GetUser
} from "../../redux/actions";
import dayjs from "dayjs";
import { GET_LIMITS } from "../../constants/constsTypes";

class Activity extends Component {
    constructor (props) {
        super(props);
        this.state = {
            userId: undefined,
            usersList: {
                list: [],
                text: '',
                hasMore: true,
            },
            fetchingUsers: false,
        };
        this.listRef = React.createRef();
        this.getUsersDebounce = debounce(this.getUsers, 600);
        this.getInputValues = this.getInputValues.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    async componentDidMount () {
        this.props.GetNotificationActivities();
        await this.props.GetUser({
            offset: 0,
            limit: GET_LIMITS,
        });
        const { userList } = this.props.userData;
        this.setState({
            usersList: {
                list: userList,
                text: '',
                hasMore: userList?.length === GET_LIMITS
            }
        })
    }

    async getInputValues ({ value }) {
        await this.setState({
            userId: value,
        });
        this.props.GetNotificationActivities({
            userId: value
        })
    }

    onSearchUsers = async(value) => {
        // console.log('onSearchUsers search', value)
        this.fetchMoreUsers = false
        if (this.state.usersList.list.length) {
            this.setState({
                usersList: {
                    list: [],
                    hasMore: true
                }
            })
        }
        if (!this.state.fetchingUsers) {
            this.setState({
                fetchingUsers: true
            })
        }
        this.getUsersDebounce(value)
    }

    getUsers = async(value) => {
        const users = await this.props.GetUser({
            text: value,
            getResponse: true,
        });
        await this.setState({
            usersList: {
                list: users,
                hasMore: users.length === GET_LIMITS,
                text: value
            },
            fetchingUsers: false,
        })
    }
    onPopupScroll = async(e) => {
        const target = e.target;

        if (target.scrollTop === (target.scrollHeight - target.offsetHeight)) {
            // console.log('scrollTop ', target?.scrollTop)
            // console.log('offsetHeight ', target?.offsetHeight)
            // console.log('scrollHeight ', target?.scrollHeight)
            const { usersList } = this.state;
            // console.log('usersList', usersList.hasMore)
            usersList.hasMore && this.loadMoreUsers()
        }
    }

    loadMoreUsers = async() => {
        const { usersList } = this.state;

        if (!this.fetchMoreUsers) {
            this.fetchMoreUsers = true
            const moreUsers = await this.props.GetUser({
                offset: usersList?.list?.length,
                text: usersList?.text,
                getResponse: true,
            });
            if (this.fetchMoreUsers) {
                await this.setState({
                    usersList: {
                        list: [...usersList.list, ...moreUsers],
                        hasMore: moreUsers.length === GET_LIMITS,
                        text: usersList?.text
                    }
                })
            }
            this.fetchMoreUsers = false
        }
    };

    onDropdownVisibleChange = async(visible) => {
        if (visible) {
            const { userList } = this.props.userData;
            this.fetchMoreUsers = false;
            this.setState({
                usersList: {
                    list: userList,
                    text: '',
                    hasMore: userList?.length === GET_LIMITS
                }
            })
        }
    }

    async loadMoreItems () {
        if (!this.fetching) {
            this.fetching = true;
            const { activityNotificationList } = this.props;
            activityNotificationList?.hasMore && await this.props.GetNotificationActivities({
                reset: false,
                userId: this.state.userId,
                offset: activityNotificationList?.itemsList?.length,
            });
            this.fetching = false
        }
    };

    render () {
        const { userId, usersList, fetchingUsers } = this.state;
        const { activityNotificationList, requestLoading } = this.props;
        const itemListInfo = [];

        activityNotificationList?.itemsList?.forEach(item => {
            itemListInfo.push({
                id: item.id,
                // nestedItemId:item?.req_data?.event_id,
                values: [
                    {
                        title:  `${item?.user?.first_name || ""} ${item?.user?.last_name || ""}`,
                        redirect: item?.user?.id && `/user/details/${item?.user?.id}`,
                    },
                    {
                        title: `${item?.device?.device_token || " - "}`,
                        tooltip: true
                    }, {
                        title: `${item?.notification?.description || " - "}`,
                        tooltip: true
                    },
                    `${item?.device?.platform || " - "}`,
                    dayjs(item.created_at).format('DD.MM.YYYY HH:mm'),
                ],
            });
        });

        const activityNotificationListHeaderInfo = {
            generalInfo: ["User", "Device token", "Message", "Platform", "Date",],
            // actions: ["details"]
        };


        const usersOptions = usersList?.list?.map((user) => {
            const { id, email, first_name, last_name, created_at, username, image_url } = user;
            let fullName = `${first_name} ${last_name || ""}`
            return {
                id: id,
                name: first_name ? `${fullName} - ${username}` : username,
                label: first_name ? fullName : username,
            }
        });
        return <PageWrapper pageTitle={'Notification Activities'}>
            <InputGroup inputType="selectCustom"
                        label="Select User"
                        placeholder="Select User"
                        name="users"
                        showSearch={true}
                        value={userId}
                        onChange={this.getInputValues}
                        onSearch={this.onSearchUsers}
                        onPopupScroll={this.onPopupScroll}
                        onDropdownVisibleChange={this.onDropdownVisibleChange}
                        options={usersOptions}
                        allowClear={true}
                        fetching={fetchingUsers}

            >
            </InputGroup>
            <section className="activities">
                <ItemsList itemListInfo={itemListInfo}
                           itemListHeaderInfo={activityNotificationListHeaderInfo}
                           listRef={this.listRef}
                           loading={requestLoading}
                           infiniteScroll={true}
                           withLong={true}
                           hasMore={activityNotificationList?.hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetNotificationActivities,
    GetModerators,
    GetUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity)
