import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'antd';

//import styles
import '../../assets/styles/media/add-folder-dropDown-card.scss';

//import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { AddFolder, GetMedia } from "../../redux/actions";
import AddFolderCard from "./AddFolderCard";
import { iconNewFolder } from "../../assets/images";
import swal from "sweetalert";


function AddMediaFolder(props){
    const [addFolderCardOpen, setAddFolderCardOpen] = useState(false);

    function toggleAddFolderCard(){
        setAddFolderCardOpen(!addFolderCardOpen)
    }

    function addFolder(folderName) {
        const { folderList } = props;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const reqData = {
            folderName: folderName,
        };
        (currentFolder && currentFolder.key) && (reqData.prefix = currentFolder.key);
        return props.AddFolder(reqData)
            .then(() => {
                props.GetMedia({
                    parent: currentFolder.key,
                });
            }).catch((error)=>{
                if ( error && error.response && error.response.status === 409 ) {
                    swal({
                        title: "Չհաջողվեց!",
                        text: `${folderName} անունով թղթապանակ արդեն գոյություն ունի։`,
                        icon: "error",
                        button: "Լավ",
                    })
                }
            })
    }

    return <Dropdown placement="bottomLeft"
                     trigger={["click"]}
                     className={"add-folder-btn"}
                     overlayClassName={`add-folder-dropdown ${props.folderDropDownClassName ? props.folderDropDownClassName : ''}`}
                     onVisibleChange={toggleAddFolderCard}
                     visible={addFolderCardOpen}
                     overlay={
                         <AddFolderCard closeModal={toggleAddFolderCard}
                                        clearCardData={addFolderCardOpen}
                                        addFolder={addFolder}/>
                     }>
        <div><img src={iconNewFolder} alt=""/>Ավելացնել Թղթապանակ</div>
    </Dropdown>
}


const mapDispatchToProps = {
    AddFolder,
    GetMedia,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMediaFolder);
