// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationsInitialValues,
    initTranslations,
    getPostReqData,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateTrainer, GetTrainerById, UpdateTrainerById } from "../../redux/actions";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { validateTranslations } from "../../utils/validateTranslations";
import {MAIN_LANGUAGE_TYPES, MEDIA_MODAL_TYPES} from "../../constants/constsTypes";
import {Editor} from "../../components/uiElements/Editor";
import {transformImage} from "../../utils/transformImage";


class AddEditTrainer extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'name',
            'profession',
            'bio'
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                image:null,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.addEditTrainer = this.addEditTrainer.bind(this);
        this.getMedia = this.getMedia.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;


        if (isEditing) {
            await this.props.GetTrainerById(this.props.id);
            const editingData = this.props.trainerById;
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations, ['name','profession','bio']),
                    image: editingData?.image_object_name ?
                        transformImage(editingData.image_object_name, editingData.image_url )
                        : null,
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    ...this.state,
                    fieldsData: initStateData
                });
            }
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                image: mediaArray[0],
            },
            errors: {
                ...errors,
                image: false
            }
        })
    }


    addEditTrainer () {
        this.setState({
            requestLoading: true
        });
        const { translations,image} = this.state.fieldsData;
        const translationRequiredFields = ['name','profession']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        let errors = { ...validationTr.errors };

        if (!image) {
            errors.image = true;
            result = false;
        }

        if (result) {
            if (!this.state.isEditing) {

                let reqData = this.getPostReqData(this.state.fieldsData);
                this.props.CreateTrainer(reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    })
            } else {
                let reqData = this.getPostReqData(this.state.fieldsData);
                this.props.UpdateTrainerById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;

        const trData = fieldsData?.translations?.find(item => item?.lang === languageTab) || {};
        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Մարզչի' : 'Փոփոխել Մարզչին'}>
            <section className="general-add-edit">

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.image && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.image}
                    />
                </div>}

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}
                />
                <div className="category-left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="name"
                                value={currentData.name}
                                initValue={initData.name}
                                error={errors['name' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Մասնագիտություն"
                                placeholder="Մասնագիտություն"
                                name="profession"
                                value={currentData?.profession}
                                initValue={initData.profession}
                                error={errors['profession' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                    <div className={'editors'}>
                        <div className={'editor-wrapper'}>
                            <label>Կենսագրություն</label>
                            <Editor
                                value={trData?.bio || ""}
                                name={'bio'}
                                initValue={fieldsData?.bio}
                                error={errors['bio' + languageTab]}
                                disableToolbar={true}
                                onChange={this.getTranslatableInputValues}/>
                        </div>
                    </div>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditTrainer}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateTrainer,
    GetTrainerById,
    UpdateTrainerById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTrainer)