// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/service-request-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import moment from "moment";

class ServiceRequestDetails extends Component {

    render () {
        const { id, serviceRequests } = this.props;

        const request = serviceRequests?.list?.find(item => item.id === id)
        const { service, user_data, created_at } = request || {};

        return <PageWrapper pageTitle={`Փաթեթ`}>
            <section className="service-request-details">
                <h1><span style={{ color: service?.name_color }}> {service?.name}</span> {service?.title}</h1>
                <div className={'user-info'}>
                    <div className={'row'}>
                        <span>Անուն:</span>
                        <span>{user_data?.first_name}</span>
                    </div>
                    <div className={'row'}>
                        <span>Ազգանուն:</span>
                        <span>{user_data?.last_name}</span>
                    </div>
                    <div className={'row'}>
                        <span>Հեռախոս:</span>
                        <span>{user_data?.phone}</span>
                    </div>
                    <div className={'row'}>
                        <span>Էլ․ հասցե:</span>
                        <span>{user_data?.email}</span>
                    </div>
                    <div className={'row'}>
                        <span>Օր/ժամ:</span>
                        <span>{created_at ? moment(created_at).format("DD.MM.YYYY / HH:mm") : ""}</span>
                    </div>
                    {/*<div className={'row'}>
                        <span>Նշումներ:</span>
                        <span className={'text-box'}>{user_data?.text || '-'}</span>
                    </div>*/}
                </div>
            </section>
        </PageWrapper>
    }
}


export default connect(mapStateToProps)(ServiceRequestDetails)
