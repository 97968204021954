// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationsInitialValues,
    initTranslations,
    getPostReqData,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateWorkoutSection, GetWorkoutSectionById, UpdateWorkoutSectionById } from "../../redux/actions";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { validateTranslations } from "../../utils/validateTranslations";
import {MAIN_LANGUAGE_TYPES} from "../../constants/constsTypes";

class AddEditWorkoutSections extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields),
                position:undefined,
                is_hidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: MAIN_LANGUAGE_TYPES.en,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getPostReqData = getPostReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addEditWorkoutSection = this.addEditWorkoutSection.bind(this);
    }

    async componentDidMount () {
        const { isEditing } = this.state;
        if (isEditing) {
            await this.props.GetWorkoutSectionById(this.props.id);
            const editingData = this.props.workoutSectionById;
            if (editingData) {
                const initStateData = {
                    translations: getTranslationsInitialValues(editingData.translations, ['title']),
                    is_hidden: editingData?.is_hidden,
                    position:editingData?.position
                }
                this.currentData = JSON.parse(JSON.stringify(initStateData));
                this.setState({
                    ...this.state,
                    fieldsData: initStateData
                });
            }
        }
    }

    addEditWorkoutSection () {
        this.setState({
            requestLoading: true
        });
        const { translations} = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        let errors = { ...validationTr.errors };

        if (result) {
            if (!this.state.isEditing) {
                let {workoutSectionsList} = this.props;

                let position = undefined
                let num = []
                workoutSectionsList?.forEach(item => {
                    num.push(item?.position)
                    return position = Math.max(...num)
                })

                let reqData = this.getPostReqData(this.state.fieldsData);
                reqData.position = position + 1
                this.props.CreateWorkoutSection(reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    })
            } else {
                let reqData = this.getPostReqData(this.state.fieldsData);
                this.props.UpdateWorkoutSectionById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations?.find(item => item?.lang === languageTab) || {};
        const currentData = fieldsData.translations?.find(item => item?.lang === languageTab) || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Մարզումների տեսակ' : 'Փոփոխել Մարզումների տեսակը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.is_hidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.is_hidden}
                            onChange={(checked) => this.getInputValues({
                                name: "is_hidden",
                                value: !checked,
                                haveChanges: this.currentData?.is_hidden !== !checked
                            })}/>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}
                />
                <div className="category-left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                cb={this.addEditWorkoutSection}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateWorkoutSection,
    GetWorkoutSectionById,
    UpdateWorkoutSectionById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditWorkoutSections)
