export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - fitness",
                key: "header_item_fitness"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - events",
                key: "header_item_events"
            },
            {
                label: "Header - spa",
                key: "header_item_spa"
            },
            {
                label: "Header - cafe",
                key: "header_item_cafe"
            },
            {
                label: "Header - contact",
                key: "header_item_contact"
            },
            {
                label: "Header - membership",
                key: "header_membership"
            },
            {
                label: "Header - languages_hy",
                key: "header_languages_hy"
            },
            {
                label: "Header - languages_en",
                key: "header_languages_en"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - main block apply",
                key: "homepage_main_block_apply"
            },
            {
                label: "Homepage - ticker block text",
                key: "homepage_ticker_block_text"
            },
            {
                label: "Homepage - services title",
                key: "homepage_services_title"
            },
            {
                label: "Homepage - services description",
                key: "homepage_services_description"
            },
            {
                label: "Homepage - video title",
                key: "homepage_video_title"
            },
            {
                label: "Homepage - video description",
                key: "homepage_video_description"
            },
            {
                label: "Homepage - artEvents title",
                key: "homepage_artEvents_title"
            },
            {
                label: "Homepage - events title",
                key: "homepage_events_title"
            },
            {
                label: "Homepage - article title",
                key: "homepage_article_title"
            },
            {
                label: "Homepage - app title",
                key: "homepage_app_title"
            },
            {
                label: "Homepage - app description",
                key: "homepage_app_description"
            },
            {
                label: "Homepage - membership block title",
                key: "homepage_membership_block_title"
            },
            {
                label: "Homepage - membership block description",
                key: "homepage_membership_block_description"
            },
            {
                label: "Homepage - membership title",
                key: "homepage_membership_title"
            },
            {
                label: "Homepage - addition title",
                key: "homepage_addition_title"
            },
            {
                label: "Homepage - faq title",
                key: "homepage_faq_title"
            },
            {
                label: "Homepage - faq description",
                key: "homepage_faq_description"
            },
            {
                label: "Homepage - faq contact btn",
                key: "homepage_faq_contact_btn"
            },
            {
                label: "Homepage - service fitness title",
                key: "home_service_fitness_title"
            },
            {
                label: "Homepage - service fitness description",
                key: "home_service_fitness_description"
            },
            {
                label: "Homepage - service team title",
                key: "home_service_team_title"
            },
            {
                label: "Homepage - service team description",
                key: "home_service_team_description"
            },
            {
                label: "Homepage - service cafe title",
                key: "home_service_cafe_title"
            },
            {
                label: "Homepage - service cafe description",
                key: "home_service_cafe_description"
            },
            {
                label: "Homepage - service spa title",
                key: "home_service_spa_title"
            },
            {
                label: "Homepage - service spa description",
                key: "home_service_spa_description"
            },
            {
                label: "Homepage - packages duration text",
                key: "home_packages_duration_text"
            },
            {
                label: "Homepage - packages btn text",
                key: "home_packages_btn_text"
            },
            {
                label: "Membership - show all",
                key: "home_packages_show_all"
            },
            {
                label: "Visit - modal title",
                key: "visit_modal_title"
            },
            {
                label: "Visit - modal description",
                key: "visit_modal_description"
            },

        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - fitness",
                key: "footer_section_fitness"
            },
            {
                label: "Footer - spa",
                key: "footer_section_spa"
            },
            {
                label: "Footer - about",
                key: "footer_section_about"
            },
            {
                label: "Footer - events",
                key: "footer_section_events"
            },
            {
                label: "Footer - cafe",
                key: "footer_section_cafe"
            },
            {
                label: "Footer - contact",
                key: "footer_section_contact"
            },
            {
                label: "Footer - copyright",
                key: "footer_copyright"
            },
            {
                label: "Footer - developed text",
                key: "footer_developed_text"
            },
            {
                label: "Footer - regulation",
                key: "footer_regulation"
            },
            {
                label: "Footer - privacy police",
                key: "footer_privacy_police"
            },
        ]
    },
    about: {
        pageName: 'About',
        list: [
            {
                label: "About - contact btn",
                key: "about_page_contact_btn_title"
            },
            {
                label: "About - page title",
                key: "about_page_title"
            },
            {
                label: "About - explore btn title",
                key: "about_page_explore_btn_title"
            },
            {
                label: "FAQ_title",
                key: "faq_page_title"
            },
            {
                label: "Section-title",
                key: "section_title"
            },
            {
                label: "About - btn title",
                key: "about_btn_title"
            },
            {
                label: "Not Found - page text",
                key: "notFound_text"
            },
        ]
    },
    contact: {
        pageName: 'Contact',
        list: [
            {
                label: "Contacts - info title",
                key: "contact_info_title"
            },
            {
                label: "Contacts - working hour title",
                key: "contact_working_hour_title"
            },
            {
                label: "Contacts - number title",
                key: "contact_number_title"
            },
            {
                label: "Contacts - address title",
                key: "contact_address_title"
            },
            {
                label: "Contacts - page title",
                key: "contact_page_title"
            },
            {
                label: "Contacts - title",
                key: "contact_title"
            },
            {
                label: "Contacts - description",
                key: "contact_description"
            },
            {
                label: "Contacts - page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - page btn send",
                key: "contacts_page_btn_send"
            },
            {
                label: "Contacts - address title",
                key: "contacts_address_title"
            },
            {
                label: "Contacts - url btn",
                key: "contacts_url_btn"
            },
        ]
    },
    fitness: {
        pageName: 'Fitness',
        list: [
            {
                label: "Fitness - page title",
                key: "fitness_page_title"
            },
            {
                label: "Fitness - page workout title",
                key: "fitness_page_workout_title"
            },
            {
                label: "Fitness - page workout description",
                key: "fitness_page_workout_description"
            },
            {
                label: "Fitness - page classes title",
                key: "fitness_page_classes_title"
            },
            {
                label: "Fitness - page classes description",
                key: "fitness_page_classes_description"
            },
            {
                label: "Workout - page staff title",
                key: "workout_page_staff_title"
            },
            {
                label: "Workout - page staff description",
                key: "workout_page_staff_description"
            },
            {
                label: "Workout - page gallery title",
                key: "workout_page_gallery_title"
            },
        ]
    },
    spa: {
        pageName: 'Spa',
        list: [
            {
                label: "Spa - page title",
                key: "spa_page_title"
            },
            {
                label: "Spa - page service title",
                key: "spa_page_service_title"
            },
            {
                label: "Spa - page service description",
                key: "spa_page_service_description"
            },
            {
                label: "Spa - page service work text",
                key: "spa_page_service_work_text"
            },
            {
                label: "Spa - page service work hours",
                key: "spa_page_service_work_hours"
            },
            {
                label: "Spa - page service priceList text",
                key: "spa_page_service_priceList_text"
            },
            {
                label: "Spa - page service reservation text",
                key: "spa_page_service_reservation_text"
            },
            {
                label: "Spa - page staff title",
                key: "spa_page_staff_title"
            },
            {
                label: "Spa - page staff description",
                key: "spa_page_staff_description"
            },
        ]
    },
    cafe: {
        pageName: 'Cafe',
        list: [
            {
                label: "Cafe - page title",
                key: "cafe_page_title"
            },
            {
                label: "Cafe - page menu title",
                key: "cafe_page_menu_title"
            },
            {
                label: "Cafe - page menu description",
                key: "cafe_page_menu_description"
            },
            {
                label: "Cafe - page menu pdf title",
                key: "cafe_page_menu_pdf_title"
            },
        ]
    },
    event: {
        pageName: 'Event',
        list: [
            {
                label: "Event - card members",
                key: "event_card_members"
            },
            {
                label: "Event - seeMore btn",
                key: "event_seeMore_btn"
            },
            {
                label: "Event - posted text",
                key: "event_posted_text"
            },
            {
                label: "Event - title",
                key: "event_title"
            },
            {
                label: "Event - page title",
                key: "event_page_title"
            },
            {
                label: "Event - page description",
                key: "event_page_description"
            },
            {
                label: "Event - page blog title",
                key: "event_page_blog_title"
            },
            {
                label: "Event - page blog description",
                key: "event_page_blog_description"
            },
            {
                label: "Event - page detail article title",
                key: "event_detail_article_title"
            },
            {
                label: "Event - page detail article description",
                key: "event_detail_article_description"
            },
            {
                label: "Event - article label text",
                key: "event_article_label_text"
            },
            {
                label: "Event - detail event title",
                key: "event_detail_event_title"
            },
            {
                label: "Event - detail event description",
                key: "event_detail_event_description"
            },
            {
                label: "Event - detail link description1",
                key: "event_detail_link_description1"
            },
            {
                label: "Event - detail link description2",
                key: "event_detail_link_description2"
            },
            {
                label: "Event - detail link btn",
                key: "event_detail_link_btn"
            },
        ]
    },
    editText:{
        pageName:'EditText',
        list:[
            {
                label: "Homepage - main block title",
                key: "homepage_main_block_title"
            },
        ]
    },
    pool:{
        pageName:'Pool',
        list:[
            {
                label: "Pool - page title",
                key: "pool_page_title"
            },
            {
                label: "Pool - page slider title",
                key: "pool_page_slider_title"
            },
            {
                label: "Pool - page more",
                key: "pool_page_more"
            },
        ]
    },
    packages:{
        pageName:'Packages',
        list:[
            {
                label: "Packages - page title",
                key: "packages_page_title"
            },
            {
                label: "Packages - page page subtitle",
                key: "packages_page_subtitle"
            },
        ]
    }
}





