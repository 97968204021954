import { store } from "../store";
import { _urlMedia, request, } from "../api";
import { MEDIA_CONSTS } from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import swal from "sweetalert";

export const GetMedia = ({
                             reset = true,
                             parent = null,
                             showError = false,
                         } = {}) => {
    return dispatch => {
        let url = `${_urlMedia}`;
        parent && (url += `?prefix=${parent}`);
        // console.log(type, 'TYPE');
        const requestData = {
            url,
            token: true,
            method: "GET",
        };
        return request(requestData)
            .then(async res => {
                res && res.data && await dispatch({
                    type: MEDIA_CONSTS.GET_MEDIA,
                    payload: {
                        data: res.data,
                        reset,
                        parent,
                        // hasMore: res.data.length === limit
                    },
                })
            }).catch(() => {
                showError && swal({
                    title: 'Զգուշացում',
                    text: 'ֆայլաին համակարգը այս պահին անհասանելի է։',
                    icon: "info",
                    button: 'Լավ'
                })
            })
    }
};

export const GetFileUploadS3SignedUrl = (data) => {
    const requestData = {
        url: _urlMedia,
        token: true,
        method: "POST",
        data: data,
    };
    return request(requestData).then(res => {
        return res.data;
    }).catch(() => null)
};

export const UploadFile = (url, file, onUploadProgress = null, uploadId) => {
    return dispatch => {
        const requestData = {
            url: url,
            method: "PUT",
            data: file,
            onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, uploadId),
            customHeaders: {
                "Accept": "application/json",
                "Content-Type": file.type,
            }
        };
        return request(requestData).then(res => {
            return res.data;
        })
    }
};

export const MoveFile = (data) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/move`,
            token: true,
            method: "POST",
            data,
        };
        return request(requestData)
            .then(_ => {
                dispatch({
                    type: MEDIA_CONSTS.MOVE_MEDIA_BY_KEY,
                    payload: data.sourceObjectKey
                })
                notificationSuccess({
                    description: "Ֆայլը հաջողությամբ փոփոխվեց!"
                })
            })
    }
};

export const MoveFolder = (data) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder/move`,
            token: true,
            method: "POST",
            data,
        };
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEDIA_CONSTS.MOVE_MEDIA_BY_KEY,
                    payload: data.sourceObjectKey
                })
                notificationSuccess({
                    description: "Թղթապանակը հաջողությամբ փոփոխվեց!"
                })
            })
    }
};

export const UpdateFile = (id, data, type) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/${type === 'FOLDER' ? 'folder/' : ''}${id}`,
            token: true,
            method: "PATCH",
            data,
        };
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEDIA_CONSTS.UPDATE_MEDIA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ֆայլը հաջողությամբ փոփոխվեց!"
                })
            })
    }
};

export const DeleteFile = (key) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}`,
            token: true,
            method: "DELETE",
            data: {
                objectName: key
            }
        };
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MEDIA_CONSTS.DELETE_MEDIA_BY_ID,
                    payload: key
                })
            })
    }
};

export const AddFolder = (data) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder`,
            token: true,
            method: "POST",
            data,
        };
        return request(requestData).then(res => {
            return res.data;
        })
    }
};

export const DeleteFolder = (key) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder`,
            token: true,
            method: "DELETE",
            data: {
                objectName: key
            }
        };
        return request(requestData).then(res => {
            dispatch({
                type: MEDIA_CONSTS.DELETE_MEDIA_BY_ID,
                payload: key
            });
        })
    }
};

export const updateMediaPath = (id) => {
    return dispatch => {
        dispatch({
            type: MEDIA_CONSTS.UPDATE_MEDIA_PATH,
            payload: id
        });
    }
};

export const toggleMediaView = (state) => {
    store.dispatch({
        type: MEDIA_CONSTS.TOGGLE_MEDIA_VIEW,
        payload: state
    })
};

export const setFolderList = (state = []) => {
    store.dispatch({
        type: MEDIA_CONSTS.SET_FOLDER_LIST,
        payload: state
    })
};

export const setUpLoadProgresses = (progresses = []) => {
    return store.dispatch({
        type: MEDIA_CONSTS.SET_UPLOAD_PROGRESSES,
        payload: progresses
    })
};

export const getMediaHistory = (text) => {
    return dispatch => {
        dispatch({
            type: MEDIA_CONSTS.GET_MEDIA_HISTORY,
            payload: text
        });
    }
};

export const deleteMediaHistory = (index) => {
    return dispatch => {
        dispatch({
            type: MEDIA_CONSTS.DELETE_MEDIA_HISTORY,
            payload: index
        });
    }
};
