// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { GetStaticTexts, UpdateStaticTexts } from "../../redux/actions";
import { StaticTextFields } from "../../constants/staticTextFields";

// Import styles
import "../../assets/styles/containerStyles/staticTexts.scss";

// Import components
import { StaticTextRow } from "../../components/uiElements/StaticTextRow";

import { LanguageTabs } from "../../components/uiElements/Tabs";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { Editor } from "../../components/uiElements/Editor";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import { MAIN_LANGUAGE_TYPES } from "../../constants/constsTypes";
import { LinkButton } from "../../components/buttons/buttons";

const areaStaticTexts = ['apps_section1_description', 'apps_section2_description', 'shipping_description']; //"static-text-key"


class StaticTexts extends Component {
    constructor (props) {
        super(props);

        this.state = {
            fields: {},
            translations: {},
            editingField: null,
            editingFieldIndex: null,
            temporaryInfo: "",
            selectedPage: undefined,
            mounted: false,
            languageTab: MAIN_LANGUAGE_TYPES.en,
        };
        this.changeLanguageTab = this.changeLanguageTab.bind(this);
        this.getTempValue = this.getTempValue.bind(this);
        this.getSelectedPage = this.getSelectedPage.bind(this);
        this.saveStaticTexts = this.saveStaticTexts.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.translatableEditing = this.translatableEditing.bind(this);
    }

    async componentDidMount () {
        await this.props.GetStaticTexts();
        const { languageTab } = this.state;
        this.setState({
            fields: this.props.staticTextsList && this.props.staticTextsList[languageTab || 'en'],
            mounted: true
        });
    }

    changeLanguageTab (activeTab) {
        this.setState({
            languageTab: activeTab,
            fields: this.props.staticTextsList && this.props.staticTextsList[activeTab],
        })
    }

    translatableEditing (name) {
        const { value } = name
        this.startEditing(name?.name)
        this.getTempValue({ value })
    }

    startEditing (name) {
        this.setState({
            editingField: name,
            temporaryInfo: this.state.fields?.[name]
        })
    }

    cancelEditing () {
        this.setState({
            editingField: null,
            temporaryInfo: ""
        })
    }

    getTempValue ({ value }) {
        this.setState({
            temporaryInfo: value
        })
    }

    getSelectedPage ({ value }) {
        this.setState({
            selectedPage: value ?? undefined
        });
    }


    saveStaticTexts (continueEditing = null) {
        const { fields, editingField, temporaryInfo, languageTab } = this.state;
        const updatingData = {
            value: temporaryInfo
        };
        languageTab && this.props.UpdateStaticTexts(updatingData, languageTab, editingField).then(() => {
            this.setState({
                fields: {
                    ...fields,
                    [editingField]: temporaryInfo
                },
                editingField: continueEditing,
                temporaryInfo: ""
            })
        });
    }


    render () {

        const { fields, editingField, temporaryInfo, languageTab, selectedPage, mounted } = this.state;
        let staticList = [];
        if (selectedPage) {
            staticList = StaticTextFields[selectedPage]?.list
        } else {
            Object.keys(StaticTextFields).forEach(page => {
                staticList = staticList?.concat(StaticTextFields[page]?.list);
            })
        }
        const selectOptions = Object.keys(StaticTextFields).map(page => ({
            id: page,
            name: StaticTextFields[page]?.pageName
        }));

        return <PageWrapper pageTitle={'Static texts'}>
            <section className="admin-static">
                {selectedPage !== 'privacyPolicy' &&
                <p className={'warning'}><span>*</span>Don’t delete '%d', it will be replaced with the username</p>}
                <div className="fixed-top-right translation-select-wrapper">

                    <InputGroup
                        inputType={"selectCustom"}
                        value={selectedPage}
                        placeholder={'Pages...'}
                        onChange={this.getSelectedPage}
                        withClear={'Բոլորը'}
                        showSearch={true}
                        options={selectOptions}
                    />
                </div>
                <div className="translation-tabs">

                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  activeTab={languageTab}
                    />
                    {
                        selectedPage !== 'editText' ? staticList.map((row, index) => {
                            const inputType = areaStaticTexts.includes(row.key) ? 'textarea' : 'input';
                            return row.key !== 'homepage_main_block_title'
                                && <StaticTextRow key={index}
                                                  inputType={inputType}
                                                  editingField={editingField}
                                                  value={(fields && fields[row.key]) || ""}
                                                  name={row.key}
                                                  label={row.label}
                                                  tempValue={temporaryInfo}
                                                  getTempValue={this.getTempValue}
                                                  startEditing={this.startEditing}
                                                  saveStaticTexts={this.saveStaticTexts}
                                                  cancelEditing={this.cancelEditing}/>
                        }) : <div className="main_block_title-wrapper">
                            <div className={'editor-title'}>
                                Homepage main block title
                            </div>
                            {mounted && <Editor
                                value={fields && fields['homepage_main_block_title']}
                                editingField={editingField}
                                name={'homepage_main_block_title'}
                                tempValue={temporaryInfo}
                                onChange={this.translatableEditing}
                            />}
                            <div className={'flex-wrapper-right'}>
                                <LinkButton title="Փոփոխել"
                                            cb={this.saveStaticTexts}/>
                            </div>
                        </div>

                    }


                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetStaticTexts,
    UpdateStaticTexts,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticTexts);
