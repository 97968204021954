// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { history } from "../../configs/history";
import swal from "sweetalert";


// Import styles
import "../../assets/styles/containerStyles/media.scss";
import "../../assets/styles/media/media-header.scss";
import {
    IconDeleteDark,
    iconGrid, iconList,
} from "../../assets/images";

// Import components
import { MediaFileCard } from "../../components/media/MediaFileCard";
import { MediaFolderCard } from "../../components/media/MediaFolderCard";
import PreviewModal from '../../components/media/PreviewModal';
import MediaUploaderWrapper from "../../components/media/AddMedia/MediaUploaderWrapper";
import AddMediaFolder from "../../components/media/AddFolder";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import { LinkButton } from "../../components/buttons/buttons";
import { LoadingOutlined } from "@ant-design/icons";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    DeleteFile,
    DeleteFolder,
    GetMedia,
    setFolderList,
    toggleMediaView,
    UpdateFile,
    MoveFile,
    MoveFolder
} from "../../redux/actions";
import { MEDIA_TYPES } from "../../constants/constsTypes";

class Media extends Component {
    constructor (props) {
        super(props);

        this.state = {
            searchValue: '',
            searchType: null,
            draggableFile: null,
            previewModalOpen: false,
            previewItem: null,
            searchClosed: true,
            fetching: false,
            selectedItems: [],
            objectsSelectedForDelete: []
        };

        this.openFolder = this.openFolder.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.onDropToFolder = this.onDropToFolder.bind(this);
        this.dragFile = this.dragFile.bind(this);
        this.openPreview = this.openPreview.bind(this,);
        this.closePreview = this.closePreview.bind(this,);
        this.getFiles = this.getFiles.bind(this,);
        this.selectCard = this.selectCard.bind(this,);
        this.removeSelect = this.removeSelect.bind(this,);
        this.deleteMediaList = this.deleteMediaList.bind(this,);
    }

    componentDidMount () {
        const { location, folderList } = this.props;

        if (folderList?.length) {
            const currentFolder = folderList[folderList.length - 1];
            history.push({
                pathname: '/media',
                search: `?currentFolder=${currentFolder?.name}`
            })
            this.getFiles(currentFolder?.key);
        } else {
            if (location.search) {
                history.push({
                    pathname: '/media',
                })
            }
            this.getFiles();
        }

        window.addEventListener('click', (e) => {
            this.removeSelect(e);
        });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const { location, folderList } = this.props;
        if (!location?.search && prevProps?.location?.search && folderList?.length) {
            setFolderList([])
        }
        if (folderList !== prevProps?.folderList) {
            // console.log('changed')
            const currentFolder = folderList?.[folderList?.length - 1] || null
            this.getFiles(currentFolder?.key)
            history.push({
                pathname: '/media',
                search: currentFolder ? `?currentFolder=${currentFolder?.name}` : ''
            })
        }
        if (location?.search !== prevProps?.location?.search) {
            this.setState({
                selectedItems: []
            });
        }
    }

    async getFiles (parent = null) {
        await this.setState({ fetching: true })
        await this.props.GetMedia({ parent, showError: true }).catch(() => {
            history.push({
                pathname: '/media',
            });
            setFolderList([])
        });
        this.setState({ fetching: false })
    }

    deleteFile = (key) => {
        this.setState({
            objectsSelectedForDelete: [key]
        })
        // console.log(key);
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ֆայլը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(async confirm => {
            if (confirm) {
                await this.props.DeleteFile(key);
            }
        }).finally(() => {
            this.setState({
                objectsSelectedForDelete: []
            })
        });
    };

    deleteFolder = (key) => {
        this.setState({
            objectsSelectedForDelete: [key]
        })
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել թղթապանակը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(async confirm => {
            if (confirm) {
                await this.props.DeleteFolder(key)
            }
        }).finally(() => {
            this.setState({
                objectsSelectedForDelete: []
            })
        });
    };

    deleteMediaList = async() => {
        const { selectedItems } = this.state;
        await this.setState({
            objectsSelectedForDelete: selectedItems.map(item => item.key)
        })
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել նշված ֆայլերը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(async confirm => {
            if (confirm && selectedItems?.length) {
                const promises = selectedItems.map(item => {
                    if (item?.type === 'FOLDER') {
                        return this.props.DeleteFolder(item?.key)
                    } else {
                        return this.props.DeleteFile(item?.key);
                    }
                });
                await Promise.all(promises)
            }
        }).finally(() => {
            this.setState({
                objectsSelectedForDelete: []
            })
        });
    };

    openFolder (folderData) {
        const currentFolderList = this.props.folderList || [];
        setFolderList([...currentFolderList, folderData])
        history.push({
            pathname: '/media',
            search: `?currentFolder=${folderData?.name}`
        })
    }

    changeFolder = (key) => {
        const { folderList } = this.props;
        if (key === null) {
            setFolderList([])
        } else {
            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.key === key
            });
            setFolderList(newList)
        }
    };

    onDropToFolder (folder) {
        const { draggableFile } = this.state;
        // console.log('draggableFile', draggableFile)
        if (draggableFile && draggableFile.key !== folder.key) {
            const reqData = {
                sourceObjectKey: draggableFile.key,
                destinationObjectKey: folder.key,
            };
            if (draggableFile.type === MEDIA_TYPES.FILE) {
                this.props.MoveFile(reqData).catch((error) => {
                    if (error && error.response && error.response.status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: `${draggableFile.name} անունով ֆայլ արդեն գոյություն ունի։`,
                            icon: "error",
                            button: "Լավ",
                        })
                    }
                })
            } else {
                this.props.MoveFolder(reqData).catch((error) => {
                    if (error && error.response && error.response.status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: `${draggableFile.name} անունով թղթապանակ արդեն գոյություն ունի։`,
                            icon: "error",
                            button: "Լավ",
                        })
                    }
                })
            }
        }
    }

    dragFile (draggableFile) {
        this.setState({
            draggableFile,
        })
    }

    openPreview (item) {
        this.setState({
            previewItem: item,
            previewModalOpen: true
        })
    }

    closePreview () {
        this.setState({
            previewItem: null,
            previewModalOpen: false
        })
    }

    selectCard (e, media) {
        const { selectedItems } = this.state;
        if (e.ctrlKey) {
            let medias = [...selectedItems];

            if (!selectedItems?.find(item => item?.key === media?.key)) {
                medias.push({
                    key: media?.key,
                    type: media?.type
                });
            } else {
                medias = selectedItems.filter(item => item?.key !== media?.key);
            }

            this.setState({
                selectedItems: medias
            })
        }
    }

    removeSelect (e) {
        if (this.state.selectedItems.length && !e.ctrlKey) {
            this.setState({
                selectedItems: []
            });
        }
    }

    render () {
        const { mediaItems, leftSideMenuOpen, mediaView, folderList } = this.props;
        const {
            fetching,
            previewModalOpen,
            previewItem,
            selectedItems,
            objectsSelectedForDelete,
        } = this.state;
        let itemsList = mediaItems ? mediaItems.itemsList : [];
        let currentFolder = null;
        if (folderList && folderList.length) {
            currentFolder = folderList[folderList.length - 1];
        }
        // console.log('folderList', folderList);
        return <PageWrapper
            mediaPageTitle={<h1 className={`media-title ${leftSideMenuOpen ? 'small-title' : ''}`}>
                        <span className={'path-item'}
                              onClick={() => this.changeFolder(null)}
                              onDragOver={(e) => currentFolder && e.preventDefault()}
                              onDrop={() => currentFolder && this.onDropToFolder({})}>
                        Ֆայլեր
                    </span>
                {
                    folderList && !!folderList.length && folderList.map((folder, index) => {
                        const notLastFolderInPath = folderList.length - 1 !== index;
                        return <>
                            <span> / </span>
                            <span className={'path-item'}
                                  onClick={() => notLastFolderInPath && this.changeFolder(folder.key)}
                                  onDragOver={(e) => notLastFolderInPath && e.preventDefault()}
                                  onDrop={() => notLastFolderInPath && this.onDropToFolder(folder)}>
                                        {folder.name}
                                    </span>
                        </>
                    })
                }
            </h1>}>
            <div className={`admin-files`}>
                {fetching && <div className={'fetching-loader'}>
                    <LoadingOutlined style={{ fontSize: 54, color: "#7f1f5f" }} spin/>
                </div>}
                <div className="media-actions-header">

                    <div className={'add-buttons-wrapper'}>
                        <AddMediaFolder
                            folderList={folderList}/>
                        <MediaUploaderWrapper
                            currentFolder={currentFolder}/>
                    </div>
                    <div className={"right-wrapper"}>
                        {!!selectedItems.length &&
                        <button className={'delete-btn'}>
                            <IconDeleteDark className={'delete-icon'} title={''} onClick={this.deleteMediaList}/>
                        </button>}
                        <div className={"toggle-track-view"}>
                            <LinkButton
                                className={`list-view-button ${mediaView ? 'selected' : ''}`}
                                title={<img src={iconList} alt=""/>}
                                cb={() => toggleMediaView(true)}/>
                            <LinkButton
                                className={`list-view-button ${!mediaView ? 'selected' : ''}`}
                                title={<img src={iconGrid} alt=""/>}
                                cb={() => toggleMediaView(false)}/>
                        </div>
                    </div>
                </div>

                {!fetching && <div className={`media-wrapper ${mediaView ? "list-view" : ""}`}>
                    {mediaView && <div className={"list-header"}>
                        <span>Name</span>
                        <span></span>
                        <span>Size</span>
                        <span>Type</span>
                        <span>Date updated</span>
                    </div>}
                    {itemsList && !!itemsList.length && itemsList.map((item) => {
                        return item.type === "FOLDER"
                            ? <MediaFolderCard key={item.key}
                                               disabled={objectsSelectedForDelete.includes(item.key)}
                                               deleteMedia={this.deleteFolder}
                                               openFolder={this.openFolder}
                                               withEdit={false}
                                               draggable={true}
                                               dragFile={this.dragFile}
                                               onDrop={this.onDropToFolder}
                                               folderList={folderList}
                                               item={item}
                                               listView={mediaView}
                                               selectCard={this.selectCard}
                                               selectedItems={selectedItems}/>
                            :
                            <MediaFileCard key={item.key}
                                           disabled={objectsSelectedForDelete.includes(item.key)}
                                           deleteMedia={this.deleteFile}
                                           inModal={false}
                                           withEdit={false}
                                           withCopy={true}
                                           draggable={true}
                                           onDoubleClick={this.openPreview}
                                           dragFile={this.dragFile}
                                           folderList={folderList}
                                           item={item}
                                           listView={mediaView}
                                           selectCard={this.selectCard}
                                           selectedItems={selectedItems}/>
                    })}
                </div>}
                <PreviewModal visible={previewModalOpen}
                              data={previewItem}
                              closeModal={this.closePreview}/>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetMedia,
    MoveFile,
    MoveFolder,
    UpdateFile,
    DeleteFile,
    DeleteFolder
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media));
