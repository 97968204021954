// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact-rule-resource.scss";

// Import components
import PageWrapper from '../components/pageContentViews/pageWrapper'
import { LinkButton } from "../components/buttons/buttons";

// Import utils
import { mapStateToProps } from "../redux/mapStateToProps";
import { GetAppVersion, UpdateAppVersionData} from "../redux/actions";
import {
    getInputValues,
} from "../utils/helperFunctions";
import { MAIN_LANGUAGE_TYPES } from "../constants/constsTypes";
import { InputGroup } from "../components/uiElements/inputGroup";
import { Switch } from "antd";


class AppVersion extends Component {
    constructor (props) {
        super(props);
        this.state = {
            fieldsData: {
                android_version_name: '',
                android_version_number: '',
                android_force_upgrade: false,
                ios_version_name: '',
                ios_version_number: '',
                ios_force_upgrade: false,
            },
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: MAIN_LANGUAGE_TYPES.en,
        };
        this.updatedDataMap = new Map();
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.getInputValues = getInputValues.bind(this);
        this.updateAppVersion = this.updateAppVersion.bind(this);
    }

    async componentDidMount () {
       await this.props.GetAppVersion();
        const { appVersionData } = this.props;
        appVersionData && this.setState({
            fieldsData: {
                android_version_name: appVersionData?.android_version_name || "" ,
                android_version_number: appVersionData?.android_version_number || "" ,
                android_force_upgrade: appVersionData?.android_force_upgrade || false ,
                ios_version_name: appVersionData?.ios_version_name || "" ,
                ios_version_number: appVersionData?.ios_version_number || "" ,
                ios_force_upgrade: appVersionData?.ios_force_upgrade || false ,
            },
        });
    }

    updateAppVersion () {
        this.setState({
            requestLoading: true
        });
        const { android_version_name, android_version_number, android_force_upgrade,
            ios_version_name, ios_version_number, ios_force_upgrade } = this.state.fieldsData;
        let result = true
        const errors = {};
        if (!android_version_name) {
            errors.android_version_name = true;
            result = false;
        }
        if (!android_version_number) {
            errors.android_version_number = true;
            result = false;
        }

        if (!ios_version_name) {
            errors.ios_version_name = true;
            result = false;
        }
        if (!ios_version_number) {
            errors.ios_version_number = true;
            result = false;
        }

        if (result) {
            let reqData = {
                android_version_name: android_version_name,
                android_version_number: android_version_number,
                android_force_upgrade: android_force_upgrade,
                ios_version_name: ios_version_name,
                ios_version_number: ios_version_number,
                ios_force_upgrade: ios_force_upgrade,
            }
            console.log('reqData', reqData);
            this.props.UpdateAppVersionData(reqData).then(() => {
                this.setState({
                    requestLoading: false,
                    errors: errors,
                })
            })
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render () {
        const { requestLoading } = this.props;
        const { fieldsData, errors, mediaModalOpen, modalAllowedTypes } = this.state;

        return <section className="general-add-edit">
            <div className={'third-wrapper'}>
            <InputGroup inputType="input"
                        type="text"
                        label="Android version name"
                        placeholder="Android version name"
                        name="android_version_name"
                        required={true}
                        value={fieldsData.android_version_name}
                        maxLength={100}
                        error={errors?.android_version_name}
                        onChange={this.getInputValues}/>
            <InputGroup inputType="input"
                        type="text"
                        integerNumber={true}
                        label="Android version number"
                        placeholder="Android version number"
                        name="android_version_number"
                        required={true}
                        value={fieldsData.android_version_number}
                        error={errors?.android_version_number}
                        maxLength={100}
                        onChange={this.getInputValues}/>
                <InputGroup inputType="wrapper"
                            label="Android Force Upgrade">
                    <div style={{paddingTop: '5px', display: 'flex', alignItems:"center"}}>
                        <Switch checked={fieldsData.android_force_upgrade}
                                onChange={(checked) => this.getInputValues({
                                    name: "android_force_upgrade",
                                    value: checked,
                                })}/>
                        {/*<label style={{margin: '0 0 0 15px'}}>{!fieldsData.is_hidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>*/}
                    </div>

                </InputGroup>
            </div>
            <div className={'third-wrapper'}>
            <InputGroup inputType="input"
                        type="text"
                        label="Ios version name"
                        placeholder="Ios version name"
                        name="ios_version_name"
                        required={true}
                        value={fieldsData.ios_version_name}
                        error={errors?.ios_version_name}
                        maxLength={100}
                        onChange={this.getInputValues}/>
            <InputGroup inputType="input"
                        type="text"
                        integerNumber={true}
                        label="Ios version number"
                        placeholder="Ios version number"
                        name="ios_version_number"
                        required={true}
                        value={fieldsData.ios_version_number}
                        error={errors?.ios_version_number}
                        maxLength={100}
                        onChange={this.getInputValues}/>
                <InputGroup inputType="wrapper"
                            label="Ios Force Upgrade">
                    <div style={{paddingTop: '5px', display: 'flex', alignItems:"center"}}>
                        <Switch checked={fieldsData.ios_force_upgrade}
                                onChange={(checked) => this.getInputValues({
                                    name: "ios_force_upgrade",
                                    value: checked,
                                })}/>
                        {/*<label style={{margin: '0 0 0 15px'}}>{!fieldsData.is_hidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>*/}
                    </div>

                </InputGroup>
            </div>
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                loading={requestLoading}
                                cb={this.updateAppVersion}/>
                </div>
            </section>

    }
}

const mapDispatchToProps = {
    GetAppVersion,
    UpdateAppVersionData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppVersion);
