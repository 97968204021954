import { request, _urlService, _urlServiceRequest } from "../api";
import { SERVICES_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetServiceRequests = ({reset = true,offset = 0, limit= 10}) => {
    const requestData = {
        url: `${_urlServiceRequest}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SERVICES_CONSTS.GET_SERVICE_REQUESTS,
                    payload:{
                        data:data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            })
    }
};

export const DeleteServiceRequest = id => {
    const requestData = {
        url: `${_urlServiceRequest}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.DELETE_SERVICE_REQUEST_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};
export const GetServices = () => {
    const requestData = {
        url: _urlService,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type:SERVICES_CONSTS.GET_SERVICES,
                    payload: data
                });
            })
    }
};

export const GetServiceById = id => {
    const requestData = {
        url: `${_urlService}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SERVICES_CONSTS.GET_SERVICE_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const DeleteServiceById = id => {
    const requestData = {
        url: `${_urlService}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type:SERVICES_CONSTS.DELETE_SERVICE_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};


export const CreateService = data => {
    const requestData = {
        url:_urlService,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.CREATE_SERVICE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Փաթեթները հաջողությամբ ստեղծվեց!"
                })
                history.push('/package')
            })
            .catch(() => {
            })
    }
};

export const UpdateServiceById = (id, data) => {
    const requestData = {
        url: `${_urlService}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:SERVICES_CONSTS.UPDATE_SERVICE_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Փաթեթները հաջողությամբ փոփոխվեց!"
                })
                history.push('/package')
            })
            .catch(() => {
            })
    }
};

export const UpdateServicePositions = items => {
    const requestData = {
        url: _urlService,
        token: true,
        method: "PATCH",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Փաթեթները հաջողությամբ փոփոխվեց!"
                })
            })
            .catch(() => {
            })
    }
};
