export const MEDIA_CONSTS = {
    UPLOAD_FILE: 'UPLOAD_FILE',
    GET_MEDIA: "GET_MEDIA",
    UPDATE_MEDIA: "UPDATE_MEDIA",
    UPDATE_MEDIA_PATH: "UPDATE_MEDIA_PATH",
    DELETE_MEDIA_BY_ID: "DELETE_MEDIA_BY_ID",
    ADD_FOLDER: 'ADD_FOLDER',
    TOGGLE_MEDIA_VIEW: "TOGGLE_MEDIA_VIEW",

    SET_FOLDER_LIST: "SET_FOLDER_LIST",
    SET_UPLOAD_PROGRESSES: "SET_UPLOAD_PROGRESSES",

    GET_MEDIA_HISTORY: 'GET_MEDIA_HISTORY',
    DELETE_MEDIA_HISTORY: 'DELETE_MEDIA_HISTORY',
    ADD_MEDIA_HISTORY: 'ADD_MEDIA_HISTORY',
    MOVE_MEDIA_BY_KEY: 'MOVE_MEDIA_BY_KEY',
};

