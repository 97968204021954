//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

export const UPLOAD_TYPES = {
    SELF: 'SELF',
    URL: 'URL',
};
export const LANGUAGE_TYPES = {
    en: 'en',
    hy: 'hy',
};
export const MAIN_LANGUAGE_TYPES = {
    en: 'en',
};

const STATUS_COLORS = {
    NEW: '#FF9112',
    CANCELLED: '#828B99',
    APPROVED: '#13C712',
    REJECTED: '#FF1212',
};
export const REVIEW_STATUS_TYPES = {
    INITIAL: {
        key: 'INITIAL',
        title: 'Նոր',
        color: STATUS_COLORS.NEW
    },
    APPROVED: {
        key: 'APPROVED',
        title: 'Հաստատված',
        color: STATUS_COLORS.APPROVED
    },
    REJECTED: {
        key: 'REJECTED',
        title: 'Մերժված',
        color: STATUS_COLORS.REJECTED
    },
};

export const STATUS_LIST = {
    NEW: {
        title: "Հաստատել",
        key: '5ee8dd7c4ac0e93f4336fcda',
        color: STATUS_COLORS.NEW,
    },
    CANCELLED: {
        title: 'Չեղարկված',
        key: '5ee8dda24ac0e93f4336fcdd',
        color: STATUS_COLORS.CANCELLED,
    },
    APPROVED: {
        title: 'Հաստատված',
        key: '5ee8dd984ac0e93f4336fcdb',
        color: STATUS_COLORS.APPROVED,
    },
    REJECTED: {
        title: 'Մերժված',
        key: '5ee8dda24ac0e93f4336fcdc',
        color: STATUS_COLORS.REJECTED,
    },
};

export const MEASUREMENT_TYPES = {
    LITER: 'Լ',
    KILOGRAM: 'Կգ',
    PIECES: 'Հատ',
    BOX: 'Արկղ',
};

export const PROMO_CODE_STATUS = {
    USED: "Օգտագործված",
    OPEN: "Չօգտագործված"
};


export const SLIDER_RESOURCE_TYPES = {
    PRODUCT: {
        title: 'Ապրանք',
        key: 'PRODUCT',
    },
    MARKET: {
        title: 'Մինի-Մարկետ',
        key: 'MARKET',
    },
    BANNER: {
        title: 'Զեղչ',
        key: 'BANNER',
    },
    CUSTOM_PAGE: {
        title: 'Դինամիկ Էջ',
        key: 'CUSTOM_PAGE',
    },
};

export const ADVERTISING_RESOURCE_TYPES = {
    PRODUCT: {
        title: 'Ապրանք',
        key: 'PRODUCT',
    },
    URL: {
        title: 'Հղում',
        key: 'URL',
    },
    CUSTOM_PAGE: {
        title: 'Դինամիկ Էջ',
        key: 'CUSTOM_PAGE',
    },
};

export const HISTORY_TYPES = {
    BONUS_FROM_ORDER: 'BONUS_FROM_ORDER',
    ORDER_VIA_BONUS_CARD: 'ORDER_VIA_BONUS_CARD'
};

export const POST_TYPES = {
    SELL: {
        key: 'SELL',
        name: 'Վաճառվող'
    },
    RENT: {
        key: 'RENT',
        name: 'Վարձով'
    },
};

export const CONDITION_TYPES = {
    NEW_HOME: {
        key: 'NEW_HOME',
        name: 'Նոր տուն'
    },
    GOOD_CONDITION: {
        key: 'GOOD_CONDITION',
        name: 'Լավ վիճակ'
    },
    NEED_RENOVATION: {
        key: 'NEED_RENOVATION',
        name: 'Վերանորոգման կարիք'
    },
};

export const PRICE_TYPES = {
    MONTHLY: 'Ամսեկան',
    DAILY: 'Օրական',
};

export const CONTACT_PHONE_TYPES = {
    MOBILE: {
        key: 'MOBILE',
        name: 'Բջջային'
    },
    OFFICE: {
        key: 'OFFICE',
        name: 'Աշխատանքային'
    },
    HOME: {
        key: 'HOME',
        name: 'Քաղաքային'
    },
};


export const CURRENCIES = {
    AMD: {
        key: "AMD",
        suffix: '֏',
    },
    USD: {
        key: 'USD',
        suffix: '$',
    },
    RUB: {
        key: "RUB",
        suffix: '₽',
    },
};

export const TYPE_CONST = {
    PACKAGE: 'Package',
    PAID: 'Paid',
}

export const WEEKDAY_TYPES = {
    Monday: {
        key: "Monday",
        name: "Երկուշաբթի",
        shortName: "Երկ",
        index: 1,
    },
    Tuesday: {
        key: "Tuesday",
        name: "Երեքշաբթի",
        shortName: "Երք",
        index: 2,
    },
    Wednesday: {
        key: "Wednesday",
        name: "Չորեքշաբթի",
        shortName: "Չրք",
        index: 3,
    },
    Thursday: {
        key: "Thursday",
        name: "Հինգշաբթի",
        shortName: "Հնգ",
        index: 4,
    },
    Friday: {
        key: "Friday",
        name: "Ուրբաթ",
        shortName: "Ուրբ",
        index: 5,
    },
    Saturday: {
        key: "Saturday",
        name: "Շաբաթ",
        shortName: "Շբթ",
        index: 6,
    },
    Sunday: {
        key: "Sunday",
        name: "Կիրակի",
        shortName: "Կիր",
        index: 7,
    },
}

export const WORKOUT_TYPE_CONST = {
    ADULT: 'Adult',
    AQUA: 'Aqua',
    CHILDREN: 'Children',
}
export const LOG_ACTION_TYPES_CONST = {
    Delete: {
        key: 'Delete',
        title: "Չեղարկել"
    },
    Create: {
        key: 'Create',
        title: "Գրանցվել"
    },
    Read: {
        key: 'Read',
        title: "Կարդալ"
    },
    Update: {
        key: 'Update ',
        title: "Փոփոխել"
    },
}
export const LOG_ACTION_ITEMS_CONST = {
    Event: {
        key: 'Event',
        title: "Իրադարձություն"
    },
    Event_join: {
        key: 'Event_join ',
        title: "Իրադար. Գրանցում"
    },
}
export const GET_LIMITS = 30

export const NotificationTypes = {
    Article: {
        key:'Article',
        title:'Բլոգ',
    },
    Event:  {
        key:'Event',
        title:'Իրադարձություն',
    },
    Workout:  {
        key:'Workout',
        title:'Խմբային մարզումներ',
    },
    System:  {
        key:'System',
        title:'Ինֆորմացիոն',
    },
}
