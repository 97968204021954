import { request, _urlSpa, _urlSpaService, _urlSpaWorker} from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {SPA_CONSTS} from "../constants";

export const GetSpa = () => {
    const requestData = {
        url: _urlSpa,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SPA_CONSTS.GET_SPA,
                    payload: data
                });
            })
    }
};

export const UpdateSpa = (id, data) => {
    const requestData = {
        url: `${_urlSpa}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:SPA_CONSTS.UPDATE_SPA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սպա ծառայությունը հաջողությամբ փոփոխվեց!"
                })
                history.push('/service/spa')
            })
            .catch(() => {
            })
    }
};

export const GetSpaServiceById = id => {
    const requestData = {
        url: `${_urlSpaService}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SPA_CONSTS.GET_SPA_SERVICE_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};
export const DeleteSpaServiceById = id => {
    const requestData = {
        url: `${_urlSpaService}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SPA_CONSTS.DELETE_SPA_SERVICE_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateSpaService = data => {
    const requestData = {
        url: _urlSpaService,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SPA_CONSTS.CREATE_SPA_SERVICE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սպա ծառայությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/service/spa')
            })
            .catch(() => {
            })
    }
};

export const UpdateSpaServiceById = (id, data) => {
    const requestData = {
        url: `${_urlSpaService}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:SPA_CONSTS.UPDATE_SPA_SERVICE_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սպա ծառայությունը հաջողությամբ փոփոխվեց!"
                })
                history.push('/service/spa')
            })
            .catch(() => {
            })
    }
};


export const UpdateSpaServicePositions = items => {
    const requestData = {
        url: _urlSpaService,
        token: true,
        method: "PATCH",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Սպա ծառայությունները հաջողությամբ փոփոխվեցին!"
                })
            })
            .catch(() => {
            })
    }
};


//=====================================  workers  ==============================================



export const GetSpaWorkerById = id => {
    const requestData = {
        url: `${_urlSpaWorker}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SPA_CONSTS.GET_SPA_WORKER_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};
export const DeleteSpaWorkerById = id => {
    const requestData = {
        url: `${_urlSpaWorker}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SPA_CONSTS.DELETE_SPA_WORKER_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateSpaWorker = data => {
    const requestData = {
        url: _urlSpaWorker,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SPA_CONSTS.CREATE_SPA_WORKER,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սպա աշխատակիցը հաջողությամբ ստեղծվեց!"
                })
                history.push('/service/spa')
            })
            .catch(() => {
            })
    }
};

export const UpdateSpaWorkerById = (id, data) => {
    const requestData = {
        url: `${_urlSpaWorker}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:SPA_CONSTS.UPDATE_SPA_WORKER_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սպա աշխատակիցը հաջողությամբ փոփոխվեց!"
                })
                history.push('/service/spa')
            })
            .catch(() => {
            })
    }
};


export const UpdateSpaWorkerPositions = items => {
    const requestData = {
        url: _urlSpaWorker,
        token: true,
        method: "PATCH",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Սպա աշխատակիցները հաջողությամբ փոփոխվեցին!"
                })
            })
            .catch(() => {
            })
    }
};
