import {request, _urlCafe} from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {CAFE_CONSTS} from "../constants";

export const GetCafe = () => {
    const requestData = {
        url: _urlCafe,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: CAFE_CONSTS.GET_CAFES,
                    payload: data
                });
            })
    }
};


export const UpdateCafeById = (data) => {
    const requestData = {
        url: _urlCafe,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:CAFE_CONSTS.GET_CAFES,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սրճարանը հաջողությամբ փոփոխվեց!"
                })
                history.push('/service/cafe')
                return res.data
            })
            .catch(() => {
            })
    }
};