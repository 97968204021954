import {request, _urlGroupWorkout, _urlWorkoutTimeline, _urlWorkoutTimelineCancellation} from "../api";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

import {GROUP_WORKOUT_CONSTS, UTIL_CONSTS} from "../constants";

export const GetGroupWorkouts = ({reset = true, offset = 0, limit = 10} = {}) => {

    const requestData = {
        url: `${_urlGroupWorkout}?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({

                    type: GROUP_WORKOUT_CONSTS.GET_GROUP_WORKOUTS,
                    payload: {
                        data: data,
                        reset,

                        hasMore: data.length === limit,
                    }
                });
            })
    }
};

export const GetGroupWorkoutById = id => {
    const requestData = {
        url: `${_urlGroupWorkout}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: GROUP_WORKOUT_CONSTS.GET_GROUP_WORKOUT_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const DeleteGroupWorkoutById = id => {
    const requestData = {
        url: `${_urlGroupWorkout}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: GROUP_WORKOUT_CONSTS.DELETE_GROUP_WORKOUT_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateGroupWorkout = data => {
    const requestData = {
        url: _urlGroupWorkout,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: GROUP_WORKOUT_CONSTS.CREATE_GROUP_WORKOUT,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Խմբային մարզումները հաջողությամբ ստեղծվեց!"
                })
                history.push('/groupWorkout')
            })
            .catch(() => {
            })
    }
};

export const UpdateGroupWorkoutById = (id, data) => {
    const requestData = {
        url: `${_urlGroupWorkout}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: GROUP_WORKOUT_CONSTS.UPDATE_GROUP_WORKOUT_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Խմբային մարզումները հաջողությամբ փոփոխվեց!"
                })
                history.push('/groupWorkout')
            })
            .catch(() => {
            })
    }
};

export const GetGroupWorkoutTimeline = (workoutId) => {
    const requestData = {
        url: `${_urlWorkoutTimeline}?workout_id=${workoutId}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                return data
            })
    }
};
export const GetAllGroupWorkoutTimelines = () => {
    const requestData = {
        url: `${_urlWorkoutTimeline}`,
        token: true,
        method: "GET",
        languageCode: 'en',
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                return data
            })
    }
};

export const AddWorkoutTimeline = (data) => {
    const requestData = {
        url: `${_urlWorkoutTimeline}`,
        token: true,
        method: "POST",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                return data
            })
    }
};

export const UpdateWorkoutTimeline = (id, data) => {
    const requestData = {
        url: `${_urlWorkoutTimeline}/${id}`,
        token: true,
        method: "PATCH",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                return data
            })
    }
};
export const DeleteWorkoutTimeline = (id) => {
    const requestData = {
        url: `${_urlWorkoutTimeline}/${id}`,
        token: true,
        method: "DELETE",

    };

    return dispatch => {
        return request(requestData)
    }
};
export const CancelWorkoutTimeline = (data) => {
    const requestData = {
        url: `${_urlWorkoutTimelineCancellation}`,
        token: true,
        method: "POST",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                return data
            })
    }
};

export const ActivateWorkoutTimeline = (data) => {
    const requestData = {
        url: `${_urlWorkoutTimelineCancellation}`,
        token: true,
        method: "DELETE",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                return data
            })
    }
};

export const clearGroupWorkoutData = () => {
    return dispatch => {
        dispatch({
            type: GROUP_WORKOUT_CONSTS.CLEAR_GROUP_WORKOUT_DATA,
        });
    }
};
