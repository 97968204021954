import { request, _urlTrainer, _urlTeam } from "../api";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import { TRAINERS_CONSTS } from "../constants";

export const GetTeam = () => {
    const requestData = {
        url: _urlTeam,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: TRAINERS_CONSTS.GET_TEAM,
                    payload: data
                });
            })
    }
};

export const UpdateTeam = ( data) => {
    const requestData = {
        url: `${_urlTeam}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:TRAINERS_CONSTS.UPDATE_TEAM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/trainer')
            })
            .catch(() => {
            })
    }
};

export const GetTrainers = () => {
    const requestData = {
        url: _urlTrainer,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: TRAINERS_CONSTS.GET_TRAINERS,
                    payload: data
                });
            })
    }
};


export const GetTrainerById = id => {
    const requestData = {
        url: `${_urlTrainer}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: TRAINERS_CONSTS.GET_TRAINER_BY_ID,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};
export const DeleteTrainerById = id => {
    const requestData = {
        url: `${_urlTrainer}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TRAINERS_CONSTS.DELETE_TRAINER_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateTrainer = data => {
    const requestData = {
        url: _urlTrainer,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: TRAINERS_CONSTS.CREATE_TRAINER,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Մարզիչը հաջողությամբ ստեղծվեց!"
                })
                history.push('/trainer')
            })
            .catch(() => {
            })
    }
};

export const UpdateTrainerById = (id, data) => {
    const requestData = {
        url: `${_urlTrainer}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type:TRAINERS_CONSTS.UPDATE_TRAINER_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Մարզիչը հաջողությամբ փոփոխվեց!"
                })
                history.push('/trainer')
            })
            .catch(() => {
            })
    }
};
