// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetServiceRequests,
    DeleteServiceRequest
} from "../../redux/actions";
import {GET_LIMITS} from "../../constants/constsTypes";
import {defaultEmptyView} from "../../assets/images";
import InfiniteScroll from "react-infinite-scroller";
import ItemsList from "../../components/ItemsList";
import swal from "sweetalert";


class ServiceRequest  extends Component {
    constructor(props) {
        super(props);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.deleteRequest = this.deleteRequest.bind(this);
    }

    componentDidMount() {
        this.props.GetServiceRequests({
            offset:0,
            limit: GET_LIMITS,
        });
    }

    loadMoreItems(){
        const { hasMore,list } = this.props.serviceRequests;
        hasMore && this.props.GetServiceRequests({
            reset: false,
            offset:list.length,
            limit: GET_LIMITS,
        });
    }

    deleteRequest(removingId) {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(async confirm => {
            if (confirm) {
                this.props.DeleteServiceRequest(removingId);
            }
        })

    }

    render() {
        let {serviceRequests} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն","Ազգանուն", "Հեռախոս",  "Փաթեթ", "Օր/ժամ"],
            largeItems: [],
            actions:[
                "delete"
            ]
        };
        let itemListInfo = serviceRequests?.list?.map(request => {
            const {id, service, user_data, created_at} = request;
            return {
                id: id,
                values: [
                    user_data?.first_name || "",
                    user_data?.last_name || "",
                    user_data?.phone || '',
                    `${service?.name} ${service?.title}`,
                    created_at? moment(created_at).format("DD.MM.YYYY / HH:mm"): "",
                ],
                deleteCb: this.deleteRequest.bind(this, id)

            };
        });
        return <PageWrapper pageTitle={'Հայտեր'}>
            <InfiniteScroll
                hasMore={serviceRequests?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                useWindow={true}
                initialLoad={false}>
                {!!serviceRequests?.list?.length ?
                    <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                               itemListInfo={itemListInfo}
                               detailsLink={"package-request"}/>
                    : <div className={'empty-view'}>
                        <img src={defaultEmptyView}/>
                    </div>}
            </InfiniteScroll>
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetServiceRequests,
    DeleteServiceRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
